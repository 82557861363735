<template>
  <div class="info-detail-content" v-if="talking" v-loading="loadingData">
    <div class="info-detail-top">
      <div style="cursor: pointer; width: 60px" @click="$emit('closeDetail')">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
    </div>
    <div
      class="info-detail-box"
      :class="{
        'info-detail-box-page':
          pageTotal > pageForm.size && tabActive != 3 && tabActive != 4
      }"
    >
      <div class="info-detail-item">
        <ag-author
          :item="talking"
          :tab="tab"
          :tabActive="tabActive"
        ></ag-author>
        <el-upload
          class="info-detail-upload"
          v-if="talking.messageType == 2"
          :action="uploadUrl"
          :limit="1"
          ref="upload"
          :on-success="imgUploader"
          :before-upload="beforeAvatarUpload"
          :show-file-list="false"
        >
        </el-upload>
        <!-- :class="{'not-line':talking.online != 1}" -->
        <div
          class="info-name"
          v-if="tab == 1 || tab == 2"
          :class="{ 'info-author': tab == 1 }"
        >
          <span>{{ talking.name }}</span>
        </div>
        <div
          class="info-name"
          v-if="tab == 3 || this.tab == 4"
          :class="{ 'info-author': tab == 3 || tab == 4 }"
        >
          <span>{{
            talking.joinMap &&
            talking.joinMap.u &&
            talking.joinMap.u.userNicename
          }}</span>
          <div
            v-if="tab == 3 || this.tab == 4"
            :class="{ notline: !getUserOnLine(talking.friendId) }"
            class="line-status"
          ></div>
        </div>
        <div class="info-amount" v-if="tab == 1">{{ talking.amount }}</div>
        <div class="info-amount" v-if="tab == 2">
          {{ talking.joinMap && talking.joinMap.g && talking.joinMap.g.amount }}
        </div>
        <div class="info-remark" v-if="tab == 3 || this.tab == 4">
          <div class="info-remark-name" v-show="!isInfoRemark">
            <span>{{ getUserPersonRemark(talking) || '昵称' }}</span>
            <span
              class="iconfont icon-pen remark-edit"
              @click="
                isInfoRemark = true;
                onInfoRemark(talking);
              "
            ></span>
          </div>
          <div
            class="info-remark-input"
            v-show="isInfoRemark"
            v-loading="remarking"
          >
            <el-input
              placeholder="备注昵称"
              v-model="infoRemark"
              @blur.stop="handleInfoRemarksBlur($event)"
              @keyup.native.enter="confirmInfoRemark($event, talking)"
              :ref="'info-remarks-' + talking.uid"
            >
            </el-input>
            <el-button
              slot="append"
              icon="el-icon-check"
              @click="confirmInfoRemark($event, talking)"
            ></el-button>
          </div>
        </div>
        <div class="info-amount" v-if="tab == 3 || this.tab == 4">
          {{ talking.friendId }}
        </div>
      </div>
      <div class="info-group-tab" v-if="tab == 2">
        <div class="info-group-item" @click="onSetupGroupName(1)">
          <i class="iconfont tab-icon icon-add-user"></i>
        </div>
        <div class="info-group-item" @click="onSetupGroupName(2)">
          <i class="iconfont tab-icon icon-del-user"></i>
        </div>
        <div class="info-group-item" @click="$emit('delGroup', talking)">
          <i class="el-icon-delete tab-icon delete-icon"></i>
        </div>
        <div class="info-group-item" @click="onEditGroupName(talking)">
          <i class="el-icon-edit-outline tab-icon"></i>
        </div>
      </div>
      <div class="info-detail-search" v-show="tab == 1 || tab == 2">
        <el-input
          class="search"
          clearable
          placeholder="用户名/ID/标签"
          @keyup.enter.native="onSearch"
          prefix-icon="el-icon-search"
          v-model="search"
        >
        </el-input>
      </div>
      <div class="info-detail-tab" v-if="tab == 1 || tab == 2">
        <div
          class="tab-item"
          v-for="(item, index) in tabList[tab - 1].list"
          :key="index"
          :class="{ 'tab-active': tabActive == index }"
          @click="onTab(item, index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="info-user-list" v-if="tab == 1 || tab == 2">
        <el-collapse v-model="activeName" accordion @change="showUserInfo">
          <!-- :disabled="isdisabled == item.id" -->
          <el-collapse-item
            v-for="(item, index) in listRight"
            :key="item.uid"
            :name="item.uid"
          >
            <template slot="title">
              <div class="user-list-lf">
                <ag-author
                  :item="item"
                  :tab="tab"
                  :tabActive="tabActive"
                ></ag-author>
              </div>
              <div class="user-list-rg">
                <div class="user-rg-top" :class="'user-rg-top-' + menuActive">
                  <div
                    class="user-name"
                    v-if="tab == 1"
                    :class="{
                      mute_user: item.mute,
                      canceluser:
                        item.joinMap && item.joinMap.u && item.joinMap.u.cancel
                    }"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="user-name"
                    v-else
                    :class="{
                      mute_user: item.muted,
                      canceluser:
                        item.joinMap && item.joinMap.u && item.joinMap.u.cancel
                    }"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="line-status"
                    :class="{
                      'not-line': item.online == 0 || !item.online,
                      'is-line': item.online != 0
                    }"
                  ></div>
                  <div class="user-remark" v-if="isEditRemark != item.id">
                    <div class="remark-user">
                      {{ getUserRemark(item) || '昵称' }}
                    </div>
                    <div class="remark-icon" @click.stop="editRemark(item)">
                      <i class="iconfont icon-pen"></i>
                    </div>
                  </div>
                  <div
                    class="input-with-remark"
                    v-if="isEditRemark == item.id"
                    @click.stop="onClick"
                  >
                    <el-input
                      placeholder="备注昵称"
                      v-model="remark"
                      @blur.stop="handleRemarksBlur($event, item)"
                      @focus.stop="handleRemarksFocus($event, item)"
                      @keyup.native.space="keyboardSpace"
                      @keyup.native.enter="confirmRemark($event, item)"
                      :ref="'remarks-' + item.id"
                    >
                    </el-input>
                    <el-button
                      slot="append"
                      icon="el-icon-check"
                      @click="confirmRemark($event, item)"
                    ></el-button>
                  </div>
                </div>
                <div class="user-id">{{ item.uid }}</div>
              </div>
            </template>
            <ag-user-list
              :item="item"
              :index="index"
              @siliao="goMsg"
              @addGroup="addGroup"
              @onLine="onLine"
              @onFrozen="onFrozen"
              @removeGroupUser="removeGroupUser"
              :tab="tab"
            ></ag-user-list>
          </el-collapse-item>
        </el-collapse>
        <!-- <div v-if="!listRight.length" style="text-align: center;line-height: 200px;">暂无数据</div> -->
      </div>
      <div class="info-user-list" v-if="tab == 3 || this.tab == 4">
        <div
          class="info-user-content"
          v-if="Object.keys(friendUser) && Object.keys(friendUser).length > 0"
        >
          <div class="user-btn-box">
            <el-button plain size="small" @click="addGroup(friendUser.t)"
              >加群</el-button
            >
            <el-button plain size="small" :disabled="true">禁言</el-button>
            <el-button
              plain
              size="small"
              :disabled="friendUser.t.userType == 3 ? true : false"
              @click="onFrozenPerson(friendUser.info)"
              >{{
                getUserCancel(friendUser.info.id) == 1 ? '解冻' : '冻结'
              }}</el-button
            >
            <i
              class="el-icon-delete del-person"
              @click="delPerson(friendUser.t)"
            ></i>
          </div>
          <div class="user-info-box">
            <div class="person-text">个人资讯</div>
            <div class="info-it">
              <span>注册时间：</span>
              <span>{{ friendUser.info.createTime }}</span>
            </div>
            <div class="info-it">
              <span>终端类型：</span>
              <span>{{ friendUser.info.source }}</span>
            </div>
            <div class="info-it">
              <span>观赛次数：</span>
              <span v-for="m in friendUser.view" :key="m.count">{{
                m.count
              }}</span>
            </div>
            <div class="info-it">
              <span>最后观看：</span>
              <span>-</span>
            </div>
            <div class="info-it">
              <span>设置纪录：</span>
              <span>-</span>
            </div>
          </div>
          <div class="user-tag-box">
            <div class="person-text">标签</div>
            <div
              class="tag-list"
              v-if="
                friendUser.info &&
                friendUser.info.tabsList &&
                friendUser.info.tabsList.length > 0
              "
            >
              <div
                class="tag-item"
                v-for="t in friendUser.info.tabsList"
                :key="t"
              >
                <span>{{ t }}</span>
                <i
                  class="iconfont icon-close"
                  @click="delTag(t, friendUser)"
                ></i>
              </div>
            </div>
            <div class="tag-btn">
              <div class="tag-add" v-if="!isAddTag" @click="addTag(friendUser)">
                <i class="iconfont icon-add"></i>
                <span>新增</span>
              </div>
              <div class="tag-input" v-if="isAddTag">
                <el-input
                  size="mini"
                  placeholder="备注标签"
                  v-model="tagVal"
                  @blur="handleTagBlur($event)"
                  @keyup.native.enter="confirmTag($event, friendUser)"
                  :ref="'tag-' + friendUser.id"
                >
                </el-input>
                <el-button
                  icon="el-icon-check"
                  @click.stop="confirmTag($event, friendUser)"
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="info-user-list-page ag-page"
      v-if="pageTotal > pageForm.size && tabActive != 3 && tabActive != 4"
    >
      <ag-page
        layout="total, prev, pager, next"
        :form="pageForm"
        :total="pageTotal"
        @change="pageLoadData"
      />
    </div>
    <!-- 创建群组 -->
    <el-dialog
      :title="setupGroupType == 1 ? '添加群组成员' : '删除群组成员'"
      :visible.sync="isSetupGroupName"
      v-loading="isLoading"
      custom-class="setup-group-dialog"
      width="416px"
    >
      <div class="setup-group-content">
        <div class="setup-group-box" style="padding: 0px">
          <div class="setup-group-item">
            <el-input
              class="search"
              clearable
              placeholder="搜索用户名/ID"
              @keyup.enter.native="searchUserName"
              prefix-icon="el-icon-search"
              v-model="consumerName"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchUserName"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div
          class="select-list-content"
          v-if="selectedUserList && selectedUserList.length > 0"
        >
          <div
            class="select-item-box"
            v-for="(item, index) in selectedUserList"
            :key="item.id"
          >
            <span>{{ item.userNicename }}</span>
            <i
              class="iconfont icon-close"
              @click="delSelectUser(item, index)"
            ></i>
          </div>
        </div>
        <div
          class="sel-le"
          v-if="selectedUserList && selectedUserList.length > 0"
        >
          已选<span>{{ selectedUserList.length }}</span>
        </div>
        <div class="user-list-content" style="min-height: 280px">
          <div
            class="user-list-name"
            v-for="(item, index) in userNicenameList"
            :key="item.id"
          >
            <div class="user-list-box">
              <div class="box-lf">
                <img v-if="item.avatar" :src="item.avatar | url" />
                <div class="lf-text" v-else>
                  {{ item.userNicename && item.userNicename.trim().charAt(0) }}
                </div>
              </div>
              <div class="box-rg">
                <div class="rg-name">{{ item.userNicename }}</div>
                <div class="rg-id">
                  <span v-if="setupGroupType == 1">{{ item.id }}</span
                  ><span v-else>{{ item.uid }}</span>
                </div>
              </div>
            </div>
            <div class="user-rg">
              <el-checkbox
                @change="selectUserChange(item, $event)"
                v-model="item.checked"
              ></el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="user-dialog-footer">
        <el-button @click="cancelGroupName">取 消</el-button>
        <el-button type="primary" @click="onGroupUserName">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改群组名"
      :visible.sync="showEditGroupName"
      width="500px"
    >
      <div class="qun">
        <el-input
          class="qun-name"
          placeholder="输入群组名称"
          v-model="groupName"
        ></el-input>

        <el-checkbox v-model="isForbidden" @change="onForbidden"
          >群临时禁言</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditGroupName = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditGroupName"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';
import vars from '@/config/vars';
import sport from '@/config/sport_manage.js';
import { uploadUrl, downloadUrl } from '@/config/env';
export default {
  props: {
    menuActive: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      uploadUrl,
      downloadUrl,
      tab: 1,
      search: '',
      talking: {},
      tabList: [
        {
          list: ['全部', '注册', '未注册', '禁言', '冻结']
        },
        {
          list: ['全部', '在线', '离线', '禁言', '冻结']
        }
      ],
      tabActive: 0,
      activeName: '',
      guestForm: {},
      guestTotal: 0,
      userMap: {},
      userList: [],
      remark: '',
      isEditRemark: false,
      isdisabled: false,
      isInfoRemark: false,
      infoRemark: '',
      friendUser: {},
      tagVal: '',
      isAddTag: false,
      isSetupGroupName: false,
      consumerName: '',
      selectedUserList: [],
      allUserList: [],
      setupGroupType: 1,
      loading: false,
      pageForm: {
        page: 1,
        size: 20
      },
      pageTotal: 0,
      loadingData: false,
      muteRoomList: [],
      cancelRoomList: [],
      cancelGroupList: [],
      showEditGroupName: false,
      isForbidden: false,
      groupMute: 0,
      groupName: '',
      isLoading: false,
      remarking: false
    };
  },
  watch: {
    search(n) {
      if (n == '') {
        this.onSearch();
      }
    }
  },
  computed: {
    listRight() {
      let w = this.search;
      let map = {};
      let list = this.userList.filter((r) => {
        let ms = map[r.uid];
        if (ms && ms.uid) {
          return false;
        }
        map[r.uid] = r;
        let u = r.joinMap && r.joinMap.u;
        let ur = r.joinMap && r.joinMap.ur;
        let userCancel = this.$store.state.userMap[r.uid];
        if (userCancel && userCancel.id) {
          if (r.uid == userCancel.uid) {
            r.cancel = userCancel.cancel;
            u.cancel = userCancel.cancel;
          }
        }
        if (this.tab == 1) {
          let userItem = this.getUserMute(r.roomId, r.uid);
          if (userItem) {
            r.mute = userItem.mute;
          }
        }
        if (this.tab == 2) {
          let groupMuted = this.getGroupUserMute(r.groupId, r.uid);
          if (groupMuted) {
            r.muted = groupMuted.muted;
          }
        }

        if (this.tab == 1) {
          if (
            (this.tabActive == 0 && u.cancel == 1) ||
            (this.tabActive == 1 && u.cancel == 1)
          ) {
            return false;
          }
          if (
            (this.tabActive == 0 && r.mute == 1) ||
            (this.tabActive == 1 && r.mute == 1)
          ) {
            return false;
          }

          if (
            (this.tabActive == 1 && u.userType != 2) ||
            (this.tabActive == 1 && r.mute == 1)
          ) {
            return false;
          }
          if (
            (this.tabActive == 2 && u.userType != 3) ||
            (this.tabActive == 2 && r.mute == 1)
          ) {
            return false;
          }
          if (this.tabActive == 3 && r.mute != 1) {
            return false;
          }
        }
        if (this.tab == 2) {
          if (
            (this.tabActive == 0 && u.cancel == 1) ||
            (this.tabActive == 1 && u.cancel == 1) ||
            (this.tabActive == 2 && u.cancel == 1)
          ) {
            return false;
          }
          if (
            (this.tabActive == 0 && r.muted == 1) ||
            (this.tabActive == 1 && r.muted == 1)
          ) {
            return false;
          }
          if (this.tabActive == 1 && u.online == 0) {
            return false;
          }

          if (this.tabActive == 2 && u.online > 0) {
            return false;
          }

          if (this.tabActive == 3 && r.muted != 1) {
            return false;
          }
        }

        if (this.tabActive == 4 && u.cancel != 1) {
          return false;
        }
        if (w && u.userNicename && u.userNicename.indexOf(w) < 0) {
          return false;
        }
        if (w && u.tabList && u.tabList.indexOf(w) < 0) {
          return false;
        }
        return true;
      });
      list.sort((a, b) => {
        if (this.tab == 1) {
          return a.joinMap.u.userType < b.joinMap.u.userType ? -1 : 1;
        }
        if (this.tab == 2) {
          let scorea = this.score(a);
          let scoreb = this.score(b);
          return scoreb - scorea;
        }
      });
      return list;
    },
    userNicenameList() {
      let list = this.allUserList;
      if (!this.consumerName) {
        return list;
      }

      let w = this.consumerName;

      return list.filter((r) => {
        let ur = r.joinMap && r.joinMap.ur;
        if (!r.userNicename) {
          return false;
        }
        if (
          (ur && ur.remark && ur.remark.indexOf(w) >= 0) ||
          r.userNicename.indexOf(w) >= 0
        ) {
          return true;
        }
        if (r.id == w) {
          return true;
        }
        return false;
      });
    }
  },
  mounted() {},
  created() {
    util.on('msg', this.onmsg);
  },
  destroyed() {
    util.off('msg', this.onmsg);
  },
  methods: {
    getUserOnLine(id) {
      let user = this.$store.state.userMap[id];
      console.log('talking', user, id, this.talking);
      if (!user || !user.id) {
        return false;
      }
      if (user.onlineApp || user.onlineWeb) {
        return true;
      }
      return false;
    },
    beforeAvatarUpload(file) {
      this.file = file;
      let isJPG =
        file.type == 'image/png' ||
        file.type == 'image/jpg' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/bmp' ||
        file.type == 'image/gif';
      let isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        this.$message.error('上传图片只能是 PNG、JPG、JPEG、BMP、GIF 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 4MB!');
      }
      return isJPG && isLt2M;
    },
    imgUploader(response, file, fileList) {
      if (file.response && file.response.data) {
        let map = {
          content: file.response.data,
          type: 3
        };
        // this.sendImage(map)
        // this.onGroupName(file.response.data)
        this.$refs.upload.clearFiles();
      }
    },
    onGroupName(avatar) {
      let reg = /[ ]+$/;
      let groupId = this.talking.groupId;
      let name = this.talking.groupName;
      api
        .editGroupName({ groupId, avatar, name })
        .then((resp) => {
          util.message(resp.message);
        })
        .catch((e) => {
          util.message(e.message);
        });
    },
    getUserMute(rid, id) {
      let u = this.$store.getters.getRoomUserById(rid, id);
      if (!u || !u.id) {
        return false;
      }
      return u;
    },
    getGroupUserMute(rid, id) {
      let u = this.$store.getters.getGroupUserById(rid, id);
      if (!u || !u.id) {
        return false;
      }
      return u;
    },
    getUserRemark(item) {
      if (item.joinMap && item.joinMap.u && item.joinMap.u.userType == 3) {
        return item.joinMap.ur.remark;
      }
      let u = this.$store.state.userRemarkMap[item.uid];
      if (!u || !u.id) {
        item.joinMap.ur.remark = '';
        item.remark = '';
        return item.joinMap.ur.remark;
      }
      item.joinMap.ur.remark = u.remark;
      item.remark = u.remark;
      return item.joinMap.ur.remark;
    },
    getUserPersonRemark(item) {
      let u = this.$store.state.userRemarkMap[item.friendId];
      if (item.joinMap && !item.joinMap.ur) {
        let ur = {
          remark: ''
        };
        this.$set(item.joinMap, 'ur', ur);
      }
      if (!u || !u.id) {
        item.joinMap.ur.remark = '';
        item.remark = '';
        return item.joinMap.ur.remark;
      }
      item.joinMap.ur.remark = u.remark;
      item.remark = u.remark;
      return item.joinMap.ur.remark;
    },
    score(a) {
      let s = 0;

      let u = a.joinMap && a.joinMap.u && a.joinMap.u;
      //注册、在线
      if (u.online > 0) {
        s += 1000000000;
      }
      //注册、离线
      if (!u.online || u.online == 0) {
        s += 100000000;
      }
      //订阅人数
      s += 1;
      return s;
    },
    getUserCancel(id) {
      let user = this.$store.state.userMap[id];
      if (!user || !user.id) {
        return 0;
      }
      return user.cancel;
    },
    goMsg(n) {
      let roomName = this.talking.name || this.talking.groupName;
      let r = {
        roomName,
        messageType: this.talking.messageType
      };
      this.$emit('onSiliao', n, r);
    },
    addGroup(n) {
      this.$emit('onAddGroup', n);
    },
    delPerson(t) {
      let id = t.id;
      this.$alert(
        `是否删除<i style="color: red">${t.joinMap.u.userNicename}</i>`,
        '删除',
        {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          callback: (action) => {
            if (action == 'confirm') {
              api
                .removeFriend({
                  id
                })
                .then((resp) => {
                  this.$store.commit('messageDelFriend', t);
                  util.success(resp.message);
                  this.$emit('delFriend');
                })
                .catch((res) => {
                  util.error(res.message);
                });
            }
          }
        }
      );
    },
    getRoomUserById(roomId, uid) {
      return this.$store.getters.getRoomUserById(roomId, uid);
    },
    onLine(item) {
      if (this.muteling) {
        return;
      }
      this.muteling = true;

      if (this.tab == 1) {
        this.editRoomUserMute(item);
        return;
      }
      if (this.tab == 2) {
        this.editGroupUserMute(item);
        return;
      }
    },
    editRoomUserMute(item) {
      let i = Object.assign({}, item);
      i.mute = item.mute ? 0 : 1;
      let msg = i.mute ? '禁言' : '解禁';
      let id = item.id;
      if (!id) {
        let umap = this.getRoomUserById(item.roomId, item.uid);
        if (umap && umap.id) {
          i.id = umap.id;
        }
      }
      this.$alert(
        `是否${msg}<i style="color: red">${item.joinMap.u.userNicename}</i>`,
        `${msg}`,
        {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          callback: (action) => {
            if (action == 'confirm') {
              api
                .editMute({
                  id: id,
                  mute: i.mute
                })
                .then((resp) => {
                  util.success(`${msg}成功`);
                  let r = resp.data;
                  this.muteling = false;
                  item.mute = r.mute || i.mute;
                  let userMap = this.$store.getters.getRoomUserByRid(
                    item.roomId
                  );
                  let user = this.getRoomUserById(item.roomId, item.uid);
                  if (!user) {
                    user = item;
                    this.$set(userMap, r.uid, user);
                    this.$store.commit('updateRoomMuted', user);
                    return;
                  }
                  user.mute = r.mute || i.mute;
                  this.$store.commit('updateRoomMuted', user);
                  this.$set(userMap, r.uid, user);
                  // let u = this.talking.blockMap[r.uid]
                  // if(!u  || !u.id){
                  //     this.talking.blockMap[r.uid] = r
                  // }else {
                  //     this.$delete(this.talking.blockMap,r.uid)
                  //                             this.$set(this.talking,'blockMap',this.talking.blockMap)
                  // }
                  // let userItem = this.$store.state.userMap[r.uid]
                  // if (userItem && userItem.id) {
                  // 	userItem.mute = r.mute
                  // 	this.$store.commit("setUser",{r:userItem,id:r.uid})
                  // }
                })
                .catch((res) => {
                  this.muteling = false;
                  util.error(res.message);
                  item.mute = item.mute ? 0 : 1;
                });
            }
          }
        }
      );
      this.muteling = false;
    },
    editGroupUserMute(item) {
      let i = Object.assign({}, item);
      i.muted = item.muted ? 0 : 1;
      let msg = i.muted ? '禁言' : '解禁';
      let id = item.id;
      let groupId = this.talking.groupId;
      this.$alert(
        `是否${msg}<i style="color: red">${item.joinMap.u.userNicename}</i>`,
        `${msg}`,
        {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          callback: (action) => {
            if (action == 'confirm') {
              api
                .editGroupUserMuted({
                  id: id,
                  muted: i.muted
                })
                .then((resp) => {
                  util.success(`${msg}成功`);
                  this.muteling = false;
                  item.muted = i.muted;
                  this.$store.commit('updateGroupMuted', { r: item, groupId });
                  // let map = this.$store.getters.getGroupUserByRid(groupId)
                  // let groupMap = this.$store.getters.getGroupUserById(groupId,item.uid)
                  // if (!groupMap) {
                  // 	// groupMap = item
                  // 	this.$store.commit('updateGroupMuted',{r:item,groupId,muted:i.muted})
                  // 	// this.$set(map,item.uid,groupMap)
                  // 	return
                  // }
                  // groupMap.muted = i.muted
                  // this.$set(groupMap,'muted',i.muted)
                  // this.$set(map,item.uid,groupMap)
                })
                .catch((res) => {
                  this.muteling = false;
                  util.error(res.message);
                  item.muted = item.muted ? 0 : 1;
                });
            }
          }
        }
      );
      this.muteling = false;
    },
    onFrozen(item) {
      let cancel = item.joinMap.u.cancel ? 0 : 1;
      let msg = cancel ? '冻结' : '解冻';
      let id = item.uid;
      this.$alert(
        `是否${msg}<i style="color: red">${item.joinMap.u.userNicename}</i>`,
        `${msg}`,
        {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          callback: (action) => {
            if (action == 'confirm') {
              api
                .editUsersCancel({
                  id: id,
                  cancel
                })
                .then((resp) => {
                  util.success(resp.message);
                  let r = resp.data;
                  let u = this.$store.state.userMap[r.id];
                  item.joinMap.u.cancel = cancel;
                  if (!u || !u.id) {
                    this.$store.commit('userMap', { r, id: r.id });
                  } else {
                    u.cancel = r.cancel;
                    u.joinMap.u.cancel = r.cancel;

                    let ur = Object.assign({}, u);
                    this.$store.commit('setUser', { r: ur, id: u.uid });
                  }
                })
                .catch((res) => {
                  util.error(res.message);
                });
            }
          }
        }
      );
    },
    onFrozenPerson(person) {
      let id = person.id;
      let cancel = this.getUserCancel(id) == 1 ? 0 : 1;
      let msg = this.getUserCancel(id) == 1 ? '解冻' : '冻结';
      this.$alert(
        `是否${msg}<i style="color: red">${person.userNicename}</i>`,
        `${msg}`,
        {
          dangerouslyUseHTMLString: true,
          type: 'warning',
          callback: (action) => {
            if (action == 'confirm') {
              api
                .editUsersCancel({
                  id: id,
                  cancel
                })
                .then((resp) => {
                  util.success(resp.message);
                  let r = resp.data;
                  this.talking.joinMap.u.cancel = r.cancel;
                  let u = this.$store.state.userMap[r.id];
                  if (!u || !u.id) {
                    this.$store.commit('userMap', { r, id: r.id });
                  } else {
                    u.cancel = r.cancel;
                    u.joinMap.u.cancel = r.cancel;
                    let ur = Object.assign({}, u);
                    this.$store.commit('setUser', { r: ur, id: u.uid });
                  }
                })
                .catch((res) => {
                  util.error(res.message);
                });
            }
          }
        }
      );
    },
    removeGroupUser(item, index) {
      let index1 = this.userList.findIndex((r) => {
        return r.id == item.id;
      });
      let userItem = this.userList[index1];
      this.$alert(`是否移出<i style="color: red">${item.name}</i>`, '移出', {
        dangerouslyUseHTMLString: true,
        type: 'warning',
        callback: (action) => {
          if (action == 'confirm') {
            this.loadingData = true;
            api
              .removeGroupUsers({
                id: item.id
              })
              .then((resp) => {
                this.loadingData = false;
                util.success(resp.message);
                this.userList.splice(index1, 1);
                this.pageTotal--;
                this.$store.commit('updateGroupAmount', userItem);
              })
              .catch((res) => {
                this.loadingData = false;
                util.error(res.message);
              });
          }
        }
      });
    },
    getUserList(n) {
      this.loading = false;
      this.loadingData = true;
      this.guestForm = {};
      this.pageForm.page = 1;
      this.pageTotal = 0;
      this.talking = n;
      this.tab = n.messageType;
      let t = n;
      this.clearData();
      if (t.messageType == 1) {
        this.getRoomUser(t);
        return;
      }
      if (t.messageType == 2) {
        this.getGroupUser(t);
        return;
      }
      if (t.messageType == 3 || t.messageType == 4) {
        this.getFriendUser(t);
        return;
      }
    },
    pageLoadData(page) {
      let t = this.talking;
      this.pageForm.page = page;
      if (t.messageType == 1) {
        if (this.tabActive == 3) {
          this.getMuteRoomUser(t);
          return;
        }
        if (this.tabActive == 4) {
          this.getCancelRoomUser(t);
          return;
        }
        this.getRoomUser(t);
        return;
      }
      if (t.messageType == 2) {
        if (this.tabActive == 4) {
          this.getCancelGroupUser(t);
          return;
        }
        this.getGroupUser(t);
        return;
      }
    },
    onTab(item, index) {
      let t = this.talking;
      if (this.tab == 1) {
        if (item == '全部') {
          this.getRoomUsers(t);
        }
        if (item == '注册') {
          this.getRoomUsers(t);
        }
        if (item == '未注册') {
          this.getRoomUsers(t);
        }
        if (item == '禁言') {
          this.getMuteRoomUser(t);
        }
        if (item == '冻结') {
          this.getCancelRoomUser(t);
        }
      }
      if (this.tab == 2) {
        if (item == '全部') {
          this.getRoomUsers(t);
        }
        if (item == '在线') {
          this.getRoomUsers(t);
        }
        if (item == '离线') {
          this.getRoomUsers(t);
        }
        if (item == '禁言') {
          this.getMutedGroupUser(t);
        }
        if (item == '冻结') {
          this.getCancelGroupUser(t);
        }
      }
      this.activeName = '';
      this.tabActive = index;
    },
    getRoomUsers(t) {
      if (this.tab == 1) {
        this.getRoomUser(t);
      }
      if (this.tab == 2) {
        this.getGroupUser(t);
      }
    },
    getRoomUser(t) {
      let roomId = t.id;
      let form = Object.assign(
        this.pageForm,
        { roomId: roomId },
        { name: this.search }
      );

      api
        .pageRoomUser(form)
        .then((resp) => {
          this.loadingData = false;
          this.pageTotal = resp.data.total;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            util.warning('该用户不存在');
            return;
          }
          let list = this.handleRoomUserList(resp.data.list, roomId);
          this.userList = list;
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    getMuteRoomUser(t) {
      let roomId = t.id;
      let form = {
        roomId: roomId,
        mute: 1,
        name: this.search
      };
      api
        .pageRoomUser(form)
        .then((resp) => {
          this.loadingData = false;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            return;
          }
          let list = this.handleRoomUserList(resp.data.list, roomId);
          let unList = this.userList.concat(list);
          this.userList = this.unique(unList);
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    getCancelRoomUser(t) {
      let roomId = t.id;
      let form = {
        roomId: roomId,
        cancel: 1,
        name: this.search
      };

      api
        .pageRoomUser(form)
        .then((resp) => {
          this.loadingData = false;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            return;
          }
          let list = this.handleRoomUserList(resp.data.list, roomId);
          let unList = this.userList.concat(list);
          this.userList = this.unique(unList);
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    handleRoomUserList(list, roomId) {
      let map = {};
      for (let i in list) {
        let r = list[i];
        r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
        r.remark = r.joinMap && r.joinMap.ur && r.joinMap.ur.remark;
        if (
          (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
          (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
        ) {
          r.joinMap.u.userNicename = '游客#' + r.uid;
          r.joinMap.u.userType = 3;
          r.name = r.joinMap.u.userNicename;
        }
        let m = map[r.uid];
        if (!m || !m.id) {
          map[r.uid] = r;
        }
        r.index = i;
        r.cancel = r.joinMap && r.joinMap.u && r.joinMap.u.cancel;
        let id = r.uid;
        this.$store.commit('userMap', { r, id });
        this.userMap[r.uid] = r;
      }
      this.$store.commit('roomUserList', { roomId: roomId, map: map });
      return list;
    },
    getGroupUser(t) {
      let groupId = t.groupId;
      let form = Object.assign(
        this.pageForm,
        { groupId: groupId },
        { name: this.search }
      );
      api
        .pageGroupUser(form)
        .then((resp) => {
          this.loadingData = false;
          this.pageTotal = resp.data.total;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            util.warning('该用户不存在');
            return;
          }
          let list = resp.data.list;
          for (let i in list) {
            let r = list[i];
            r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
            r.remark = r.joinMap && r.joinMap.ur && r.joinMap.ur.remark;
            r.online = r.joinMap && r.joinMap.u && r.joinMap.u.online;
            if (
              (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
              (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
            ) {
              r.joinMap.u.userNicename = '游客#' + r.uid;
              r.joinMap.u.userType = 3;
              r.name = r.joinMap.u.userNicename;
            }
            this.userMap[r.uid] = r;
          }
          this.userList = list;
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    getMutedGroupUser(t) {
      let groupId = t.groupId;
      let form = {
        groupId: groupId,
        muted: 1,
        name: this.search
      };
      api
        .pageGroupUser(form)
        .then((resp) => {
          this.loadingData = false;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            return;
          }
          let list = resp.data.list;
          for (let i in list) {
            let r = list[i];
            r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
            r.remark = r.joinMap && r.joinMap.ur && r.joinMap.ur.remark;
            r.online = r.joinMap && r.joinMap.u && r.joinMap.u.online;
            if (
              (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
              (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
            ) {
              r.joinMap.u.userNicename = '游客#' + r.uid;
              r.joinMap.u.userType = 3;
              r.name = r.joinMap.u.userNicename;
            }
            this.userMap[r.uid] = r;
          }
          let unList = this.userList.concat(list);
          this.userList = this.unique(unList);
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    getCancelGroupUser(t) {
      let groupId = t.groupId;
      let form = {
        groupId: groupId,
        cancel: 1,
        name: this.search
      };
      api
        .pageGroupUser(form)
        .then((resp) => {
          this.loadingData = false;
          if (!resp.data || !resp.data.list || !resp.data.list.length) {
            return;
          }
          let list = resp.data.list;
          for (let i in list) {
            let r = list[i];
            r.name = r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
            r.remark = r.joinMap && r.joinMap.ur && r.joinMap.ur.remark;
            r.online = r.joinMap && r.joinMap.u && r.joinMap.u.online;
            if (
              (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
              (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
            ) {
              r.joinMap.u.userNicename = '游客#' + r.uid;
              r.joinMap.u.userType = 3;
              r.name = r.joinMap.u.userNicename;
            }
            this.userMap[r.uid] = r;
          }
          let unList = this.userList.concat(list);
          this.userList = this.unique(unList);
        })
        .catch((err) => {
          this.loadingData = false;
          util.error('无法获取数据');
        });
    },
    getFriendUser(t) {
      let id = t.friendId;
      api
        .countViewMatch({
          id
        })
        .then((resp) => {
          this.loadingData = false;
          this.talking.joinMap.u.cancel = resp.data.info.cancel;
          let info = resp.data.info;
          let tabsList = resp.data.userTab;
          let userTab = [];
          for (let i in tabsList) {
            let r = tabsList[i];
            userTab.push(r.tab);
          }
          if (
            info &&
            info.joinMap &&
            info.joinMap.ur &&
            info.joinMap.ur.remark
          ) {
          }
          let user = this.$store.state.userMap[id];
          let tur = t.joinMap.ur;
          let ur = Object.assign({}, tur);
          if (user && user.id) {
            ur = user.joinMap && user.joinMap.ur;
          }
          if (info.userType == 3 || !info.id) {
            info.userNicename = '游客#' + (info.id || info.friendId);
            info.cancel = 0;
          }
          info.joinMap = {
            u: {
              userNicename: info.userNicename,
              cancel: info.cancel
            },
            ur
          };
          info.onlineApp = resp.data.onlineApp;
          info.onlineWeb = resp.data.onlineWeb;
          this.$set(info, 'tabsList', userTab);
          this.$store.commit('userMap', { r: info, id });
          this.friendUser = Object.assign({ t: this.talking }, resp.data);
          console.log('===========----', this.friendUser);
        })
        .catch((res) => {
          this.loadingData = false;
          util.error(res.message);
        });
    },
    onInfoRemark(t) {
      this.infoRemark =
        (t.joinMap && t.joinMap.ur && t.joinMap.ur.remark) || t.name;
      this.$nextTick(() => {
        this.$refs['info-remarks-' + t.uid].focus();
      });
    },
    handleInfoRemarksBlur(e) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          this.isInfoRemark = false;
        }, 500);
      });
    },
    confirmInfoRemark(e, item) {
      e.stopPropagation();
      if (!this.infoRemark) {
        util.error('请输入备注名');
        return;
      }
      this.remarking = true;
      let remark = this.infoRemark;
      let uid = item.friendId;
      let id = item.joinMap && item.joinMap.ur && item.joinMap.ur.id;
      let rm = this.$store.state.userRemarkMap[uid];
      if (rm && rm.id) {
        id = rm.id;
      }
      // let id = undefined
      api
        .editUserRemark({
          id,
          uid,
          remark
        })
        .then((resp) => {
          this.isInfoRemark = false;
          this.remarking = false;
          // item.remark = resp.data.remark
          if (item && item.joinMap && item.joinMap.ur) {
            item.joinMap.ur.id = resp.data.id;
            item.joinMap.ur.remark = resp.data.remark;
          }
          let r = this.userMap[uid];
          if (r && r.joinMap && r.joinMap.ur) {
            r.joinMap.ur.id = resp.data.id;
            r.joinMap.ur.remark = resp.data.remark;
          }
          let u = this.$store.state.userRemarkMap[uid];
          if (u && u.id) {
            u.id = resp.data.id;
            u.remark = resp.data.remark;
            let obj = Object.assign({}, u);
            this.$store.commit('setUserRemark', { r: obj, id: uid });
          } else {
            let ur = resp.data;
            this.$store.commit('setUserRemark', { r: ur, id: uid });
          }
          let f = this.$store.state.message.map.friend[uid];

          if (f && f.joinMap && f.joinMap.ur) {
            f.joinMap.ur.id = resp.data.id;
            f.joinMap.ur.remark = resp.data.remark;
            f.name =
              (f.joinMap && f.joinMap.ur && f.joinMap.ur.remark) ||
              (f.joinMap && f.joinMap.u && f.joinMap.u.userNicename);
            this.$set(this.$store.state.message.map.friend, uid, f);
          }
          this.infoRemark = '';
          util.success(resp.message);
        })
        .catch((err) => {
          this.remarking = false;
          util.error(err.message);
        });
    },
    onSearch() {
      let t = this.talking;
      if (t.messageType == 1 || this.tab == 1) {
        this.getRoomUser(t);
        return;
      }
      if (t.messageType == 2 || this.tab == 2) {
        this.getGroupUser(t);
        return;
      }
    },
    editRemark(tag) {
      this.isEditRemark = tag.id;
      this.remark =
        (tag.joinMap && tag.joinMap.ur && tag.joinMap.ur.remark) ||
        tag.name ||
        (tag.joinMap && tag.joinMap.u && tag.joinMap.u.userNicename);
      this.$nextTick(() => {
        this.$refs['remarks-' + tag.id][0].focus();
      });
    },
    handleRemarksBlur(e, tag) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isEditRemark == tag.id) {
            this.isEditRemark = 0;
            this.isdisabled = 0;
          }
        }, 500);
      });
    },
    onClick(e) {
      e.stopPropagation();
    },
    handleRemarksFocus(e, tag) {
      e.stopPropagation();
      this.isdisabled = tag.id;
    },
    keyboardSpace(e) {
      e.stopPropagation();
    },
    confirmRemark(e, tag) {
      e.stopPropagation();
      if (!this.remark) {
        util.error('请输入备注名');
        return;
      }
      let remark = this.remark;
      let id = tag.joinMap && tag.joinMap.ur && tag.joinMap.ur.id;
      let uid = tag.uid;
      if (this.tab == 3 || this.tab == 4) {
        uid = tag.friendId;
      }
      let user = this.$store.state.userRemarkMap[uid];
      if (user && user.id) {
        id = user.id;
      }
      api
        .editUserRemark({
          id,
          uid,
          remark
        })
        .then((resp) => {
          this.isEditRemark = false;
          tag.remark = resp.data.remark;
          if (tag && tag.joinMap && tag.joinMap.ur) {
            tag.joinMap.ur.id = resp.data.id;
            tag.joinMap.ur.remark = resp.data.remark;
          }
          let r = this.userMap[uid];
          if (r && r.joinMap && r.joinMap.ur) {
            r.joinMap.ur.id = resp.data.id;
            r.joinMap.ur.remark = resp.data.remark;
          }
          let u = this.$store.state.userRemarkMap[uid];
          if (u && u.id) {
            u.id = resp.data.id;
            u.remark = resp.data.remark;
            let obj = Object.assign({}, u);
            this.$store.commit('setUserRemark', { r: obj, id: uid });
          } else {
            let ur = resp.data;
            this.$store.commit('setUserRemark', { r: ur, id: uid });
          }
          let f = this.$store.state.message.map.friend[uid];

          if (f && f.joinMap && f.joinMap.ur) {
            f.joinMap.ur.id = resp.data.id;
            f.joinMap.ur.remark = resp.data.remark;
            f.name =
              (f.joinMap && f.joinMap.ur && f.joinMap.ur.remark) ||
              (f.joinMap && f.joinMap.u && f.joinMap.u.userNicename);
            this.$set(this.$store.state.message.map.friend, uid, f);
          }
          this.remark = '';
          util.success(resp.message);
        })
        .catch((err) => {
          util.error(err.message);
        });
    },
    showUserInfo(uid) {
      if (uid) {
        let item = this.userList.find((item) => item.uid == uid);
        let type = this.tab;
        this.showViewMatch(item, type);
      }
    },
    showViewMatch(tag, type) {
      let id = type == 3 ? tag.friendId : tag.uid;
      api
        .countViewMatch({
          id
        })
        .then((resp) => {
          this.$set(tag, 'viewMatch', resp.data);
          let info = resp.data.info;
          let tabsList = resp.data.userTab;
          let userTab = [];
          for (let i in tabsList) {
            let r = tabsList[i];
            userTab.push(r.tab);
          }

          this.$set(tag, 'tabsList', userTab);
        })
        .catch((res) => {
          util.error(res.message);
        });

      let uid = id;
      let departmentId = this.$store.infoLogin.departmentId;

      api
        .listUserTabForDepartment({
          uid,
          departmentId
        })
        .then((resp) => {
          this.$set(tag, 'depTab', resp.data);
        })
        .catch((res) => {
          util.error(res.message);
        });
    },
    addTag(item) {
      this.isAddTag = true;
      this.$nextTick(() => {
        if (this.$refs['tag-' + item.id]) {
          this.$refs['tag-' + item.id].focus();
        }
      });
    },
    handleTagBlur(e) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          this.isAddTag = false;
        }, 500);
      });
    },
    confirmTag(e, item) {
      let id = item.t.friendId || item.t.uid;
      let tab = this.tagVal;
      if (!tab) {
        e.stopPropagation();
        util.error('请输入标签名');
        return;
      }
      if (tab) {
        api
          .editUserTab({
            id,
            tab
          })
          .then((resp) => {
            util.success('添加成功');
            item.info.tabsList.push(tab);
            this.isAddTag = false;
            this.tagVal = '';
          })
          .catch((res) => {
            util.error(res.message);
          });
      }
    },
    delTag(tab, tag) {
      let id = tag.t.friendId || tag.t.uid;
      let l = tag.info.tabsList;
      api
        .removeUserTab({
          id,
          tab
        })
        .then((resp) => {
          l.splice(l.indexOf(tab), 1);
        })
        .catch((res) => {
          util.error(res.message);
        });
    },
    onSetupGroupName(type) {
      this.setupGroupType = type;
      this.isSetupGroupName = true;
      this.clearData();
      if (type == 1) {
        this.getAllUserList();
        return;
      }
      if (type == 2) {
        this.getGroupUserList();
        return;
      }
    },
    onEditGroupName(t) {
      this.groupName = t.name;
      this.groupMute = t.mute || (t.joinMap && t.joinMap.g && t.joinMap.g.mute);
      if (!this.groupMute) {
        this.groupMute = 0;
      }
      this.isForbidden = this.groupMute == 1 ? true : false;
      this.showEditGroupName = true;
    },
    onForbidden(s) {
      this.isForbidden = s;
      this.groupMute = s ? 1 : 0;
    },
    confirmEditGroupName() {
      if (!this.groupName || this.groupName.match(/^[ ]*$/)) {
        util.message('请输入群组名称');
        return;
      }
      api
        .editGroupName({
          groupId: this.talking.groupId,
          name: this.groupName,
          mute: this.groupMute
        })
        .then((resp) => {
          util.success(resp.message);
          this.showEditGroupName = false;
          this.talking.name = resp.data.name;
          this.$store.commit('messageUpdateGroup', resp.data);
        })
        .catch((err) => {
          util.error(err.message);
        });
    },
    searchUserName() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (this.setupGroupType == 1) {
        this.getAllUserList();
        return;
      }
    },
    getAllUserList() {
      let re = /^[0-9]+.?[0-9]*/;
      if (!re.test(this.consumerName)) {
        api
          .pageUsers({ userNicename: this.consumerName })
          .then((resp) => {
            this.loading = false;
            if (!resp.data || !resp.data.list || !resp.data.list.length) {
              util.warning('该用户不存在');
              return;
            }
            this.handleAllUserList(resp.data.list);
          })
          .catch((err) => {
            this.loading = false;
            util.error(err.message);
          });
        return;
      }
      api
        .pageUsers({ id: this.consumerName })
        .then((resp) => {
          this.loading = false;
          if (!resp.data || !resp.data.list) {
            util.warning('该用户不存在');
            return;
          }
          this.handleAllUserList(resp.data.list);
        })
        .catch((err) => {
          this.loading = false;
          util.error(err.message);
        });
    },
    handleAllUserList(l) {
      let list = this.unique(l);
      let selList = this.selectedUserList;
      for (let j in list) {
        let l = list[j];
        if (!selList.length) {
          this.$set(l, 'checked', false);
        }
        for (let i in selList) {
          let r = selList[i];
          if (r.id == l.id) {
            this.$set(l, 'checked', true);
          }
        }
      }
      this.allUserList = list;
    },
    getGroupUserList() {
      let groupId = this.talking.groupId;
      this.guestForm.groupId = groupId;
      api
        .listGroupUser(this.guestForm)
        .then((resp) => {
          this.loading = false;
          if (!resp.data || !resp.data.length) {
            util.warning('该用户不存在');
            return;
          }
          let list = resp.data;
          let selList = this.selectedUserList;
          for (let i in list) {
            let r = list[i];
            r.userNicename =
              r.joinMap && r.joinMap.u && r.joinMap.u.userNicename;
            if (
              (r.joinMap && r.joinMap.u && r.joinMap.u.userType == 3) ||
              (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename)
            ) {
              r.joinMap.u.userNicename = '游客#' + r.uid;
              r.joinMap.u.userType = 3;
              r.userNicename = r.joinMap.u.userNicename;
            }
            if (!selList.length) {
              this.$set(r, 'checked', false);
            }
            for (let j in selList) {
              let s = selList[j];
              if (r.id == s.id) {
                this.$set(l, 'checked', true);
              }
            }
          }
          this.allUserList = list;
        })
        .catch((err) => {
          this.loading = false;
          util.error('无法获取数据');
        });
    },
    selectUserChange(r, t) {
      r.checked = t;
      if (t) {
        this.selectedUserList.push(r);
      } else {
        let index = this.selectedUserList.findIndex((item) => {
          return item.id == r.id;
        });
        this.selectedUserList.splice(index, 1);
      }
    },
    onGroupUserName() {
      if (this.loading) {
        return;
      }
      if (this.setupGroupType == 1) {
        this.addGroupUser();
        return;
      }
      if (this.setupGroupType == 2) {
        this.delGroupUser();
        return;
      }
    },
    delSelectUser(item, i) {
      this.selectedUserList.splice(i, 1);
      this.$set(item, 'checked', false);
      let list = this.userNicenameList;
      for (let i in list) {
        let r = list[i];
        if (r.id == item.id) {
          this.$set(r, 'checked', false);
        }
      }
    },
    addGroupUser() {
      let userId = [];
      let userNicenameList = [];
      let groupId = this.talking.groupId;
      let name = this.talking.name || this.talking.groupName;
      this.selectedUserList.forEach((r) => {
        userNicenameList.push(r);
        userId.push(r.id);
      });
      if (userId.length == 0) {
        util.message('请选择用户');
        return;
      }
      this.isLoading = true;
      this.loading = true;
      api
        .editGroup({
          userId,
          groupId,
          name
        })
        .then((resp) => {
          this.isSetupGroupName = false;
          this.loading = false;
          this.isLoading = false;
          let r = resp.data;
          this.clearData();
          let t = this.talking;
          t.groupId = r.groupId;
          this.getGroupUser(t);
          let groupMap = this.$store.state.message.map.group[r.groupId];
          r.messageType = 2;
          if (groupMap) {
            groupMap.messageType = 2;
            if (groupMap.joinMap && groupMap.joinMap.g) {
              groupMap.joinMap.g.amount =
                r.joinMap && r.joinMap.g && r.joinMap.g.amount;
            }
            this.$store.commit('messageUpdateGroup', groupMap);
          }
          // for (let i in userNicenameList) {
          // 	let map = {}
          // 	let u = userNicenameList[i]
          // 	map.uid = userId[i]
          // 	map.id = userId[i]
          // 	map.joinMap = {
          // 		u : {
          // 			createTime: r.addTime,
          // 			userNicename: u.userNicename,
          // 			online: u.online,
          // 			cancel: u.cancel
          // 		},
          // 		ur: {
          // 			remark: ''
          // 		}
          // 	}
          // 	map.name = map.joinMap.u.userNicename
          // 	map.online = u.online
          // 	map.mute = 0
          // 	map.groupId = r.groupId
          // 	this.userList.push(map)
          // }
          // for(let i in this.userList){
          // 	let r = this.userList[i]
          // 	if(this.userMap[r.uid]){
          // 		continue
          // 	}
          //     r.name = r.joinMap.u.userNicename
          // 	this.userMap[r.uid] = r
          // }
          // this.userList = Object.values(this.userMap)

          util.success(resp.message);
        })
        .catch((err) => {
          this.loading = false;
          this.isLoading = false;
          util.error(err.message);
        });
    },
    delGroupUser() {
      let userId = [];
      let map = {};
      this.selectedUserList.forEach((r) => {
        userId.push(r.id);
        map[r.id] = r;
      });
      if (userId.length == 0) {
        util.message('请选择用户');
        return;
      }
      this.loading = true;
      this.isLoading = true;
      api
        .removeGroupUsers({ id: userId })
        .then((resp) => {
          this.isSetupGroupName = false;
          this.loading = false;
          this.isLoading = false;
          let t = this.talking;
          this.getGroupUser(t);
          this.pageTotal = this.pageTotal - this.selectedUserList.length;
          for (let i in this.selectedUserList) {
            let r = this.selectedUserList[i];
            let userItem = map[r.id];
            let index = this.userList
              .map(function (item) {
                return item.uid;
              })
              .indexOf(userItem.uid);
            this.userList.splice(index, 1);

            this.$delete(this.userMap, r.uid);
            this.$store.commit('updateGroupAmount', userItem);
          }
          this.clearData();
          util.success(resp.message);
        })
        .catch((err) => {
          this.loading = false;
          this.isLoading = false;
          util.error(err.message);
        });
    },
    cancelGroupName() {
      this.isSetupGroupName = false;
    },
    clearData() {
      let list = this.allUserList;
      for (let i in list) {
        let r = list[i];
        r.checked = false;
      }
      this.consumerName = '';
      this.selectedUserList = [];
      this.allUserList = [];
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id));
    },
    onmsg(msg) {
      let l = msg;
      for (let i in l) {
        let r = l[i];
        if (r.heart) {
          continue;
        }
        if (r.type == vars.TYPE_ENTER) {
          if (this.talking.messageType == 1 && r.roomId == this.talking.id) {
            r.joinMap = {
              u: {
                avatar: r.senderAvator,
                userNicename: r.senderName,
                cancel: 0,
                createTime: ''
              },
              ur: {
                remark: ''
              }
            };
            r.uid = r.senderId;
            r.name =
              r.name || (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename);
            r.mute = 0;
            r.online = 1;
            r.roomId = r.roomId;
            this.userList.unshift(r);
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped="scoped">
::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

@import url('../style/message1.less');
</style>

<style type="text/css">
.info-user-list .el-collapse-item {
  margin: 0px 0px 10px 0px !important;
}
.info-user-list .el-collapse-item__header {
  padding: 8px !important;
}
.input-with-remark {
  max-width: 200px;
  display: flex;
  flex: 1;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}
.input-with-remark .el-input {
  width: 100%;
  height: 22px;
  line-height: 22px !important;
}
.input-with-remark .el-input .el-input__inner {
  height: 22px;
  line-height: 22px !important;
  font-size: 12px;
  border: 0px;
  border-radius: 2px;
  padding: 0 5px;
}
.input-with-remark .el-input .el-input__inner:focus {
  border-color: #dcdfe6;
}
.input-with-remark .el-button {
  height: 22px;
  width: 22px;
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  border: 0px;
  color: #04c160;
}
.input-with-remark .el-button:hover {
  border-radius: 0px;
  border: 0px;
  background-color: #fff;
}
.info-user-list .el-collapse-item__content {
  padding-bottom: 0px;
}
.user-btn-box .el-button {
  margin-left: 15px !important;
}
.info-remark-input {
  max-width: 200px;
  display: flex;
  flex: 1;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}
.info-remark-input .el-input {
  width: 100%;
  height: 22px;
  line-height: 22px !important;
}
.info-remark-input .el-input .el-input__inner {
  height: 22px;
  line-height: 22px !important;
  font-size: 12px;
  border: 0px;
  border-radius: 2px;
  padding: 0 5px;
}
.info-remark-input .el-input .el-input__inner:focus {
  border-color: #dcdfe6;
}
.info-remark-input .el-button {
  height: 22px;
  width: 22px;
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  border: 0px;
  color: #04c160;
}
.info-remark-input .el-button:hover {
  border-radius: 0px;
  border: 0px;
  background-color: #fff;
}
.tag-btn .el-input--mini .el-input__inner {
  flex: 1;
  height: 25px;
  line-height: 25px;
  border: 0px;
  padding-left: 5px;
  border-radius: 0px;
}
.tag-input {
  width: 250px;
  display: flex;
  border: 1px solid #ebeef5;
  border-radius: 3px;
}
.tag-btn .el-button {
  padding: 0 5px;
  border: 0px;
  color: #04c160;
  border-radius: 0px;
}
.tag-btn .el-button:hover,
.tag-btn .el-button:focus {
  border: 0px;
  background-color: #fff;
  color: #04c160;
}
.setup-group-dialog .el-dialog__body {
  padding-left: 20px;
  padding-top: 0px;
}
.info-user-list-page .el-pagination {
  padding: 0px !important;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: center;
}
.info-detail-upload {
  position: absolute;
  top: 0px;
  width: 48px;
  height: 48px;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 50%;
  cursor: pointer;
  z-index: 999;
}
.info-detail-upload .el-upload {
  width: 48px;
  height: 48px;
}
</style>
