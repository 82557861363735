<template>
  <div class="edit-link">
    <div class="edit-link-top">
      <div class="edit-pic">
        <ag-uploader v-model="val.noticeThumb" :isAsset="true">
          <div style="width: 100px; height: 100px" v-if="!val.noticeThumb">
            添加图片
          </div>
          <img
            style="width: 100px; height: 100px"
            class="sale-pic-img"
            v-else
            :src="val.noticeThumb | url"
          />
        </ag-uploader>
      </div>
      <div class="edit-con">
        <input
          class="title-input"
          maxlength="10"
          v-model="val.noticeTitle"
          placeholder="请输入标题"
        />
        <textarea
          class="content-text"
          maxlength="48"
          v-model="val.noticeContent"
          placeholder="请输入内容"
        ></textarea>
      </div>
    </div>
    <div class="edit-link-input">
      <input
        class="link-input"
        v-model="val.noticeUrl"
        placeholder="请输入链接"
      />
    </div>
    <!-- <div class="edit-link-send">
             <el-button type="primary" @click="sendLink">设置直播间公告</el-button>

    	</div> -->
  </div>
</template>

<script>
import api from '../api/api.js';
import util from '@/config/util';

export default {
  props: ['value'],
  mounted() {
    this.val = this.value;
  },
  data() {
    return {
      val: {
        noticeTitle: '',
        noticeContent: '',
        noticeThumb: '',
        noticeUrl: ''
      }
    };
  },
  created() {},
  watch: {
    value(n, o) {
      this.val = n;
    }
  },
  computed: {},
  destroyed() {},
  methods: {}
};
</script>

<style lang="less" scoped="scoped">
@import url('../style/message.less');
</style>
