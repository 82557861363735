<template>
  <div class="user-lsit-content" v-if="item && item.viewMatch">
    <div class="user-tag-box" v-if="$store.infoLogin.id != item.uid">
      <div class="tag-list" v-if="item.tabsList && item.tabsList.length > 0">
        <div class="tag-item" v-for="t in item.tabsList">
          <span>{{ t }}</span>
          <i class="iconfont icon-close" @click="delTag(t, item)"></i>
        </div>
      </div>
      <div class="tag-btn">
        <div class="tag-add" v-if="isAddTag != item.id" @click="addTag(item)">
          <i class="iconfont icon-add"></i>
          <span>新增</span>
        </div>
        <div class="tag-input" v-if="isAddTag == item.id">
          <el-input
            size="mini"
            placeholder="备注标签"
            v-model="tagVal"
            @blur="handleTagBlur($event)"
            @keyup.native.enter="confirmTag(item)"
            :ref="'tag-' + item.id"
          >
          </el-input>
          <el-button
            icon="el-icon-check"
            @click.stop="confirmTag(item)"
          ></el-button>
        </div>
      </div>
    </div>
    <div class="user-info-box">
      <div class="info-it">
        <span>注册时间：</span>
        <span>{{
          item.viewMatch &&
          item.viewMatch.info &&
          item.viewMatch.info.createTime
        }}</span>
      </div>
      <div class="info-it">
        <span>终端类型：</span>
        <span>{{
          item.viewMatch && item.viewMatch.info && item.viewMatch.info.source
        }}</span>
      </div>
      <div class="info-it">
        <span>观赛次数：</span>
        <span v-for="m in item.viewMatch.view">{{ m.count }}</span>
      </div>
      <div class="info-it">
        <span>最后观看：</span>
        <span>-</span>
      </div>
      <div class="info-it">
        <span>设置纪录：</span>
        <span>-</span>
      </div>
    </div>
    <!-- v-if="$store.infoLogin.id != item.uid" -->
    <div class="user-btn-box">
      <el-button plain size="small" @click="$emit('siliao', item)"
        >私聊</el-button
      >
      <el-button plain size="small" @click="$emit('addGroup', item)"
        >加群</el-button
      >
      <el-button
        v-if="tab == 2"
        plain
        size="small"
        @click="$emit('removeGroupUser', item, index)"
        >移出</el-button
      >
      <el-button
        v-if="tab == 1"
        plain
        size="small"
        @click="$emit('onLine', item)"
        >{{ item.mute ? '解禁' : '禁言' }}</el-button
      >
      <el-button
        v-if="tab == 2"
        plain
        size="small"
        @click="$emit('onLine', item)"
        >{{ item.muted ? '解禁' : '禁言' }}</el-button
      >
      <el-button plain size="small" @click="$emit('onFrozen', item)">{{
        item.joinMap && item.joinMap.u && item.joinMap.u.cancel
          ? '解冻'
          : '冻结'
      }}</el-button>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      }
    },
    tab: {
      type: Number,
      default: 1
    },
    index: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      tagVal: '',
      isAddTag: 0
    };
  },
  methods: {
    addTag(item) {
      this.isAddTag = item.id;
      this.$nextTick(() => {
        if (this.$refs['tag-' + item.id]) {
          this.$refs['tag-' + item.id].focus();
        }
      });
    },
    handleTagBlur(e) {
      e.stopPropagation();
      this.$nextTick(() => {
        setTimeout(() => {
          this.isAddTag = 0;
        }, 1000);
      });
    },
    confirmTag(item) {
      let id = item.friendId || item.uid;
      let tab = this.tagVal;
      if (tab) {
        api
          .editUserTab({
            id,
            tab
          })
          .then((resp) => {
            util.success('添加成功');
            item.tabsList.push(tab);
            this.isAddTag = false;
            this.tagVal = '';
          })
          .catch((res) => {
            util.error(res.message);
          });
      }
    },
    delTag(tab, tag) {
      let id = tag.friendId || tag.uid;
      let l = tag.tabsList;

      api
        .removeUserTab({
          id,
          tab
        })
        .then((resp) => {
          l.splice(l.indexOf(tab), 1);
        })
        .catch((res) => {
          util.error(res.message);
        });
    }
  }
};
</script>

<style lang="less" scoped="scoped">
.user-lsit-content {
  padding: 0 8px 8px 8px;
}

.user-tag-box {
  border-top: 1px solid #ebedf2;
  padding: 8px 0;
}
.tag-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.tag-item {
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  background: #2c455c;
  border-radius: 20px;
  color: #fff;
  margin-right: 8px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 25px 0 20px;
  margin-bottom: 8px;
  font-size: 12px;
  i {
    position: absolute;
    right: 5px;
    font-size: 12px;
    top: 0;
    cursor: pointer;
  }
}
.tag-btn {
  width: 100%;
  display: flex;
  align-items: center;
}
.tag-add {
  width: 54px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 20px;
  cursor: pointer;
}

.user-info-box {
  border-top: 1px solid #ebedf2;
  padding: 8px 0;
}
.user-btn-box {
  border-top: 1px solid #ebedf2;
  padding: 16px 0 8px 0;
  display: flex;
  justify-content: center;
}
</style>
<style type="text/css">
.tag-btn .el-input--mini .el-input__inner {
  flex: 1;
  height: 25px;
  line-height: 25px;
  border: 0px;
  padding-left: 5px;
}
.tag-input {
  width: 250px;
  display: flex;
  border: 1px solid #ebeef5;
  border-radius: 3px;
}
.tag-btn .el-button {
  padding: 0 5px;
  border: 0px;
  color: #04c160;
}
.tag-btn .el-button:hover,
.tag-btn .el-button:focus {
  border: 0px;
  background-color: #fff;
  color: #04c160;
}
</style>
