<template>
  <el-select
    :disabled="disabled"
    :size="size"
    :placeholder="placeholder"
    v-model="val"
    :loading="loading"
    @change="change"
    :multiple="multiple"
    :clearable="true"
    :filterable="true"
  >
    <el-option
      v-for="(item, index) in data"
      :key="item[name] + '-' + index"
      :label="item[label]"
      :value="item[name]"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {},
    data: {
      type: Array
    },
    placeholder: {
      default: '请选择'
    },
    multiple: {
      default: false
    },
    disabled: {
      default: false
    },
    name: {
      default: 'id'
    },
    label: {
      default: 'name'
    },
    size: {
      default: 'mini'
    },
    loading: {
      default: false
    }
  },
  mounted() {
    //传入的变量不能改变, 所以新建一个内部变量
    this.val = this.value === 0 ? 0 : this.value || undefined;

    //console.log('select', this.name)
  },
  data() {
    return {
      //temp:this.uin(this.data),

      val: undefined
    };
  },
  created() {},
  watch: {
    val(value) {
      //触发改变v-model的值
      //console.log('ag-select emit input', value)
      this.$emit('input', value, this.val);
    },
    value(value) {
      this.val = value === 0 ? 0 : value || undefined;
    }
    // data(value){
    //          console.log('ag-select', value)
    //          }
  },
  destroyed() {
    //console.log('ag-select destroyed')
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    },
    uin(arr) {
      let hash = {};

      if (!Array.isArray(arr)) {
        return [];
      }
      const newArr = arr.reduceRight((item, next) => {
        hash[next.id] ? '' : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
      return newArr;
    }
  }
};
</script>

<style lang="less">
.el-select {
  width: 100%;
}
</style>
