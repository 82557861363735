<template>
  <div class="ag-radio">
    <el-radio-group v-model="val" :disabled="disabled">
      <el-radio-button
        v-for="item in data"
        :key="item[name]"
        :label="item[name]"
      >
        <span @click="onClick(item)">{{ item[label] }}</span>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    data: {
      type: Array
    },
    placeholder: {
      default: '请选择'
    },
    multiple: {
      default: false
    },
    disabled: {
      default: false
    },
    name: {
      default: 'id'
    },
    label: {
      default: 'name'
    },
    size: {
      default: 'mini'
    }
  },
  mounted() {
    //传入的变量不能改变, 所以新建一个内部变量
    this.val = this.value === 0 ? 0 : this.value || undefined;

    //console.log('select', this.name)
  },
  data() {
    return {
      //temp:this.uin(this.data),

      val: undefined
    };
  },
  created() {
    //console.log('ag-select created')
  },
  watch: {
    val(value) {
      //触发改变v-model的值
      console.log('ag-radio emit input', value);
      this.$emit('input', value);
    },
    value(value) {
      this.val = value === 0 ? 0 : value || undefined;
    }
    // data(value){
    //          console.log('ag-select', value)
    //          }
  },
  destroyed() {
    //console.log('ag-select destroyed')
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    },
    onClick(item) {
      this.$emit('onClick', item);
    },
    uin(arr) {
      let hash = {};

      if (!Array.isArray(arr)) {
        return [];
      }
      const newArr = arr.reduceRight((item, next) => {
        hash[next.id] ? '' : (hash[next.id] = true && item.push(next));
        return item;
      }, []);
      return newArr;
    }
  }
};
</script>

<style lang="less"></style>
