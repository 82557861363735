<template>
  <div class="edit-container">
    <el-form class="edit-form" size="mini" label-width="120px">
      <el-form-item label="主队">
        <span v-if="info.joinMap">{{ info.joinMap.schedule.teamNamea }}</span>
      </el-form-item>

      <el-form-item label="客队">
        <span v-if="info.joinMap">{{ info.joinMap.schedule.teamNameb }}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="copyEditInfo">复制</el-button>
        <el-button
          @click="pasteEditInfo"
          v-if="Object.keys($store.state.roomInfo).length"
          >粘贴</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="welcomeTo" :true-label="1" :false-label="0"
          >设置主播私聊欢迎词</el-checkbox
        >
      </el-form-item>
      <el-form-item v-show="welcomeTo" label="设置欢迎词">
        <el-input
          type="text"
          v-model="editinfo.welcome"
          clearable
          style="width: 200px"
          placeholder="设置欢迎词"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="editinfo.followVisible"
          :true-label="1"
          :false-label="0"
          >是否显示订阅弹幕</el-checkbox
        >
      </el-form-item>
      <el-form-item v-show="editinfo.followVisible" label="订阅消息">
        <el-input
          type="text"
          v-model="editinfo.followA"
          clearable
          style="width: 200px"
          placeholder="例如:谢谢"
        ></el-input>
        <span class="username">球迷***</span>
        <el-input
          type="text"
          v-model="editinfo.followB"
          clearable
          style="width: 200px"
          placeholder="例如:,带你飞"
        ></el-input>
        <span v-show="editinfo.followA || editinfo.followB"
          >预览：<el-tag>
            {{ editinfo.followA }}球迷***{{ editinfo.followB }}
          </el-tag></span
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="editinfo.enterVisible"
          :true-label="1"
          :false-label="0"
          >是否显示用户进入直播间弹幕</el-checkbox
        >
      </el-form-item>
      <el-form-item label="进入直播间弹幕" v-show="editinfo.enterVisible">
        <el-input
          type="text"
          v-model="editinfo.enterA"
          clearable
          style="width: 200px"
          placeholder="例如:欢迎"
        ></el-input>
        <span class="username">球迷***</span>
        <el-input
          type="text"
          v-model="editinfo.enterB"
          clearable
          style="width: 200px"
          placeholder="例如:,来啦!快来发个弹幕吧"
        ></el-input>
        <span v-show="editinfo.enterA || editinfo.enterB"
          >预览：<el-tag>
            {{ editinfo.enterA }}球迷***{{ editinfo.enterB }}
          </el-tag></span
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="danmuVisible"
          :true-label="1"
          :false-label="0"
          @change="onDanmuVisible"
          >是否启用定时弹幕</el-checkbox
        >
      </el-form-item>
      <el-form-item label="定时弹幕消息" v-show="danmuVisible">
        <el-input
          type="text"
          v-model="editinfo.danmu"
          clearable
          style="width: 300px"
        ></el-input>
        <el-input
          type="text"
          v-model="editinfo.danmuUrl"
          clearable
          placeholder="链接"
          style="width: 300px"
        ></el-input>
        <el-input-number
          v-model="editinfo.danmuInterval"
          :min="0"
          :max="1800"
        ></el-input-number>
        秒
      </el-form-item>
      <el-form-item>
        <el-checkbox
          v-model="adVisible"
          :true-label="1"
          :false-label="0"
          @change="onAdVisible"
          >是否启用定时聊天广告</el-checkbox
        >
      </el-form-item>
      <el-form-item label="定时聊天广告" v-show="adVisible">
        <ag-link ref="agLink" v-model="adinfo"></ag-link>
        <el-input-number
          v-model="editinfo.adInterval"
          :min="0"
          :max="1800"
        ></el-input-number>
        秒
      </el-form-item>
      <el-form-item label="直播间消息管理">
        <el-checkbox
          v-model="editinfo.userVisible"
          :true-label="1"
          :false-label="0"
          >是否显示用户进入</el-checkbox
        >
        <el-checkbox
          v-model="editinfo.guestVisible"
          :true-label="1"
          :false-label="0"
          >是否显示游客进入</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" @click="addDanmu">更新定时弹幕消息</el-button> -->
        <!-- <el-button type="danger" @click="clearIntervalDamnu">清除定时弹幕消息</el-button> -->
      </el-form-item>
      <el-form-item label="直播间公告">
        <el-radio v-model="radio" :label="1">普通公告</el-radio>
        <el-radio v-model="radio" :label="2">链接公告</el-radio>
      </el-form-item>
      <el-form-item>
        <div v-show="radio == 1">
          <el-input
            type="text"
            maxlength="22"
            clearable
            v-model="editinfo.noticeContent"
          ></el-input>
        </div>
        <ag-link ref="agLink" v-show="radio == 2" v-model="editinfo"></ag-link>
        <!-- <el-button type="primary" @click="agLinkSend">更新直播间公告</el-button> -->
        <!-- <el-button type="danger" @click="delMsg">清除公告</el-button> -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitEdit">保存</el-button>
        <el-button @click="closeEdit">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';
import vars from '@/config/vars';
import word from '@/config/word';
import config from '@/config/default';
export default {
  props: {
    info: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      vars,
      welcomeTo: 0,
      radio: 1,
      adVisible: 1,
      danmuVisible: 1,
      adinfo: {
        noticeContent: '',
        noticeThumb: '',
        noticeUrl: '',
        noticeTitle: ''
      },
      editinfo: {
        welcome: '',
        danmuUrl: '',
        enterVisible: 1,
        adInterval: 0,
        danmuInterval: 0,
        danmu: '',
        sysNotice: '',
        followB: '',
        noticeContent: '',
        noticeThumb: '',
        noticeUrl: '',
        noticeTitle: '',
        enterA: '',
        enterB: '',
        followVisible: 1,
        userVisible: 1,
        guestVisible: 1,
        title: '',
        followA: ''
      }
    };
  },
  mounted() {
    this.loadData(this.info);
  },
  watch: {
    info(n, o) {
      this.loadData(n);
    }
  },
  methods: {
    onDanmuVisible() {
      this.editinfo.danmuInterval = 0;
      this.editinfo.danmuUrl = '';
      this.editinfo.danmu = '';
    },
    loadData(info) {
      let row = info;
      if (!Object.keys(row).length) {
        return;
      }
      let live = row.joinMap.live;

      this.editinfo.welcome = live.welcome;
      if (live.welcome) {
        this.welcomeTo = 1;
      } else {
        this.welcomeTo = 0;
      }
      this.editinfo.id = row.liveId;

      this.editinfo.followVisible = live.followVisible;
      this.editinfo.followA = live.followA;
      this.editinfo.followB = live.followB;

      this.editinfo.enterVisible = live.enterVisible;
      this.editinfo.enterA = live.enterA;
      this.editinfo.enterB = live.enterB;

      this.danmuVisible = live.danmuInterval == 0 ? 0 : 1;
      this.editinfo.danmu = live.danmu;
      this.editinfo.danmuUrl = live.danmuUrl;
      this.editinfo.danmuInterval = live.danmuInterval;

      //定时广告
      this.editinfo.adInterval = live.adInterval;
      if (live.adThumb) {
        this.adVisible = 1;
        this.adinfo.noticeThumb = live.adThumb;
        this.adinfo.noticeTitle = live.adTitle;
        this.adinfo.noticeUrl = live.adUrl;
        this.adinfo.noticeContent = live.adContent;
      } else {
        this.adVisible = 0;
      }

      //后台直播间配置
      this.editinfo.userVisible = live.userVisible;
      this.editinfo.guestVisible = live.guestVisible;

      //公告
      this.editinfo.noticeThumb = live.noticeThumb;
      this.editinfo.noticeTitle = live.noticeTitle;
      this.editinfo.noticeUrl = live.noticeUrl;
      this.editinfo.noticeContent = live.noticeContent;
      this.radio = live.noticeThumb ? 2 : 1;
      this.editing = true;
    },
    closeEdit() {
      this.$emit('closeEdit');
    },
    onAdVisible() {
      if (this.adVisible == 0) {
        this.adinfo.noticeThumb = '';
        this.adinfo.noticeTitle = '';
        this.adinfo.noticeUrl = '';
        this.adinfo.noticeContent = '';
        this.editinfo.adInterval = 0;
      }
    },
    submitEdit() {
      if (!!this.editinfo.danmu != !!this.editinfo.danmuInterval) {
        util.message(
          this.editinfo.danmu ? '请设置定时弹幕时间' : '请设置定时弹幕内容'
        );
        return;
      }
      if (!this.welcomeTo) {
        this.editinfo.welcome = '';
      }

      this.editinfo.adUrl = this.adinfo.noticeUrl;
      this.editinfo.adContent = this.adinfo.noticeContent;
      this.editinfo.adThumb = this.adinfo.noticeThumb;
      this.editinfo.adTitle = this.adinfo.noticeTitle;
      this.editinfo.danmuInterval = !this.editinfo.danmuInterval
        ? 0
        : this.editinfo.danmuInterval;
      // console.log(this.editinfo.danmuInterval)
      if (this.radio == 1) {
        this.editinfo.noticeUrl = '';
        this.editinfo.noticeTitle = '';
        this.editinfo.noticeThumb = '';
      }
      if (this.editinfo.adInterval == 0) {
        this.editinfo.adThumb = '';
        this.editinfo.adContent = '';
        this.editinfo.adTitle = '';
        this.editinfo.adUrl = '';
        this.adVisible = 0;
        this.onAdVisible();
      }
      api
        .editUserLive(this.editinfo)
        .then((resp) => {
          // console.log(resp)
          this.$emit('submitEdit');
          util.message(resp.message);
        })
        .catch((res) => {
          util.error(res.message);
        });
    },
    copyEditInfo() {
      this.$store.state.roomInfo = Object.assign({}, this.editinfo);
      this.$store.state.roomAdinfo = Object.assign({}, this.adinfo);
      util.message('复制成功');
      console.log(
        'editinfoinfo',
        this.$store.state.roomAdinfo,
        this.$store.state.roomInfo
      );
    },
    pasteEditInfo() {
      let id = this.editinfo.id;
      this.editinfo = Object.assign({}, this.$store.state.roomInfo);
      this.editinfo.id = id;
      //定时广告
      if (Object.keys(this.$store.state.roomAdinfo).length > 0) {
        this.adinfo = Object.assign({}, this.$store.state.roomAdinfo);
        this.adVisible = 1;
      } else {
        this.adVisible = 0;
      }
      if (this.editinfo.welcome) {
        this.welcomeTo = 1;
      } else {
        this.welcomeTo = 0;
      }
      this.danmuVisible = this.editinfo.danmuInterval == 0 ? 0 : 1;

      //公告
      this.radio = this.editinfo.noticeThumb ? 2 : 1;
      util.message('粘贴成功');
      // console.log("editinfoinfo",this.editinfo,this.adinfo)
    }
  }
};
</script>

<style></style>
