import Vue from 'vue';

const state = {};
const actions = {};
const mutations = {};

state.roomInfo = {};
state.roomAdinfo = {};

function message(store) {
  Object.assign(store.state, state);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
}

export default message;
