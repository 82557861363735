import api from '@/api/api';
import util from '@/config/util';
import Vue from 'vue';
import vars from '@/config/vars';

const state = {};
const actions = {};
const mutations = {};
const getters = {};
state.infoLogin = {};
state.user = {};
state.userMap = {};
state.userRemarkMap = {};
state.userDepartmentMap = {};
state.roomUserMap = {};
state.groupUserMap = {};

getters.getinfoLogin = (state) => () => {
  return state.infoLogin;
};

getters.getRoomUserById = (state) => (rid, id) => {
  let m = state.roomUserMap[rid];
  if (!m || m == undefined) {
    return {};
  }
  return m[id];
};

getters.getRoomUserByRid = (state) => (rid) => {
  return state.roomUserMap[rid];
};

getters.getGroupUserByRid = (state) => (rid) => {
  return state.groupUserMap[rid];
};

getters.getGroupUserById = (state) => (rid, id) => {
  let m = state.groupUserMap[rid];
  if (!m || m == undefined) {
    return {};
  }
  return m[id];
};

getters.getUserRemarkId = (state) => (t, n, id) => {
  let user = state.userRemarkMap[id];

  let name =
    (n.joinMap && n.joinMap.u && n.joinMap.u.userNicename) || n.userNicename;
  if (user && user.id) {
    name = user.remark;
  } else if (t.messageType == 3) {
    let ur = t.joinMap && t.joinMap.ur;
    if (ur && ur.id) {
      name = ur.remark;
    }
    if (!name) {
      name = t.name;
    }
  }
  console.log('-----', name);
  return name;
};

mutations.setInfoLogin = function (state, data) {
  Vue.set(state, 'infoLogin', data);
};

mutations.userList = function (state, data) {
  state.userList = data;
};

mutations.roomUserList = function (state, data) {
  let id = data.roomId;
  let map = data.map;
  let user = state.roomUserMap[id];
  if (user != undefined) {
    map = Object.assign(map, user);
  }
  Vue.set(state.roomUserMap, id, map);
};

mutations.groupUserMutedList = function (state, data) {
  let id = data.groupId;
  let map = data.map;
  let user = state.groupUserMap[id];
  if (user != undefined) {
    map = Object.assign(map, user);
  }
  Vue.set(state.groupUserMap, id, map);
};

mutations.setUser = function (state, data) {
  let id = data.id;
  Vue.set(state.userMap, id, data.r);
};

mutations.setUserRemark = function (state, data) {
  let id = data.id;
  Vue.delete(state.userRemarkMap, id);
  Vue.set(state.userRemarkMap, id, data.r);
};

mutations.userMap = function (state, data) {
  let id = data.id;
  let r = data.r;
  let user = state.userMap[id];
  // if(!user || !user.id){
  Vue.set(state.userMap, id, r);
  // }
};

mutations.updateGroupMuted = function (state, data) {
  let id = data.groupId;
  let r = data.r;
  let groupMap = state.groupUserMap[id];
  // r.muted = data.muted
  Vue.set(groupMap, r.uid, r);
};

mutations.updateRoomMuted = function (state, data) {
  let id = data.roomId;
  let r = data;
  let roomUserMap = state.roomUserMap[id];
  Vue.set(roomUserMap, r.uid, r);
};

mutations.listUserRemark = function (state, data) {
  for (let i in data) {
    let r = data[i];
    let m = state.userRemarkMap[r.uid];
    if (m && m.id) {
      continue;
    }
    state.userRemarkMap[r.uid] = r;
  }
};

mutations.userDepartmentList = function (state, data) {
  for (let i in data) {
    let r = data[i];
    let m = state.userDepartmentMap[r.id];
    if (m && m.id) {
      continue;
    }
    state.userDepartmentMap[r.id] = r;
  }
};

actions.userList = function (context, data) {
  api.pageUsers().then((resp) => {
    if (!resp.data || !resp.data.list) {
      return;
    }
    context.commit('userList', resp.data.list);
  });
};
actions.listUserRemark = function (context, data) {
  let userRemarkMap = context.state.userRemarkMap;
  if (Object.keys(userRemarkMap).length > 0) {
    return;
  }
  api.listUserRemark().then((resp) => {
    if (!resp.data || !resp.data) {
      return;
    }
    context.commit('listUserRemark', resp.data);
  });
};

function user(store) {
  Object.assign(store.state, state);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
  Object.assign(store.getters, getters);
}

export default user;
