<template>
  <ul class="ag-tree">
    <li
      v-for="(item, index) in data"
      class="ag-tree-item"
      :class="{ expand: expandIndex[item.id] }"
      :key="item.id"
    >
      <span class="label" :class="{ checked: innerValue === item.id }">
        <a @click.stop="expand(item)">
          <i
            v-show="item.children.length > 0 && expandIndex[item.id]"
            class="iconfont icon-zhankai"
          ></i>
          <i
            v-show="item.children.length > 0 && !expandIndex[item.id]"
            class="iconfont icon-shouqi"
          ></i>
          <i
            v-show="item.children.length === 0"
            class="iconfont icon-yuandianxiao"
          ></i>
        </a>
        <label class="title">
          <input
            v-if="item.editable"
            v-model="item.title"
            placeholder="请输入内容"
            class="ag-tree-input"
          />
          <a v-else @click.stop="checked(item)" class="ag-tree-title">{{
            item.title
          }}</a>
        </label>
        <span class="bc" v-show="!readonly">
          <a v-if="!item.editable" @click.stop="append(item, index)">
            <i class="iconfont icon-add"></i>
          </a>
          <a v-if="!item.editable" @click.stop="remove(item, index)">
            <i class="iconfont icon-close"></i>
          </a>
          <a v-if="!item.editable" @click.stop="editable(item, index)">
            <i class="iconfont icon-edit"></i>
          </a>
          <a v-if="item.editable" @click.stop="submit(item, index)">
            <i class="iconfont icon-success"></i>
          </a>
          <a v-if="item.editable" @click.stop="cancel(item, index)">
            <i class="iconfont icon-wrong"></i>
          </a>
        </span>
      </span>
      <ag-tree
        :data="item.children"
        v-model="innerValue"
        :readonly="readonly"
      />
    </li>
  </ul>
</template>

<script>
import api from '@/api/api';
import util from '@/config/util';

export default {
  props: {
    data: {
      required: true
    },
    value: {},
    name: {
      default: 'KnowledgeCategory'
    },
    readonly: {
      default: false
    }
  },
  mounted() {
    this.innerValue = this.value;
  },
  data() {
    return {
      expandIndex: {},
      innerValue: undefined
    };
  },
  created() {},
  watch: {
    value(to, from) {
      //console.error('父节点的value改变了', to, from)
      this.innerValue = to;
      //console.error('修改innerValue', to, from)
    },
    innerValue(to, from) {
      //console.error('子节点修改innerValue', to, from)
      this.$emit('input', to);
    }
  },
  destroyed() {},
  methods: {
    expand(item) {
      if (item.children.length === 0) {
        return;
      }
      this.$set(this.expandIndex, item.id, !this.expandIndex[item.id]);
    },
    checked(item) {
      if (item.id === this.innerValue) {
        this.innerValue = undefined;
        return;
      }
      //console.error('点击=>修改innerValue', item.id, this.innerValue)
      this.innerValue = item.id;
    },
    append(item, index) {
      let r = { title: '', editable: true, parentId: item.id, children: [] };
      item.children.push(r);
    },
    submit(item, index) {
      api['edit' + this.name](item)
        .then((resp) => {
          item.id = resp.data.id;
          item.editable = false;
        })
        .catch(util.error);
    },
    editable(item, index) {
      this.$set(item, 'editable', true);
    },
    cancel(item, index) {
      if (!item.id) {
        this.data.splice(index, 1);
        return;
      }

      item.editable = false;
    },
    remove(item, index) {
      if (!item.id) {
        this.data.splice(index, 1);
        return;
      }

      api['remove' + this.name](item)
        .then((resp) => {
          this.data.splice(index, 1);
        })
        .catch(util.error);
    }
  }
};
</script>

<style lang="less" scoped>
@import '../style/var';

.ag-tree {
  padding: 10px;
  margin: 0;
}

.ag-tree .ag-tree {
  padding: 0 0 0 10px;
}

.title > * {
  display: block;
}

.expand > .ag-tree {
  display: none;
}

.empty > i {
  color: #d9d9d9;
}

.ag-tree-item {
  list-style: none;
  line-height: 30px;
}

.ag-tree-input {
  border: none;
  width: 120px;
  padding: 4px 4px;
}

.label {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;

  .title {
    flex-grow: 1;
  }

  .bc {
    flex-grow: 0;
    padding: 0;
    margin: 0;
    a {
      display: inline-block;
      width: 20px;
      text-align: center;
    }
  }
}

.label.checked,
.label:hover {
  background-color: @blue;

  color: white;

  border-radius: 5px;

  .ag-tree-title {
    color: white;
  }
  a {
    color: white;
  }
  a:hover {
    color: @light-blue;
  }
}
</style>
