import api from '@/api/api';
//import headTop from '@/components/headTop'
//import { mapState, mapActions } from 'vuex'

let state = [];
let actions = [];

actions.push('api');

for (let i in api) {
  state.push(i);
  actions.push(i + 'Click');
}
export default {
  state,
  actions
};
