<template>
  <div class="header_container row">
    <div class="center">
      <a @click="menuToggle">
        <i class="icon iconfont icon-all"></i>
      </a>
      <slot></slot>
    </div>
    <div class="right">
      <!-- <a style=" font-size: 12px;padding-right: 20px">

				<i class="icon iconfont icon-lishi"></i>
				系统授权还剩{{days}}天
			</a> -->
      <el-checkbox v-model="isShock" @change="onIsEnter">消息提醒</el-checkbox>
      <audio
        ref="audio"
        id="audio"
        preload="auto"
        src="../../static/audio.mp3"
      ></audio>
      <a style="font-size: 12px; padding-right: 20px; padding-left: 20px">
        &copy; 直播管理平台 版权所有
      </a>
      <!-- <div class="laglevel" :class="'laglevel'+laglevel"></div> -->
      <a style="font-size: 14px; padding-right: 5px">
        <span class="green tag" style="font-size: 13px">
          {{ getUserNicename }}
        </span>
      </a>
      <el-dropdown @command="handleCommand" menu-align="start">
        <a>
          V{{ version }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </a>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="home">首页</el-dropdown-item>
          <el-dropdown-item command="my">个人信息</el-dropdown-item>
          <el-dropdown-item command="changePass">修改密码</el-dropdown-item>
          <el-dropdown-item command="binding" v-if="!hasCodeGoogle"
            >绑定</el-dropdown-item
          >
          <el-dropdown-item command="unbinding" v-else>解绑</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog :visible.sync="$store.state.api.editUsersPass.visible" top="5vh">
      <span slot="title">
        <i class="icon iconfont icon-bianji"></i>
        <span>修改密码</span>
      </span>

      <el-form label-width="100px" size="small">
        <el-form-item label="原密码" prop="oldPass">
          <el-input
            v-model="$store.state.api.editUsersPass.form.oldPass"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input
            v-model="$store.state.api.editUsersPass.form.newPass"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <button
          @click="$store.state.api.editUsersPass.visible = false"
          class="cancel"
        >
          取 消
        </button>
        <button type="primary" class="sure">确 定</button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'修改密码'"
      width="40%"
      :visible.sync="editPaswordVisible"
    >
      <el-form class="edit-form" label-width="140px" size="mini">
        <el-form-item label="原密码">
          <el-input v-model="form.oldPass" type="password"></el-input>
        </el-form-item>

        <el-form-item label="新密码">
          <el-input v-model="form.newPass" type="password"></el-input>
        </el-form-item>

        <el-form-item label="新密码确认">
          <el-input v-model="form.confirmPass" type="password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editPaswordClick">提交</el-button>
        <el-button @click="editPaswordVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <google :isBangding="isBangding" @close="isBangding = false"></google>
  </div>
</template>

<script>
import { baseImgPath, version } from '@/config/env';
import { mapActions, mapState } from 'vuex';
import api from '@/api/api';
import util from '@/config/util';
import config from '@/config/default';
import env from '@/config/env';
import google from './agGoogle.vue';
export default {
  components: {
    google
  },
  data() {
    return {
      baseImgPath,
      infoLogin: {},
      form: {},
      editPaswordVisible: false,
      days: '-',
      version,
      isShock: false,
      laglevel: 2,
      isBangding: false
    };
  },
  created() {
    util.on('lag', this.lag);
  },
  computed: {
    hasCodeGoogle() {
      let u = this.$store.getters.getinfoLogin();
      if (u && u.hasCodeGoogle) {
        return true;
      }
      return false;
    },
    getUserNicename() {
      let u = this.$store.getters.getinfoLogin();
      if (u && u.userNicename) {
        return u.userNicename;
      }
      return '';
    }
  },
  mounted() {
    this.isShock = localStorage.getItem('isShock') == 1 ? true : false;

    this.onAudio();
  },
  methods: {
    lag(time) {
      if (time >= 0) {
        this.laglevel = Math.min(Math.floor(time / 1000), 2);
      }
    },
    onAudio() {
      this.$nextTick(function () {
        // let audioPlay = document.getElementById('audio')
        // audioPlay.play()
        // setTimeout(() => {
        //   audioPlay.pause()
        //   audioPlay.load()
        // }, 10)
      });
      // this.$refs.audio
    },
    onIsEnter(e) {
      this.isShock = e;
      if (e) {
        localStorage.isShock = 1;
      } else {
        localStorage.removeItem('isShock');
      }
      console.log('aaaa', e);
    },
    menuToggle() {
      window.app.$emit('menu');
    },
    ...mapActions(config.actions),
    async handleCommand(command) {
      if (command === 'home') {
        this.$router.push('/manage');
      } else if (command === 'logout') {
        const resp = await api.logout();
        if (resp.success) {
          // this.$router.push('/');
          location.href = '/';
          sessionStorage.removeItem('drawMap');
          sessionStorage.removeItem('drawRegionMap');
          // window.location.reload()
        } else {
          util.error(resp.message);
        }
      } else if (command === 'changePass') {
        this.editPaswordVisible = true;
      } else if (command === 'binding') {
        this.isBangding = true;
      } else if (command === 'unbinding') {
        util.confirm(
          `是否解绑账号"${this.$store.infoLogin.userNicename}"？`,
          () => {
            api
              .editCodeGoogleCloseA({ id: this.$store.infoLogin.id })
              .then((resp) => {
                util.message(resp.message);
                this.$store.infoLogin.hasCodeGoogle = false;
              })
              .catch((err) => {
                util.message(err.message);
              });
          }
        );
      } else if (command === 'my') {
        this.$router.push('/my');
      }
    },

    editPaswordClick() {
      if (this.form.newPass != this.form.confirmPass) {
        util.error('确认密码不一致');
        return;
      }

      api
        .editUsersPass(this.form)
        .then((resp) => {
          util.message(resp.message);
          this.editPaswordVisible = false;
        })
        .catch((res) => {
          util.error(res.message);
        });
    }
  }
};
</script>

<style lang="less">
@import '../style/mixin';
.right {
  display: flex;
}
.header_container {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;

  padding-left: 20px;
  padding-right: 20px;
  .el-breadcrumb__item {
    line-height: 50px;
  }
}

.head-title {
  color: #999c9e;
}
.el-dropdown-menu__item {
  text-align: center;
}

.logo-img {
  vertical-align: middle;
  height: 40px;
  margin-top: 5px;
}

.el-breadcrumb__item {
  float: inherit;
  margin-left: 10px;
  vertical-align: middle;
}

.logo-admin {
  font-size: 30px;
  margin-right: 40px;
  cursor: pointer;
}
.laglevel {
  width: 15px;
  height: 15px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 10px;
}
.laglevel0 {
  background-color: #07c160;
}
.laglevel1 {
  background-color: yellow;
}
.laglevel2 {
  background-color: red;
}
</style>
