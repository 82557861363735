import { saveAs } from 'file-saver';
import util from '@/config/util';

const word = {};
const defaultCss = `
    table{
        border-collapse: collapse;
    }
    td {
        border:1px solid #DCDFE6;
        height: 30px;
    }
`;
word.export = (fileName, el, css) => {
  if (!new Blob()) {
    util.confirm('该浏览器不支持文件导出，建议使用Firefox v20以后的版本');
    return;
  }

  fileName = typeof fileName !== 'undefined' ? fileName : 'jQuery-Word-Export';
  let data = {
    mhtml: {
      top:
        'Mime-Version: 1.0\nContent-Base: ' +
        location.href +
        '\nContent-Type: Multipart/related; boundary="NEXT.ITEM-BOUNDARY";type="text/html"\n\n--NEXT.ITEM-BOUNDARY\nContent-Type: text/html; charset="utf-8"\nContent-Location: ' +
        location.href +
        '\n\n<!DOCTYPE html>\n<html>\n_html_</html>',
      head: '<head>\n<meta http-equiv="Content-Type" content="text/html; charset=utf-8">\n<style>\n_styles_\n</style>\n</head>\n',
      body: '<body>_body_</body>'
    }
  };
  let options = {
    maxWidth: 624
  };
  let mhtmlBottom = '\n';
  let html;

  if (typeof el === 'string') {
    html = el;
  } else {
    html = el.innerHTML;
    // Clone selected element before manipulating it
    let markup = el;
    // Embed all images using Data URLs
    let images = Array();
    let img = markup.querySelectorAll('img');
    for (var i = 0; i < img.length; i++) {
      // Calculate dimensions of output image
      var w = Math.min(img[i].width, options.maxWidth);
      var h = img[i].height * (w / img[i].width);
      // Create canvas for converting image to data URL
      var canvas = document.createElement('CANVAS');
      canvas.width = w;
      canvas.height = h;
      // Draw image to canvas
      var context = canvas.getContext('2d');
      context.drawImage(img[i], 0, 0, w, h);
      // Get data URL encoding of image
      var uri = canvas.toDataURL('image/png');
      img[i].src = img[i].src;
      img[i].width = w;
      img[i].height = h;
      // Save encoded image to array
      images[i] = {
        type: uri.substring(uri.indexOf(':') + 1, uri.indexOf(';')),
        encoding: uri.substring(uri.indexOf(';') + 1, uri.indexOf(',')),
        location: img[i].src,
        data: uri.substring(uri.indexOf(',') + 1)
      };
    }

    // // Prepare bottom of mhtml file with image data

    for (var i = 0; i < images.length; i++) {
      mhtmlBottom += '--NEXT.ITEM-BOUNDARY\n';
      mhtmlBottom += 'Content-Location: ' + images[i].location + '\n';
      mhtmlBottom += 'Content-Type: ' + images[i].type + '\n';
      mhtmlBottom +=
        'Content-Transfer-Encoding: ' + images[i].encoding + '\n\n';
      mhtmlBottom += images[i].data + '\n\n';
    }
    mhtmlBottom += '--NEXT.ITEM-BOUNDARY--';
  }

  //TODO: load css from included stylesheet
  var styles = css || defaultCss;

  // Aggregate parts of the file together
  var fileContent =
    data.mhtml.top.replace(
      '_html_',
      data.mhtml.head.replace('_styles_', styles) +
        data.mhtml.body.replace('_body_', html)
    ) + mhtmlBottom;

  // Create a Blob with the file contents
  var blob = new Blob([fileContent], {
    type: 'application/msword;charset=utf-8'
  });
  saveAs(blob, fileName + '.doc');
};

export default word;
