<template>
  <div class="uploader">
    <el-upload
      :action="uploadUrl"
      :limit="1"
      ref="upload"
      :accept="accept"
      :on-success="onFileUploaded"
      :on-error="onerrorUploaded"
      :before-upload="onBeforeUploaded"
      :show-file-list="false"
      :data="{isAsset: isAsset}"
    >
      <slot></slot>
    </el-upload>
  </div>
</template>

<script>
import api from '../config/api.js';
import util from '../config/util.js';
import { uploadUrl, downloadUrl } from '../config/env.js';

export default {
  props: {
    accept: {
      type: String,
      default: '*'
    },
    isAsset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      uploadUrl: ''
    };
  },
  onLoad() {
    console.log('upload onLoad');
  },
  onShow() {},
  mounted() {
    this.uploadUrl = uploadUrl;
  },
  computed: {},
  methods: {
    onFileUploaded(response, file, fileList) {
      if (file.response && file.response.data) {
        util.message('上传成功');
        const thumb = file.response.data;
        this.$emit('input', thumb);
        this.$refs.upload.clearFiles();
      }
      // console.log('upload',this.uploadUrl)
    },
    onBeforeUploaded(file) {
      this.$emit('before', file);
    },
    onerrorUploaded() {
      this.$emit('error');
    }
  }
};
</script>

<style scoped>
.uploader {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
