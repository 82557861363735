import api from '@/api/api';
import util from '@/config/util';
import sport from '@/config/sport_manage';
import Vue from 'vue';
import vars from '@/config/vars';

const state = {};
const actions = {};
const mutations = {};

state.drawMap = util.getSessionStorage('drawMap');
state.drawList = [];
state.drawRegionMap = util.getSessionStorage('drawRegionMap');
state.menuActive = 1;

mutations.setRegion = function (state, data) {
  let id = data.id;
  Vue.delete(state.drawMap, id);
  Vue.delete(state.drawRegionMap, id);
  try {
    let drawMapJSON = JSON.stringify(state.drawMap);
    let drawRegionMapJSON = JSON.stringify(state.drawRegionMap);
    sessionStorage.setItem('drawMap', drawMapJSON);
    sessionStorage.setItem('drawRegionMap', drawRegionMapJSON);
  } catch (e) {
    console.error(e);
    //TODO handle the exception
  }
};
mutations.updateDrawReadMsg = function (state, data) {
  let msgId = data.msgId;
  for (let i in state.drawMap) {
    let r = state.drawMap[i];
    console.log('asd', r);
    let t = r.talking;
    if (t.messageType != 3 || t.messageType != 4) {
      continue;
    }
    let messageList = r.messageList;
    for (let i in messageList) {
      let m = messageList[i];
      if (m.msgId == msgId) {
        m.status = 1;
        try {
          Vue.set(state.drawMap, r.id, r);
          let drawMapJSON = JSON.stringify(state.drawMap);
          let drawRegionMapJSON = JSON.stringify(state.drawRegionMap);
          sessionStorage.setItem('drawMap', drawMapJSON);
          sessionStorage.setItem('drawRegionMap', drawRegionMapJSON);
        } catch (e) {
          console.error(e);
        }
        return;
      }
    }
  }
};

mutations.drawALL = function (state, data) {
  let r = data.item;
  console.log('drawAll', r);
  let t = data.t;
  let notmenu = data.notmenu;
  let key = r.id;
  if (r.messageType == 4) {
    key = `${r.messageType}-${r.id}`;
  }
  let m = state.drawMap[key];
  let talking = r.talking;
  if (!m || !m.id) {
    let name;
    if (talking.messageType == 1) {
      name = '直播间——' + talking.name;
    } else if (talking.messageType == 2) {
      name = '群组——' + talking.groupName;
    } else if (talking.messageType == 3) {
      name = '私聊——' + talking.name;
    } else if (talking.messageType == 4) {
      name = '小助手——' + talking.name;
    }
    let l = {
      id: key,
      name
    };
    Vue.set(state.drawRegionMap, key, l);
    Vue.set(state.drawMap, key, r);
    try {
      let drawMapJSON = JSON.stringify(state.drawMap);
      let drawRegionMapJSON = JSON.stringify(state.drawRegionMap);
      sessionStorage.setItem('drawMap', drawMapJSON);
      sessionStorage.setItem('drawRegionMap', drawRegionMapJSON);
    } catch (e) {
      console.error(e);
      //TODO handle the exception
    }
    if (!notmenu) {
      t.setMenuLength();
    }
  } else {
    t.$confirm(`是否解除钉选"${r.talking.name}"？`, '解除钉选', {
      confirmButtonText: '确定',
      cancelButtonText: '取消'
    })
      .then(() => {
        Vue.delete(state.drawMap, key);
        Vue.delete(state.drawRegionMap, key);
        try {
          let drawMapJSON = JSON.stringify(state.drawMap);
          let drawRegionMapJSON = JSON.stringify(state.drawRegionMap);
          sessionStorage.setItem('drawMap', drawMapJSON);
          sessionStorage.setItem('drawRegionMap', drawRegionMapJSON);
        } catch (e) {
          console.error(e);
          //TODO handle the exception
        }
        t.setMenuLength();
        t.drawlooks();
      })
      .catch(() => {});
  }
};
mutations.refreshDraw = function (state, data) {
  let id = data.id;
  let key = id;
  if (data.messageType == 4) {
    key = `${data.messageType}-${id}`;
  }
  let map = state.drawMap[key];
  let messageList = [];
  let list = data.list;
  if (!data.isfor) {
    list = data.list.reverse();
  }
  if (map && map.id) {
    for (let i in list) {
      let r = list[i];
      if (r.type == vars.TYPE_VOTE_USER) {
        continue;
      }
      if (r.type == vars.TYPE_VOTE) {
        continue;
      }
      if (r.type == vars.TYPE_FOLLOW) {
        continue;
      }
      if (r.contentType != 3) {
        let jsonContent = sport.jsonContent(r.content, r);
        r.jsonContent = jsonContent;
      }
      messageList.push(r);
    }
    Vue.set(map, 'messageList', messageList);
  }
};
actions.drawMapInit = function (context) {
  let map = context.state.drawMap;
  return new Promise((resolve, reject) => {
    if (!Object.values(map) || Object.values(map).length <= 0) {
      resolve();
      return;
    }
    for (let i in map) {
      let r = map[i];
      // console.log("aaa",r,i)
      let talking = r.talking;
      if (talking.messageType != 3 || talking.messageType != 4) {
        continue;
      }
      api
        .pageMsg({
          id: talking.id
        })
        .then((resp) => {
          let data = {
            id: r.id,
            list: resp.data.list,
            messageType: talking.messageType
          };
          context.commit('refreshDraw', data);
        })
        .catch((res) => {});
    }
    resolve();
  });
};

function message(store) {
  Object.assign(store.state, state);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
}

export default message;
