<template>
  <div class="ag-checkbox">
    <el-checkbox-group v-model="val">
      <el-checkbox-button
        v-for="(item, index) in data"
        :key="item[name]"
        :label="item[name]"
        :id="item[name] + '-' + index"
      >
        {{ item[label] }}
      </el-checkbox-button>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array
    },
    data: {
      type: Array
    },
    placeholder: {
      default: '请选择'
    },
    multiple: {
      default: true
    },
    disabled: {
      default: false
    },
    name: {
      default: 'id'
    },
    label: {
      default: 'name'
    },
    size: {
      default: 'mini'
    }
  },
  mounted() {
    //传入的变量不能改变, 所以新建一个内部变量
    this.val = this.value === 0 ? [] : this.value || [];
    //console.log('select', this.name)
  },
  data() {
    return {
      //temp:this.uin(this.data),
      val: []
    };
  },
  created() {
    //console.log('ag-select created')
  },
  watch: {
    val(value) {
      //触发改变v-model的值
      //console.log('ag-checkbox emit input', value)
      this.$emit('input', value);
    },
    value(value) {
      this.val = value === 0 ? [] : value || [];
    }
    // data(value){
    //          console.log('ag-select', value)
    //          }
  },
  destroyed() {
    //console.log('ag-select destroyed')
  },
  methods: {
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>

<style lang="less"></style>
