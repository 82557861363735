import Vue from 'vue';
const state = {};
const actions = {};
const mutations = {};
const getters = {};

state.unreadMap = {}; //未读
state.readMap = {}; //已读

getters.getReadMsgId = (state) => (id, status) => {
  if (status == 1) {
    return true;
  }
  let r = state.readMap[id];
  if (r) {
    return true;
  }

  return false;
};
//  未读信息
mutations.unReadMsg = function (state, data) {};
mutations.addReadMsg = function (state, data) {
  let id = data.id;
  Vue.set(state.readMap, id, true);
};
mutations.removeUnReadMsg = function (state, data) {};

function message(store) {
  Object.assign(store.state, state);
  Object.assign(store.getters, getters);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
}

export default message;
