import util from './util';

const api = {};

/**
 * 接口：获取管理员列表
 * 参数列表:
Long assistId
 */
api.listAssistUp = function (data) {
  return util.post('listAssistUp', data);
};

/**
 * 接口：獲得推流商列表
 * @returns {Boolean} success
 * @returns {Object[]} data - Stream Providers
 * @returns {Long} data[].id
 * @returns {String} data[].providerCode
 * @returns {String} data[].providerName
 * @returns {String} data[].pullDomain
 * @returns {String} data[].pullSecret
 * @returns {String} data[].pullThumb
 * @returns {String} data[].pushDomain
 * @returns {String} data[].pushSecret
 * @returns {String} data[].remark
 */
api.listStreamProvider = function (data) {
  return util.post('listStreamProvider', data)
}

/**
 * 接口：新增/修改推流商列表
 * @param {Long} [id]
 * @param {String} providerCode
 * @param {String} providerName
 * @param {String} pullDomain
 * @param {String} pullSecret
 * @param {String} pullThumb
 * @param {String} pushDomain
 * @param {String} pushSecret
 * @param {String} [remark]
 */
api.editStreamProvider = function (data) {
  return util.post('editStreamProvider', data)
}

/**
 * 接口：刪除推流商列表
 * @param {Long} id
 *
 * @returns {Boolean} success
 */
api.removeStreamProvider = function (data) {
  return util.post('removeStreamProvider', data)
}

/**
 * 接口：獲得子平台推流清單
* @param {String} brand
*
 * @returns {Boolean} success
 * @returns {Object[]} data - Brand Stream Providers
 * @returns {Long} data[].id
 * @returns {String} data[].brand
 * @returns {Object} data[].joinMap.streamProvdier - 單純按照原格式產出
 * @returns {String} data[].joinMap.streamProvdier.providerCode
 * @returns {String} data[].joinMap.streamProvdier.providerName
 * @returns {Long} data[].status
 * @returns {Long} data[].priority
 * @returns {Long} data[].pushLimit
 * @returns {Long} data[].usedPushAmount
 * @returns {Long} data[].streamProviderId
 */
api.listBrandStreamProvider = function (data) {
  return util.post('listBrandStreamProvider', data)
}

/**
 * 接口：新增/修改子平台推流列表
 * @param {Long} [id]
 * @param {String} brand
 * @param {Long} streamProviderId
 * @param {Long} pushLimit
 * @param {Long} priority
 * @param {Long} status
 *
 * @returns {Boolean} success
 */
api.editBrandStreamProvider = function (data) {
  return util.post('editBrandStreamProvider', data)
}

/**
 * 接口：刪除子平台推流
 * @param {Long} id
 *
 * @returns {Boolean} success
 */
api.removeBrandStreamProvider = function (data) {
  return util.post('removeBrandStreamProvider', data)
}

/**
 * 接口：獲得推流商歷史列表
 *
 * @returns {Boolean} success
 * @returns {Object[]} data
 * @returns {String} data[].providerCode
 * @returns {String} data[].providerName
 */
api.listStreamProviderOptions = function (data) {
  return util.post('listStreamProviderOptions', data)
}

/**
 * 接口：獲得批量發送申請列表
 * @param {Long} size
 * @param {Long} page
 * @param {Long} [status]
 * @param {Date} [fromAddDate]
 * @param {Date} [endAddDate]
 *
 * @returns {Boolean} success
 * @returns {Object[]} list
 * @returns {Long} list[].id
 * @returns {Long} list[].uid
 * @returns {Long} list[].status - 1:審核中 2:審核不通過 3:等待發送 4:發送中 5:已完成
 * @returns {Long} list[].contentType - 1:普通消息 2:廣告 3:圖片
 * @returns {String} list[].requestDesc
 * @returns {Long} list[].departmentId
 * @returns {Long} list[].sendTotal
 * @returns {Long} list[].receiverTotal
 * @returns {Date} list[].addTime
 * @returns {String} list[].content
 * @returns {String} [list[].adThumb]
 * @returns {String} [list[].adTitle]
 * @returns {String} [list[].adUrl]
 */
api.pageBatchMsg = function (data) {
  return util.post('pageBatchMsg', data)
}

/**
 * 接口：編輯/新增批量發送申請
 * @param {Long} contentType - 1:普通消息 2:廣告 3:圖片
 * @param {String} requestDesc
 * @param {String} content
 * @param {String} [adTitle] - required in contentType=3
 * @param {String} [adThumb] - required in contentType=3
 * @param {String} [adUrl] - required in contentType=3
 *
 * @returns {Boolean} success
 */
api.editBatchMsg = function (data) {
  return util.post('editBatchMsg', data)
}

/**
 * 接口：獲得批量發送審核列表
 * @param {Long} size
 * @param {Long} page
 *
 * @returns {Boolean} success
 * @returns {Object[]} list
 * @returns {Long} list[].id
 * @returns {Long} list[].uid
 * @returns {Long} list[].status - 1:審核中 2:審核不通過 3:等待發送 4:發送中 5:已完成
 * @returns {Long} list[].contentType - 1:普通消息 2:廣告 3:圖片
 * @returns {String} list[].requestDesc
 * @returns {Long} list[].departmentId
 * @returns {Long} list[].sendTotal
 * @returns {Long} list[].receiverTotal
 * @returns {Date} list[].addTime
 * @returns {String} list[].content
 * @returns {String} [list[].adThumb]
 * @returns {String} [list[].adTitle]
 * @returns {String} [list[].adUrl]
 * @returns {Object} list[].joinMap.requester - 單純按照原格式產出
 * @returns {Long} list[].joinMap.requester.adMin
 * @returns {String} list[].joinMap.requester.departmentName
 * @returns {Boolean} list[].joinMap.requester.hasCodeGoogle
 * @returns {Boolean} list[].joinMap.requester.hasPass
 * @returns {Long} list[].joinMap.requester.socketIndex
 * @returns {String} list[].joinMap.requester.userNicename
 */
api.pageBatchMsgReview = function (data) {
  return util.post('pageBatchMsgReview', data)
}

/**
 * 接口：編輯/新增批量發送審核
 * @param {Long} id
 * @param {Boolean} approve
 *
 * @returns {Boolean} success
 * @returns {String} message
 */
api.editBatchMsgReview = function (data) {
  return util.post('editBatchMsgReview', data)
}

/**
 * 接口：獲得批量發送審核紀錄清單
 * @param {Long} size
 * @param {Long} page
 * @param {Long} [status]
 * @param {Date} [fromAddDate]
 * @param {Date} [endAddDate]
 *
 * @returns {Boolean} success
 * @returns {Object[]} list
 * @returns {Long} list[].id
 * @returns {Long} list[].uid
 * @returns {Long} list[].status - 1:審核中 2:審核不通過 3:等待發送 4:發送中 5:已完成
 * @returns {Long} list[].contentType - 1:普通消息 2:廣告 3:圖片
 * @returns {String} list[].requestDesc
 * @returns {Long} list[].departmentId
 * @returns {Long} list[].sendTotal
 * @returns {Long} list[].receiverTotal
 * @returns {Date} list[].addTime
 * @returns {String} list[].content
 * @returns {Date} list[].reviewTime
 * @returns {Long} list[].reviewerUid
 * @returns {String} [list[].adThumb]
 * @returns {String} [list[].adTitle]
 * @returns {String} [list[].adUrl]
 * @returns {Object} list[].joinMap.requester - 單純按照原格式產出
 * @returns {Long} list[].joinMap.requester.adMin
 * @returns {String} list[].joinMap.requester.departmentName
 * @returns {Boolean} list[].joinMap.requester.hasCodeGoogle
 * @returns {Boolean} list[].joinMap.requester.hasPass
 * @returns {Long} list[].joinMap.requester.socketIndex
 * @returns {String} list[].joinMap.requester.userNicename
 * @returns {Object} list[].joinMap.reviewer - 單純按照原格式產出
 * @returns {Long} list[].joinMap.reviewer.adMin
 * @returns {String} list[].joinMap.reviewer.departmentName
 * @returns {Boolean} list[].joinMap.reviewer.hasCodeGoogle
 * @returns {Boolean} list[].joinMap.reviewer.hasPass
 * @returns {Long} list[].joinMap.reviewer.socketIndex
 * @returns {String} list[].joinMap.reviewer.userNicename
 */
api.pageBatchMsgHistory = function (data) {
  return util.post('pageBatchMsgHistory', data)
}


/**
 * 接口：添加/修改推荐
 * 参数列表:
Long id
Long matchId
Long scheduleId
String season
Long result
String target
String reason
String title
 */
api.editRecommend = function (data) {
  return util.post('editRecommend', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removeStanding = function (data) {
  return util.post('removeStanding', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long id
必填	Long cancel
 */
api.editUsersCancel = function (data) {
  return util.post('editUsersCancel', data);
};

/**
 * 接口：
 * 参数列表:
 */
api.listMatchScheduleClass = function (data) {
  return util.post('listMatchScheduleClass', data);
};

/**
 * 接口 赛事类型 (Admin)
 * 参数列表:
 */
api.listMatchScheduleClassA = function (data) {
  return util.post('listMatchScheduleClassA', data);
};

/**
 * 接口：
 * 参数列表:
 */
api.listWhiteIp = function (data) {
  return util.post('listWhiteIp', data);
};

/**
 * 接口：用户申请实名认证
 * 参数列表:
String aUrl
String bUrl
String cUrl
Long id
 */
api.editIdcard = function (data) {
  return util.post('editIdcard', data);
};

/**
 * 接口：投票
 * 参数列表:
Long id
Long roomId
Long status
 */
api.pageVote = function (data) {
  return util.post('pageVote', data);
};

/**
 * 接口：新增/修改美称
 * 参数列表:
String name
String nice
Long id
 */
api.editNicename = function (data) {
  return util.post('editNicename', data);
};

/**
 * 接口：离开直播间
 * 参数列表:
必填	Long ruid
 */
api.editRoomUsersLeave = function (data) {
  return util.post('editRoomUsersLeave', data);
};

/**
 * 接口：取消关注主播
 * 参数列表:
必填	Long id
 */
api.editFollowCancel = function (data) {
  return util.post('editFollowCancel', data);
};

/**
String t
 */
api.redirect = function (data) {
  return util.post('redirect', data);
};

/**
Long id
 */
api.removeAppVer = function (data) {
  return util.post('removeAppVer', data);
};

/**
 * 接口：
 * 参数列表:
Long friendId
String name
Long unread
 */
api.pageFriend = function (data) {
  return util.post('pageFriend', data);
};

/**
 * 接口：获取赛程缓存
 * 参数列表:
Date time
Long scheduleClass
Long hot
Long done = 0l
 */
api.pageScheduleCache = function (data) {
  return util.post('pageScheduleCache', data);
};

/**
 * 接口：
 * 参数列表:
必填	String mobile
必填	String country
 */
api.editSendCode = function (data) {
  return util.post('editSendCode', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removeArticle = function (data) {
  return util.post('removeArticle', data);
};

/**
 * 接口：删除直播间消息
 * 参数列表:
必填	Long id
 */
api.removeRoomMsg = function (data) {
  return util.post('removeRoomMsg', data);
};

/**
 * 接口：
 * 参数列表:
Long friendId
Long fromId
 */
api.listMsg = function (data) {
  return util.post('listMsg', data);
};

/**
 * 接口：
 * 参数列表:
Long friendId
String name
Long unread
 */
api.pageAssistFriend = function (data) {
  return util.post('pageAssistFriend', data);
};

/**
 * 接口：设置用户标签，仅所属群组可见
 * 参数列表:
Long id
String tab
Long departmentId
 */
api.editUserTabForDepartment = function (data) {
  return util.post('editUserTabForDepartment', data);
};

/**
 * 接口：添加/修改积分榜
 * 参数列表:
Long id
Long matchId
Long scheduleId
Long teamId
String season
String description
Long drawAll
Long drawAway
Long drawHome
String form
Long goalAgainstAll
Long goalAgainstAway
Long goalAgainstHome
Long goalDiff
Long goalForAll
Long goalForAway
Long goalForHome
String groupName
Long loseAll
Long loseAway
Long loseHome
Long playedAll
Long playedAway
Long playedHome
Long points
Long rank
Long state
String status
Long winAll
Long winAway
Long winHome
 */
api.editStanding = function (data) {
  return util.post('editStanding', data);
};

/**
 * 接口：
 * 参数列表:
Long uid
Long matchId
Long scheduleClass
Date date
Long hot
Long status
String city
String country
String viewKey
Long live
Long scheduleId
 */
api.listMatchSchedule = function (data) {
  return util.post('listMatchSchedule', data);
};

/**
 * 接口：删除队伍
 * 参数列表:
Long id
 */
api.removeNicename = function (data) {
  return util.post('removeNicename', data);
};

/**
 * 接口：
 * 参数列表:
Long scheId
 */
api.pageUserMatch = function (data) {
  return util.post('pageUserMatch', data);
};

/**
 * 接口：
 * 参数列表:
必填	String code
必填	String password
 */
api.editUsersPassByCode = function (data) {
  return util.post('editUsersPassByCode', data);
};

/**
必填	Long matchId
 */
api.listMatchEvent = function (data) {
  return util.post('listMatchEvent', data);
};

/**
 * 接口：开播/上播
 * 参数列表:
Long id
Long hide
String viewKey
 */
api.editUsersLiveOpen = function (data) {
  return util.post('editUsersLiveOpen', data);
};

/**
 * 接口：完成一次性任务
 * 参数列表:
必填	Long taskId
 */
api.editUserOnceTask = function (data) {
  return util.post('editUserOnceTask', data);
};

/**
 * 接口：新增/修改队伍
 * 参数列表:
String name
String nameEn
String thumb
Long scheduleClass
Long id
 */
api.editTeam = function (data) {
  return util.post('editTeam', data);
};

/**
 * 接口：
 * 参数列表:
 */
api.infoLogin = function (data) {
  return util.post('infoLogin', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long id
 */
api.editFriendCancel = function (data) {
  return util.post('editFriendCancel', data);
};

/**
 * 接口：删除用户标签
 * 参数列表:
Long id
String tab
 */
api.removeUserTab = function (data) {
  return util.post('removeUserTab', data);
};

/**
 * 接口：在群组列表隐藏,收发信息会取消隐藏
 * 参数列表:
必填	Long id
 */
api.editGroupUserHide = function (data) {
  return util.post('editGroupUserHide', data);
};

/**
 * 接口：修改群组配置
 * 参数列表:
必填	Long groupId
必填	String name
Long mute
 */
api.editGroupName = function (data) {
  return util.post('editGroupName', data);
};

/**
 * 接口：按天查询赛程
 * 参数列表:
Date date
Long hot
 */
api.listSchedule = function (data) {
  return util.post('listSchedule', data);
};

/**
 * 接口：查询房间用户列表
 * 参数列表:
必填	Long roomId
Long userType
Long online
String name
 */
api.listRoomUser = function (data) {
  return util.post('listRoomUser', data);
};

/**
String name
String remark
Long id
 */
api.editGrade = function (data) {
  return util.post('editGrade', data);
};

/**
 * 接口：轮播图管理
 * 参数列表:
Long type
Long status
 */
api.pageSwiper = function (data) {
  return util.post('pageSwiper', data);
};

/**
 * 接口：新增/修改直播间信息
 * 参数列表:
必填	Long id
Long amount
Long amountRate
Long priority
thumb_type
String thumb
 */
api.editRoom = function (data) {
  return util.post('editRoom', data);
};

/**
 * 接口：删除赛事屏蔽国家
 * 参数列表:
Long id
 */
api.removeMatchCountry = function (data) {
  return util.post('removeMatchCountry', data);
};

/**
必填	String account
必填	String code
String clientid
String country
 */
api.loginByCode = function (data) {
  return util.post('loginByCode', data);
};

/**
 * 接口：删除赛事屏蔽城市
 * 参数列表:
Long id
 */
api.removeMatchCity = function (data) {
  return util.post('removeMatchCity', data);
};

/**
 * 接口：查询房间用户列表
 * 参数列表:
必填	Long roomId
Long userType
Long online
String name
 */
api.pageRoomUser = function (data) {
  return util.post('pageRoomUser', data);
};

/**
 */
api.screenshot = function (data) {
  return util.post('screenshot', data);
};

/**
 * 接口：删除IP白名单
 * 参数列表:
必填	Long id
 */
api.removeWhiteIp = function (data) {
  return util.post('removeWhiteIp', data);
};

/**
 * 接口：添加/修改特权
 * 参数列表:
String url
String name
String icon
Long parentId
Long priority
Long type
Long status
Long grade
Long id
 */
api.editPrivilege = function (data) {
  return util.post('editPrivilege', data);
};

/**
 * 接口：删除元数据
 * 参数列表:
必填	Long id
 */
api.removeAction = function (data) {
  return util.post('removeAction', data);
};

/**
 * 接口：群静音
 * 参数列表:
必填	Long id
Long mute
 */
api.editGroupUserMute = function (data) {
  return util.post('editGroupUserMute', data);
};

/**
 * 接口：群禁言
 * 参数列表:
必填	Long id
Long muted
 */
api.editGroupUserMuted = function (data) {
  return util.post('editGroupUserMuted', data);
};

/**
必填	Long matchId
 */
api.listMatchDetail = function (data) {
  return util.post('listMatchDetail', data);
};

/**
String deviceId
必填	String deviceType
String deviceId
int index
String url
 */
api.infoSocket = function (data) {
  return util.post('infoSocket', data);
};

/**
 * 接口：
 * 参数列表:
Long matchId
Long scheduleClass
Long hot
Long done
Date time
Long code
 */
api.pageSchedule = function (data) {
  return util.post('pageSchedule', data);
};

/**
 * 接口：删除元数据
 * 参数列表:
必填	Long id
 */
api.removeMetaData = function (data) {
  return util.post('removeMetaData', data);
};

/**
 * 接口：新增常用标签
 * 参数列表:
String tab
 */
api.editCommonTab = function (data) {
  return util.post('editCommonTab', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long matchId
 */
api.listMatchCity = function (data) {
  return util.post('listMatchCity', data);
};

/**
必填	Long matchId
 */
api.listPlayerTechnic = function (data) {
  return util.post('listPlayerTechnic', data);
};

/**
 * 接口：删除用户标签
 * 参数列表:
必填	Long id
 */
api.removeDepartment = function (data) {
  return util.post('removeDepartment', data);
};

/**
 * 接口：私聊分页查询
 * 参数列表:
Long friendId
Long toId
Long fromId
Long id
 */
api.pageMsg = function (data) {
  return util.post('pageMsg', data);
};

/**
 * 接口：
 * 参数列表:
Long matchId
String device
String city
 */
api.pageMatchCity = function (data) {
  return util.post('pageMatchCity', data);
};

/**
 */
api.testRedis = function (data) {
  return util.post('testRedis', data);
};

/**
 * 接口：删除群组消息
 * 参数列表:
必填	Long id
 */
api.removeGroupMsg = function (data) {
  return util.post('removeGroupMsg', data);
};

/**
 * 接口：获取枚举数据树
 * 参数列表:
 */
api.treeMetaData = function (data) {
  return util.post('treeMetaData', data);
};

/**
 * 接口：查询用户一次性任务
 * 参数列表:
Long taskId
 */
api.listUserOnceTask = function (data) {
  return util.post('listUserOnceTask', data);
};

/**
 * 接口：获取实名申请列表
 * 参数列表:
String name
String mobile
Long id
Long status
Date fromBeginDate
Date toBeginDate
 */
api.pageIdcard = function (data) {
  return util.post('pageIdcard', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long friendId
 */
api.editFriendRead = function (data) {
  return util.post('editFriendRead', data);
};

/**
 * 接口：获取用户所属客服组列表，仅同一群组可见
 * 参数列表:
Long uid
Long departmentId
 */
api.listUserTabForDepartment = function (data) {
  return util.post('listUserTabForDepartment', data);
};

/**
 * 接口：获取队伍列表
 * 参数列表:
String name
Long scheduleClass
Long matchId
Long ts
 */
api.pageTeam = function (data) {
  return util.post('pageTeam', data);
};

/**
int deviceType = CmfUsers.DEVICE_TYPE_H5
 */
api.syncUser = function (data) {
  return util.post('syncUser', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long[] id
必填	Long friendId
 */
api.editMsgRead = function (data) {
  return util.post('editMsgRead', data);
};

/**
 * 接口：删除队伍
 * 参数列表:
Long id
 */
api.removeTeam = function (data) {
  return util.post('removeTeam', data);
};

/**
 * 接口：
 * 参数列表:
Long ishot
 */
api.listLiveClass = function (data) {
  return util.post('listLiveClass', data);
};

/**
 * 接口：完成每日任务
 * 参数列表:
必填	Long taskId
 */
api.editUserDailyTask = function (data) {
  return util.post('editUserDailyTask', data);
};

/**
 * 接口： 发送群组消息
 * 参数列表:
必填	Long groupId
必填	String content
必填	Long contentType = Message.CONTENT_TYPE_TEXT
String thumb
String title
String url
String refName
String refContent
long push = 0
 */
api.editGroupMsg = function (data) {
  return util.post('editGroupMsg', data);
};

/**
 * 接口：获取赛事列表(控制項)
 * 参数列表:
String name
Long id
Long classId
Long hot
Date date
Long status
String nameAbbr
Long ts
 */
api.pageMatch = function (data) {
  return util.post('pageMatchA', data);
};

/**
 * 接口：获取赛事列表(for pageMatch)
 */
api.pageMatchAdmin = function (data) {
  return util.post('pageMatchAdmin', data);
};

/**
 * 接口：
 * 参数列表:
Long friendId
String name
 */
api.listFriend = function (data) {
  return util.post('listFriend', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removePlayer = function (data) {
  return util.post('removePlayer', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long friendId
 */
api.editFriend = function (data) {
  return util.post('editFriend', data);
};

/**
 * 接口：新增/修改敏感字
 * 参数列表:
String word
Long id
Long status = 1l
 */
api.editBlacklist = function (data) {
  return util.post('editBlacklist', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long[] id
必填	Long groupId
 */
api.editGroupMsgRead = function (data) {
  return util.post('editGroupMsgRead', data);
};

/**
 * 接口：修改元数据
 * 参数列表:
Long id
Long parentId = 0l
Long status = 1l
String type
必填	String label
String value
Long companyId
 */
api.editMetaData = function (data) {
  return util.post('editMetaData', data);
};

/**
 * 接口：修改元数据
 * 参数列表:
Long id
Long parentId = 0l
Long status = 1l
String type
必填	String label
String value
Long companyId
 */
api.editMetaDataA = function (data) {
  return util.post('editMetaDataA', data);
};

/**
 * 接口：获取主播信息
 * 参数列表:
Long id
String search
 */
api.pageUp = function (data) {
  return util.post('pageUp', data);
};

/**
 * 接口：获取管理员下的主播列表
 * 参数列表:
Long id
String search
 */
api.listUpA = function (data) {
  return util.post('listUpA', data);
};

/**
 * 接口：获取反馈列表
 * 参数列表:
String content
Long id
Long uid
Long status
Date beginDate
Date endDate
 */
api.pageFeedback = function (data) {
  return util.post('pageFeedbackA', data);
};

/**
 * 接口：设置赛事列表
 * 参数列表:
String name
String thumb
Long id
Long classId
Long hot
Long status
Long priority
String nameEn
String nameAbbr
String nameEnAbbr
String currentSeason
String sumRound
String currentRound
String color
 */
api.editMatch = function (data) {
  return util.post('editMatch', data);
};

/**
 * 接口：获取赛程列表
 * 参数列表:
String teamNamea
String teamNameb
String title
Date fromBeginDate
Date toBeginDate
Long matchId
Long classId
Long status
Long id
Long hot
String matchName
String groupName
Long live
 */
api.pageMatchSchedule = function (data) {
  return util.post('pageMatchScheduleA', data);
};

/**
 * 接口：添加/修改新闻
 * 参数列表:
Long id
Long articleId
Long type = 1l
String url
Long priority = 0l
Date endTime
Date startTime
Long status
String thumb
String thumbPc
Long[] device
Long matchId
 */
api.editSwiper = function (data) {
  return util.post('editSwiper', data);
};

/**
 * 接口：设置推送设备号
 * 参数列表:
String registerId
 */
api.editRegisterId = function (data) {
  return util.post('editRegisterId', data);
};

/**
 * 接口：获取用户列表
 * 参数列表:
String search
String userLogin
String mobile
String userNicename
Long userType
String tab
Long roomId
Long groupId
 * 参数：是否好友 1-是 2-否 0-全部（默认）	Long isFriend
Long matchId
Long scheduleId
Long teamId
 * 参数：最近上线日期	Date onlineBeginDate
Date onlineEndDate
 * 参数：用户注册日期	Date beginDate
Date endDate
 * 参数：订阅主播	Long streamerId
String ua
String city
Long departmentId
Long roleId
Long id
 */
api.pageUsers = function (data) {
  return util.post('pageUsers', data);
};

api.pageBackendUsers = function (data) {
  return util.post('pageBackendUsers', data);
};

api.pageAppUsers = function (data) {
  return util.post('pageAppUsers', data);
};

/**
 * 接口：获取管理員列表
 * 参数列表:
 */

api.pageAdminUsers = function (data) {
  return util.post('pageAdminUsers', data);
};

api.pageUsersForMsg = function (data) {
  return util.post('pageUsersForMsg', data);
};

/**
 * 接口：获取角色列表
 * 参数列表:
 */
api.listRole = function (data) {
  return util.post('listRole', data);
};

/**
 * 接口：删除赛程
 * 参数列表:
Long id
 */
api.removeMatchSchedule = function (data) {
  return util.post('removeMatchSchedule', data);
};

/**
 * 接口：
 * 参数列表:
 */
api.infoUser = function (data) {
  return util.post('infoUser', data);
};

/**
 * 接口：
 * 参数列表:
String nicename
String avatar
String bio
 */
api.editUsers = function (data) {
  return util.post('editUsers', data);
};

/**
 * 接口：给好友发消息
 * 参数列表:
Long tid
必填	String content
Long contentType = Message.CONTENT_TYPE_TEXT
String thumb
String title
String url
String refName
String refContent
Long id
 */
api.editMsg = function (data) {
  return util.post('editMsg', data);
};

/**
必填	String account
必填	String password
int deviceType = CmfUsers.DEVICE_TYPE_H5
 */
api.login = function (data) {
  return util.post('login', data);
};

/**
 * 接口：轮播图列表
 * 参数列表:
Long type = 1l
 */
api.listSwiper = function (data) {
  return util.post('listSwiper', data);
};

/**
 * 接口：隐藏群组
 * 参数列表:
必填	Long id
 */
api.editGroupUserHideMessage = function (data) {
  return util.post('editGroupUserHideMessage', data);
};

/**
 * 接口：解散群组
 * 参数列表:
Long id
 */
api.removeGroup = function (data) {
  return util.post('removeGroup', data);
};

/**
 * 接口：
 * 参数列表:
static List<CommonTab> l
static Object mux = new Object()
 */
api.listCommonTag = function (data) {
  return util.post('listCommonTag', data);
};

/**
 * 接口：删除比赛订阅
 * 参数列表:
必填	Long id
 */
api.removeUserMatch = function (data) {
  return util.post('removeUserMatch', data);
};

/**
 * 接口：
 * 参数列表:
必填	String account
必填	String code
String country
int deviceType = CmfUsers.DEVICE_TYPE_H5
 */
api.editBind = function (data) {
  return util.post('editBind', data);
};

/**
 * 接口：将用户移除群组
 * 参数列表:
Long[] id
 */
api.removeGroupUsers = function (data) {
  return util.post('removeGroupUsers', data);
};

/**
 * 接口：投票
 * 参数列表:
Long id
Long status
String title
String optionA
String optionB
Long answer
Long roomId
Long timeout
 */
api.editVote = function (data) {
  return util.post('editVote', data);
};

/**
必填	Long uid
 */
api.pageUpSche = function (data) {
  return util.post('pageUpSche', data);
};

/**
 * 接口：
 * 参数列表:
Long matchId
String device
String country
 */
api.pageMatchCountry = function (data) {
  return util.post('pageMatchCountry', data);
};

/**
 * 接口：获取管理员列表
 * 参数列表:
 */
api.listAdmin = function (data) {
  return util.post('listAdmin', data);
};

/**
 * 接口：查询群组用户列表
 * 参数列表:
必填	Long groupId
String name
 */
api.pageGroupUser = function (data) {
  return util.post('pageGroupUser', data);
};

/**
 * 接口：投票
 * 参数列表:
Long voteId
Long answer
 */
api.editVoteUser = function (data) {
  return util.post('editVoteUser', data);
};

/**
 * 接口：查询用户的群组列表
 * 参数列表:
Long groupId
String name
Date beginDate
Date endDate
 */
api.listGroup = function (data) {
  return util.post('listGroup', data);
};

/**
 * 接口：查询群组用户列表
 * 参数列表:
必填	Long groupId
String name
 */
api.listGroupUser = function (data) {
  return util.post('listGroupUser', data);
};

/**
Long ts
 */
api.dataTeam = function (data) {
  return util.post('dataTeam', data);
};

/**
 * 接口: 主播统计推荐
 * 参数列表:
必填	Long uid
String beginDate
Long uid
Long blackCount
Long redCount
 */
api.countRecommend = function (data) {
  return util.post('countRecommend', data);
};

/**
 * 接口：获取客服组列表
 * 参数列表:
Long status = 1l
 */
api.listDepartment = function (data) {
  return util.post('listDepartment', data);
};

/**
 * 接口：获取客服组列表(Admin)
 * 参数列表:
 Long status = 1l
 */
api.listDepartmentA = function (data) {
  return util.post('listDepartmentA', data);
};

/**
 */
api.infoToken = function (data) {
  return util.post('infoToken', data);
};

/**
 * 接口：点赞
 * 参数列表:
必填	Long id
 */
api.editGoodCancel = function (data) {
  return util.post('editGoodCancel', data);
};

/**
 * 接口：点赞
 * 参数列表:
必填	Long refId
必填	Long type
必填	Long good
 */
api.editGood = function (data) {
  return util.post('editGood', data);
};

/**
 * 接口：IP白名单
 * 参数列表:
String ip
 */
api.editWhiteIp = function (data) {
  return util.post('editWhiteIp', data);
};

/**
 * 接口：推荐列表
 * 参数列表:
Long matchId
Long uid
String season
Long status
Long scheduleId
String title
String target
Long classId
Long history
Long id
Long hot
static final RecommendCache cache = new RecommendCache(60000)
static final RecommendUpCache upCache = new RecommendUpCache(60000)
static final RecommendScheCache scheCache = new RecommendScheCache(60000)
 */
api.pageRecommend = function (data) {
  return util.post('pageRecommendA', data);
};

/**
 * 接口：
 * 参数列表:
必填	String mobile
必填	String code
 */
api.editUsersMobile = function (data) {
  return util.post('editUsersMobile', data);
};

/**
 * 接口：删除用户等级
 * 参数列表:
必填	Long id
 */
api.removeGrade = function (data) {
  return util.post('removeGrade', data);
};

/**
 * 接口：获取当前主播的助理, 优先已加好友的
 * 参数列表:
Long upId
 */
api.listAssistOfUp = function (data) {
  return util.post('listAssistOfUp', data);
};

/**
 * 接口：修改权限
 * 参数列表:
必填	Long roleId
Long[] actionId
 */
api.editRoleAction = function (data) {
  return util.post('editRoleAction', data);
};

/**
 * 接口：设置赛程
 * 参数列表:
Long scheduleClass
Long matchId
Long id
Date beginTime
Long hot
String pull
Long recommend
Long teamIda
Long teamIdb
String title
Long status
Long scoreA
Long scoreB
String groupName
String levelName
String roundNum
String season
Long ignoreUpdate
 */
api.editMatchSchedule = function (data) {
  return util.post('editMatchSchedule', data);
};

/**
 * 接口：
 * 参数列表:
String content
Long senderId
 */
api.pageMessage = function (data) {
  return util.post('pageMessage', data);
};

/**
 * 接口：添加/修改射手榜
 * 参数列表:
Long id
Long matchId
Long scheduleId
Long teamId
String season
Long goalAssists
Long goalConceded
Long goalSaves
Long goalTotal
String name
String nameEn
Long playerId
Long shotOn
Long shotTotal
Long penalty
Long ownGoal
String teamName
String teamNameEn
 */
api.editPlayer = function (data) {
  return util.post('editPlayer', data);
};

/**
 * 接口：订阅比赛
 * 参数列表:
必填	Long scheId
 */
api.editUserMatch = function (data) {
  return util.post('editUserMatch', data);
};

/**
 * 接口：
 * 参数列表:
Long type
 */
api.pageAppVer = function (data) {
  return util.post('pageAppVer', data);
};

/**
必填	String pushid
 */
api.editUsersPushid = function (data) {
  return util.post('editUsersPushid', data);
};

/**
 * 接口：获取用户等级列表
 * 参数列表:
 */
api.listGrade = function (data) {
  return util.post('listGrade', data);
};

/**
 * 接口：获取用户等级列表(Admin)
 * 参数列表:
 */
api.listGradeA = function (data) {
  return util.post('listGradeA', data);
};

/**
 * 接口：
 * 参数列表:
Long roomId
 */
api.listRoomReport = function (data) {
  return util.post('listRoomReport', data);
};

/**
 * 接口：设置用户标签
 * 参数列表:
Long id
String tab
 */
api.editUserTab = function (data) {
  return util.post('editUserTab', data);
};

/**
 */
api.editUsersAttentionCancel = function (data) {
  return util.post('editUsersAttentionCancel', data);
};

/**
 * 接口: 主播推荐排名
 * 参数列表:
 */
api.countRecommendStanding = function (data) {
  return util.post('countRecommendStanding', data);
};

/**
 */
api.infoStatus = function (data) {
  return util.post('infoStatus', data);
};

/**
 * 接口：删除好友
 * 参数列表:
Long id
 */
api.removeFriend = function (data) {
  return util.post('removeFriend', data);
};

/**
 * 接口：删除敏感字
 * 参数列表:
Long id
 */
api.removeBlacklist = function (data) {
  return util.post('removeBlacklist', data);
};

/**
必填	Long matchId
 */
api.listMatchLineup = function (data) {
  return util.post('listMatchLineup', data);
};

/**
 * 接口：添加/修改文档
 * 参数列表:
Long id
String file
String pdf
Long refId = 0l
String title = "无标题"
Long type = 1l
 */
api.editAttach = function (data) {
  return util.post('editAttach', data);
};

/**
 * 接口：积分榜
 * 参数列表:
Long matchId
Long teamId
String season
 */
api.pageStanding = function (data) {
  return util.post('pageStandingA', data);
};

/**
 * 接口：在好友列表隐藏好友,收发信息会取消隐藏
 * 参数列表:
必填	Long id
 */
api.editFriendHide = function (data) {
  return util.post('editFriendHide', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removeSwiper = function (data) {
  return util.post('removeSwiper', data);
};

/**
 * 接口：查询直播间列表
 * 参数列表:
Long islive = 1l
 */
api.listRoom = function (data) {
  return util.post('listRoom', data);
};

/**
 * 接口：设置直播公告
 * 参数列表:
必填	Long id
String followA
String followB
Long followVisible
Long userVisible
Long guestVisible
String enterA
String enterB
Long enterVisible
String danmu
String danmuUrl
Long danmuInterval
String noticeContent
String noticeTitle
String noticeThumb
String noticeUrl
String adContent
Long adInterval
String adThumb
String adTitle
String adUrl
String welcome
 */
api.editUserLive = function (data) {
  return util.post('editUserLive', data);
};

/**
Long ts
 */
api.dataMatch = function (data) {
  return util.post('dataMatch', data);
};

/**
 * 接口：添加/修改新闻
 * 参数列表:
Long id
String content
Long groupId
String title = "无标题"
Long type = 1l
Date endTime
String keywords
Date startTime
String remark
Long status
String thumb
Long classId
Long matchId
Long scheduleId
Long teamId
String season
Long hot
 */
api.editArticle = function (data) {
  return util.post('editArticle', data);
};

/**
 * 接口：修改当前登陆账号的密码
 * 参数列表:
必填	String oldPass
必填	String newPass
 */
api.editUsersPass = function (data) {
  return util.post('editUsersPass', data);
};

/**
 * 接口：用户申请实名认证
 * 参数列表:
String remark
 * 参数：status：0默认 1通过 2拒绝	Long status
Long id
 */
api.editIdcardResult = function (data) {
  return util.post('editIdcardResult', data);
};

/**
 * 接口：修改pushid
 * 参数列表:
String pushid
 */
api.editPushid = function (data) {
  return util.post('editPushid', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long matchId
 */
api.listMatchCountry = function (data) {
  return util.post('listMatchCountry', data);
};

/**
 * 接口：获取特权列表
 * 参数列表:
 */
api.listPrivilege = function (data) {
  return util.post('listPrivilege', data);
};

/**
 * 接口：获取特权列表(Admin)
 * 参数列表:
 */
api.listPrivilegeA = function (data) {
  return util.post('listPrivilegeA', data);
};

/**
 * 接口：
 * 参数列表:
int deviceType = CmfUsers.DEVICE_TYPE_H5
static TimeLimit tl = new TimeLimit()
 */
api.editGuest = function (data) {
  return util.post('editGuest', data);
};

/**
 * 接口：
 * 参数列表:
Long id
String name
String thumb
 */
api.editMatchScheduleClass = function (data) {
  return util.post('editMatchScheduleClass', data);
};

/**
 * 接口：设置私有的用户备注
 * 参数列表:
Long id
Long uid
String remark
 */
api.editUserRemark = function (data) {
  return util.post('editUserRemark', data);
};

/**
 * 接口：
 * 参数列表:
String userNicename
String userLogin
Long sex
Long id
Long userType
String password
String avatar
String mobile
Long iszb = 0l
Long roleId
String bio
String tabs
String departmentId
String departmentName
String assistId
Long score
Long followRate
String remark
 */
api.editUsersA = function (data) {
  return util.post('editUsersA', data);
};
api.editUsersAA = function (data) {
  return util.post('editUsersAA', data);
};

/**
 * 接口：获取主播信息缓存
 * 参数列表:
 */
api.pageUpCache = function (data) {
  return util.post('pageUpCache', data);
};

/**
必填	Long teamId
Long teamIdb
 */
api.listLastMatch = function (data) {
  return util.post('listLastMatch', data);
};

/**
 * 接口：删除常用标签
 * 参数列表:
Long id
 */
api.removeCommonTab = function (data) {
  return util.post('removeCommonTab', data);
};

/**
 * 接口：删除用户私有标签
 * 参数列表:
Long id
 */
api.removeUserTabDep = function (data) {
  return util.post('removeUserTabDep', data);
};

/**
 * 接口：获取昵称列表
 * 参数列表:
String nice
String name
 */
api.pageNicename = function (data) {
  return util.post('pageNicename', data);
};

/**
必填	String account
必填	String code
String password
String source
String country
int deviceType = CmfUsers.DEVICE_TYPE_H5
 */
api.regist = function (data) {
  return util.post('regist', data);
};

/**
 * 接口：每日统计
 * 参数列表:
String beginDate
String endDate
 */
api.pageDailyStat = function (data) {
  return util.post('pageDailyStat', data);
};

/**
 * 接口：查询用户每日任务
 * 参数列表:
Long taskId
 */
api.listUserDailyTask = function (data) {
  return util.post('listUserDailyTask', data);
};

/**
必填	Long id
 * 参数：mute：1禁言 0可以发言	Long mute = 1l
 */
api.editMute = function (data) {
  return util.post('editMute', data);
};

/**
必填	Long matchId
必填	String country
必填	String devices
Long id
 */
api.editMatchCountry = function (data) {
  return util.post('editMatchCountry', data);
};

/**
 * 接口：删除特权
 * 参数列表:
必填	Long id
 */
api.removePrivilege = function (data) {
  return util.post('removePrivilege', data);
};

/**
 * 接口：举报直播间内容
 * 参数列表:
必填	Long roomId
必填	Long type
 */
api.editRoomReport = function (data) {
  return util.post('editRoomReport', data);
};

/**
 * 接口：新增/修改群组
 * 参数列表:
Long groupId
Long[] userId
String name
Long mute
 */
api.editGroup = function (data) {
  return util.post('editGroup', data);
};

/**
 * 接口：获取菜单
 * 参数列表:
 */
api.listMenu = function (data) {
  return util.post('listMenu', data);
};

/**
必填	Long roomId
 */
api.editRoomUsers = function (data) {
  return util.post('editRoomUsers', data);
};

/**
 * 接口：
 * 参数列表:
Long ishot
Long islive = 1l
Long uid
String name
Long scheduleId
Long matchId
Long classId
 */
api.listUsersLive = function (data) {
  return util.post('listUsersLive', data);
};

/**
Long ts
 */
api.dataGame = function (data) {
  return util.post('dataGame', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removeUsers = function (data) {
  return util.post('removeUsers', data);
};

/**
必填	Long matchId
必填	String city
必填	String devices
Long id
 */
api.editMatchCity = function (data) {
  return util.post('editMatchCity', data);
};

/**
 * 接口：获取直播列表
 * 参数列表:
Long ishot
Long islive
Long uid
String name
Long scheduleId
Date beginTime
Date endTime
String teamA
String teamB
Long thumbType
 */
api.pageUsersLive = function (data) {
  return util.post('pageUsersLive', data);
};

/**
 * 接口：更新推流地址
 * 参数列表:
必填	Long id
 */
api.editUsersLiveUrl = function (data) {
  return util.post('editUsersLiveUrl', data);
};

/**
 * 接口：添加/修改客服组
 * 参数列表:
必填	String name
Long parentId
Long status
Long id
 */
api.editDepartment = function (data) {
  return util.post('editDepartment', data);
};

/**
 * 接口：关注主播
 * 参数列表:
必填	Long streamerId
 */
api.editFollow = function (data) {
  return util.post('editFollow', data);
};

/**
 * 接口：
 * 参数列表:
 */
api.listBlacklist = function (data) {
  return util.post('listBlacklistA', data);
};

/**
 * 接口：
 * 参数列表:
Long type
String ver
String url
Long verIndex
String thumb
String remark
Long id
Long iosId
Long iosNew
Long force
String wgt
 */
api.editAppVer = function (data) {
  return util.post('editAppVer', data);
};

/**
 * 接口：申请直播
 * 参数列表:
Long scheduleId
String remark
String clear
String pull
String push
Long status
Long id
Long uid
Long departmentId
Date beginTime
Date endTime
Long hide = 0l
String viewKey
String title
 */
api.editLiveRequest = function (data) {
  return util.post('editLiveRequest', data);
};

/**
 * 接口：申请直播
 * 参数列表:
 Long status
 Long id
 */
api.editLiveRequestReview = function (data) {
  return util.post('editLiveRequestReview', data);
};

/**
 * 接口：推荐排名
 * 参数列表:
 */
api.pageRecommendStanding = function (data) {
  return util.post('pageRecommendStanding', data);
};

/**
 * 接口：新闻列表
 * 参数列表:
Long matchId
Long teamId
String season
Long status
Long scheduleId
Long hot
Long id
Long classId
String title
 */
api.pageArticle = function (data) {
  return util.post('pageArticleA', data);
};

/**
 * 接口：新闻列表
 * 参数列表:
Long matchId
Long teamId
String season
Long status
Long scheduleId
Long hot
Long id
Long classId
String title
 */
api.pageArticleA = function (data) {
  return util.post('pageArticleA', data);
};

/**
 * 接口：新闻列表
 * 参数列表:
Long id
Long status 说明：0下线 1发布
 */
api.editArticleStatus = function (data) {
  return util.post('editArticleStatus', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long roomId
Long toId
Long fromId
 */
api.pageRoomMsg = function (data) {
  return util.post('pageRoomMsg', data);
};

/**
 * 接口：射手榜
 * 参数列表:
Long matchId
Long teamId
String season
Long playerId
Long id
String name
String nameEn
String teamName
String teamNameEn
 */
api.pagePlayer = function (data) {
  return util.post('pagePlayer', data);
};

/**
 */
api.taskDaily2 = function (data) {
  return util.post('taskDaily2', data);
};

/**
 * 接口：
 * 参数列表:
Long uid
 */
api.listFollow = function (data) {
  return util.post('listFollow', data);
};

/**
 * 接口：获取权限
 * 参数列表:
Long roleId
 */
api.listRoleAction = function (data) {
  return util.post('listRoleAction', data);
};

/**
 * 接口：
 * 参数列表:
int deviceType = CmfUsers.DEVICE_TYPE_H5
 */
api.infoUsers = function (data) {
  return util.post('infoUsers', data);
};

/**
 * 接口：离开群组
 * 参数列表:
必填	Long groupUserId
 */
api.editGroupUsersLeave = function (data) {
  return util.post('editGroupUsersLeave', data);
};

/**
 * 接口：隐藏好友消息
 * 参数列表:
必填	Long id
 */
api.editFriendHideMessage = function (data) {
  return util.post('editFriendHideMessage', data);
};

/**
必填	Long roomId
必填	Long type = Message.TYPE_NORMAL
必填	Long messageType = Message.MESSAGE_TYPE_ROOM
必填	Long contentType = Message.CONTENT_TYPE_TEXT
String url
String thumb
String title
String refName
String refContent
static String blacklist = "1234567890壹贰叁肆伍陆柒捌玖拾零一二三四五六七八九十貳參肆陸佰仟①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳⒈⒉⒊⒋⒌⒍⒎⒏⒐⒑⒒⒓⒔⒕⒖⒗⒘⒙⒚⒛⑴⑵⑶⑷⑸⑹⑺⑻⑼⑽⑾⑿⒀⒁⒂⒃⒄⒅⒆⒇❶❷❸❹❺❻❼❽❾❿⓫⓬⓭⓮⓯⓰⓱⓲⓳⓴㈠㈡㈢㈣㈤㈥㈦㈧㈨㈩"
static HashSet<Character> blackMap = new HashSet<>()
必填	String content
 */
api.editRoomMessage = function (data) {
  return util.post('editRoomMessage', data);
};

/**
 * 接口：举报聊天内容
 * 参数列表:
必填	Long msgId
必填	Long type
 */
api.editReport = function (data) {
  return util.post('editReport', data);
};

/**
 * 接口：添加/修改反馈
 * 参数列表:
Long id
String content
Long status
 */
api.editFeedback = function (data) {
  return util.post('editFeedback', data);
};

/**
 */
api.logout = function (data) {
  return util.post('logout', data);
};

/**
 * 接口: 上传文档
 * 参数列表:
String file
[Boolean isAsset]
 */
api.upload = function (data) {
  return util.post('upload', data);
};

/**
 * 接口：获取赛事列表
 * 参数列表:
String name
Long id
Long classId
Long hot
Date date
Long ts
 */
api.listMatch = function (data) {
  return util.post('listMatch', data);
};

/**
 * 接口：获取直播申请列表
 * 参数列表:
Long scheduleId
Long uid
Long status
Date fromBeginDate
Date toBeginDate
Long matchId
Long classId
String teamNamea
String teamNameb
 */
api.pageLiveRequest = function (data) {
  return util.post('pageLiveRequest', data);
};

/**
 * 接口：
 * 参数列表:
必填	Long groupId
Long fromId
Long toId
 */
api.pageGroupMsg = function (data) {
  return util.post('pageGroupMsg', data);
};

/**
 * 接口：删除私聊消息
 * 参数列表:
必填	Long id
 */
api.removeMsg = function (data) {
  return util.post('removeMsg', data);
};

/**
String name
Long id
 */
api.editRole = function (data) {
  return util.post('editRole', data);
};

/**
 * 接口：获取元数据列表
 * 参数列表:
String label
 */
api.listMetaData = function (data) {
  return util.post('listMetaData', data);
};

/**
 * 接口：获取模块列表
 * 参数列表:
 */
api.listAction = function (data) {
  return util.post('listAction', data);
};

/**
 * 接口：删除赛事
 * 参数列表:
Long id
 */
api.removeMatch = function (data) {
  return util.post('removeMatch', data);
};

/**
 * 接口：获取用户所属客服组列表
 * 参数列表:
 */
api.listUserDepartment = function (data) {
  return util.post('listUserDepartment', data);
};

/**
 * 接口：删除推荐
 * 参数列表:
Long id
 */
api.removeRecommend = function (data) {
  return util.post('removeRecommend', data);
};

/**
 * 接口：添加/修改权限
 * 参数列表:
String url
String name
String icon
Long parentId
Long priority
 * 参数：1菜单 2权限 3按钮	Long type
Long id
 */
api.editAction = function (data) {
  return util.post('editAction', data);
};

/**
 * 接口：
 * 参数列表:
Long groupId
Long fromId
 */
api.listGroupMsg = function (data) {
  return util.post('listGroupMsg', data);
};

/**
 */
api.editUsersAttention = function (data) {
  return util.post('editUsersAttention', data);
};

/**
 * 接口: CountViewMatch
 * 参数列表:
Long id
List<Map> view
List<Map> viewByMatch
List<Map> viewByTeam
CmfUsers info
 */
api.countViewMatch = function (data) {
  return util.post('countViewMatch', data);
};

/**
 * 接口：下播
 * 参数列表:
 * 参数：此id是live的id	必填	Long id
 */
api.editUsersLiveClose = function (data) {
  return util.post('editUsersLiveClose', data);
};

/**
 * 接口：删除赛程分类
 * 参数列表:
Long id
 */
api.removeMatchScheduleClass = function (data) {
  return util.post('removeMatchScheduleClass', data);
};
/*  直播间禁言
    listRoomUserBlock
    参数roomId
 */
api.listRoomUserBlock = function (data) {
  return util.post('listRoomUserBlock', data);
};

/*  用户备注列表
    listUserRemark
 */
api.listUserRemark = function (data) {
  return util.post('listUserRemark', data);
};
/**
 * 接口：获取用户列表
 * 参数列表:
String search
String userLogin
String mobile
String userNicename
Long userType
String tab
Long roomId
Long groupId
 * 参数：是否好友 1-是 2-否 0-全部（默认）	Long isFriend
Long matchId
Long scheduleId
Long teamId
 * 参数：最近上线日期	Date onlineBeginDate
Date onlineEndDate
 * 参数：用户注册日期	Date beginDate
Date endDate
 * 参数：订阅主播	Long streamerId
String ua
String city
Long departmentId
Long roleId
Long id
 */
api.pageUsersA = function (data) {
  return util.post('pageUsersA', data);
};

/*  屏蔽用户列表
    listBlockUser
 */
api.listBlockUser = function (data) {
  return util.post('listBlockUser', data);
};

/*  屏蔽用户
    editBlockUser
    flag 标识符
 */
api.editBlockUser = function (data) {
  return util.post('editBlockUser', data);
};

/*  删除屏蔽用户
    removeBlockUser
    flag 标识符
 */
api.removeBlockUser = function (data) {
  return util.post('removeBlockUser', data);
};

/*  屏蔽ip列表
    listBlockIp
 */
api.listBlockIp = function (data) {
  return util.post('listBlockIp', data);
};

/*  屏蔽ip
    editBlockIp
    ip ip地址
 */
api.editBlockIp = function (data) {
  return util.post('editBlockIp', data);
};

/*  删除屏蔽ip
    removeBlockIp
    ip ip地址
 */
api.removeBlockIp = function (data) {
  return util.post('removeBlockIp', data);
};
/**
 * 接口：获取最近一周已申请未开播的主播赛程
 * 参数列表:
 * 参数：none
 */
api.listRequestSchedule = function (data) {
  return util.post('listRequestSchedule', data);
};

/**
 * 接口：修改直播P2SP播放器比例
 * 参数列表:
 * id: 直播id
 * abRate: P2SP播放比例
 */
api.editUsersLiveAb = function (data) {
  return util.post('editUsersLiveAb', data);
};

/**
 * 接口： 版本列表
 * 参数列表:
Long type
 */
api.pageAppVerA = function (data) {
  return util.post('pageAppVerA', data);
};

/**
 * 接口： 编辑快捷咨询
 * 参数列表:
 * 咨询问题 String question;
    咨询ID Long id;
    排序优先级 Long priority;
    状态 Long status;
    回复 String answer;
    自动回复 autoType 0不自动 1自动
    回复类型 Long answerType; 1文本 2图片
 */
api.editFaq = function (data) {
  return util.post('editFaq', data);
};

/**
 * 接口： 删除快捷咨询
 * 参数列表:
 * 快捷咨询ID Long id;
Long type
 */
api.removeFaq = function (data) {
  return util.post('removeFaq', data);
};
/**
 * 接口： 用户获取快捷咨询
 * 参数列表:
Long type
 */
api.listFaq = function (data) {
  return util.post('listFaq', data);
};

/**
 * 接口： 后台管理获取快捷咨询
 * 参数列表:
 * 快捷咨询ID Long id;
    public  Long status;
    public  Long answerType;
    public  Long autoType;
    //问题
    public String question;
*/
api.pageFaqA = function (data) {
  return util.post('pageFaqA', data);
};

/**
 * 接口： 编辑轮播图
 * 参数列表:
 *
 *  Long id;
    关联ID Long articleId  当type:2/6 新闻id type:3 直播间id type:4 比赛id
    轮播图类型 Long type 1图片链接 2新闻(取消) 3直播 4比赛 5纯图片 6专题

     链接地址  String url;
    优先级 Long priority;
    结束展示时间  Date endTime;
    开始展示时间  Date startTime;
    状态: 1启用 0删除  Long status;
    轮播图图片地址  String thumb;
    轮播图图片地址PC String thumbPc;
    展示设备  Long[] device;
    关联赛事 Long matchId;
Long type
 */
api.editSwiper = function (data) {
  return util.post('editSwiper', data);
};

/*
    添加/修改推荐主播 editHotUp
    Long priority;  优先级
    Long upId;主播id
    Long id;  主键
    Long status;   是否启用  0否 1是
    Date beginTime; 开始推荐时间
    Date endTime; 结束推荐时间
 */
api.editHotUp = function (data) {
  return util.post('editHotUp', data);
};

/*
 用户查询推荐主播
 ListHotUp
 */
api.listHotUp = function (data) {
  return util.post('listHotUp', data);
};

/*
 管理查询推荐主播
 ListHotUp
 */
api.listHotUps = function (data) {
  return util.post('listHotUps', data);
};

/*
 删除推荐主播
 RemoveHotUp
 id   主键
 */
api.removeHotUp = function (data) {
  return util.post('removeHotUp', data);
};

/*
 添加/修改推荐直播
 EditHotLive

 Long priority;  优先级
 Long  requestId;直播id
 Long id;  主键
 Long status;   是否启用  0否 1是
 Date beginTime; 开始推荐时间
 Date endTime; 结束推荐时间
 */
api.editHotLive = function (data) {
  return util.post('editHotLive', data);
};

/*
    用户查询推荐直播
    ListHotLive
 */
api.listHotLive = function (data) {
  return util.post('listHotLive', data);
};

/*
 管理查询推荐直播
 ListHotUp
 String matchName;
 String teamA;
 String teamB;
 */
api.listHotLives = function (data) {
  return util.post('listHotLives', data);
};
/*
 删除推荐直播
 RemoveHotLive
  id   主键
 */
api.removeHotLive = function (data) {
  return util.post('removeHotLive', data);
};

/*
 查询主播列表
 pageUpA
  id   主键
  Long status;
  Long isLive;
  Long departmentId;
 */
api.pageUpA = function (data) {
  return util.post('pageUpA', data);
};

/*
 修改/添加个人封面列表
 editUpInfo
  id   主键
  uid
  status
  cover
 */
api.editUpInfo = function (data) {
  return util.post('editUpInfo', data);
};

/*
 查询主播个人封面列表
 pageUpInfo
  id   主键
 */
api.pageUpInfo = function (data) {
  return util.post('pageUpInfoA', data);
};

/*
 查询未开始的直播封面管理
 listRequestScheduleA
  id   主键
 */
api.listRequestScheduleA = function (data) {
  return util.post('listRequestScheduleA', data);
};

/*
 修改/添加未上播直播封面管理
 editLiveThumb
  Long id;
  String thumb;
  Long thumbType;
 */
api.editLiveThumb = function (data) {
  return util.post('editLiveThumb', data);
};

/*
 修改/添加首页比赛推荐
 editHotMatch
  Long id;
  //联赛
    scheduleId
  //状态   0未推荐  1已推荐   2取消推荐
   Long status;
  Date beginTime;
 */

api.editHotMatch = function (data) {
  return util.post('editHotMatch', data);
};

/*
 查询首页比赛推荐
 pageHotMatch
  id   主键
  String teamA;
  String teamB;
  Long status;
 */
api.pageHotMatch = function (data) {
  return util.post('pageHotMatch', data);
};

/*
 查询全部首页比赛推荐
 listHotMatch
  id   主键
  String teamA;
  String teamB;
  Long status;
 */
api.listHotMatch = function (data) {
  return util.post('listHotMatch', data);
};

/*
 生成自动运动封面
 editThumb
    Long requestId;
 */
api.editThumb = function (data) {
  return util.post('editThumb', data);
};

/*
 域名渠道号列表
 listDomain
 String domain 域名
 String channel 渠道号
 */
api.listDomain = function (data) {
  return util.post('listDomain', data);
};

/*
 删除域名渠道号
 editThumb
 Long id;
 */
api.removeDomain = function (data) {
  return util.post('removeDomain', data);
};

/*
 删除域名渠道号修改
 editThumb
 String domain; 域名
 String channel; 渠道号
 Long status; 状态
 Long id;
 String remark; 备注
 */
api.editDomain = function (data) {
  return util.post('editDomain', data);
};

/*
 注销账户
 editUsersLogout
 Long  id
 */
api.editUsersLogout = function (data) {
  return util.post('editUsersLogout', data);
};

/*
 获取当前主播所在的所有平台
 listBrandOfUp
 Long  uid
 */
api.listBrandOfUp = function (data) {
  return util.post('listBrandOfUp', data);
};

/*
 获取所有平台
 listBrand
 */
api.listBrand = function (data) {
  return util.post('listBrand', data);
};

/*
 查询用户
 pageUserA
 String userNicename; 用户昵称
 String userLogin; 登录账号
 */
api.pageUserA = function (data) {
  return util.post('pageUserA', data);
};

/*
查询用户
pageUserA
String userNicename; 用户昵称
String userLogin; 登录账号
*/
api.pageUserAdmin = function (data) {
  return util.post('pageUserAdmin', data);
};

/*
 操作记录
 pageUserOperator
 String userNicename; 用户昵称
 String userLogin; 登录账号
 String operator; 操作账号
 Long type; 操作类型

 */
api.pageUserOperator = function (data) {
  return util.post('pageUserOperator', data);
};

/*
 修改用户信息
 editUsersInfo
 String userNicename; 用户昵称
 String password; 用户密码
 String tab; 用户标签
 Long id; 用户id

 */
api.editUsersInfo = function (data) {
  return util.post('editUsersInfo', data);
};

/*
 子平台列表
 pageBrandA
 String brand; 平台标识
 String name; 平台名称
 Long status; 启用状态 1、启用 2、停用
 */
api.pageBrandA = function (data) {
  return util.post('pageBrandA', data);
};

/*
添加、编辑子平台
 editBrandA
 String brand; 平台标识
 String name; 平台名称
 String thumb; 平台logo
 String thumbPc; 横版logo
 String remark; 备注
 Long status; 启用状态 1、启用 2、停用

 */
api.editBrandA = function (data) {
  return util.post('editBrandA', data);
};

/*
 删除子平台
 removeBrandA
 Long id;

 */
api.removeBrandA = function (data) {
  return util.post('removeBrandA', data);
};

/*
 子平台渠道列表
 listBrandChannel
 */
api.listBrandChannel = function (data) {
  return util.post('listBrandChannel', data);
};

/*
 添加、编辑子平台渠道
 editBrandChannelA
 Long id;
 String brand; 平台
 String brandB; 新平台
 String channel; 渠道
 String second; 切换时间
 */
api.editBrandChannelA = function (data) {
  return util.post('editBrandChannelA', data);
};

/*
 删除子平台渠道
 removeBrandChannelA
 Long id;
 */
api.removeBrandChannelA = function (data) {
  return util.post('removeBrandChannelA', data);
};

/*
 轮播图所属子平台
 listBrandOfSwiper
 Long id
 */
api.listBrandOfSwiper = function (data) {
  return util.post('listBrandOfSwiper', data);
};

/*
 渠道包列表
 pageApkChannel
 String channel; 渠道号
 String name; 应用名
 Long status 已处理 1、是 2、否
 */
api.pageApkChannel = function (data) {
  return util.post('pageApkChannel', data);
};

/*
 主包列表
 pageApkMain
 String channel; 渠道号
 String name; 应用名
 Long status 已处理 1、是 2、否
 */
api.pageApkMain = function (data) {
  return util.post('pageApkMain', data);
};

/*
 添加、编辑主包
 pageApkMain
 String name; 应用名
 String version; 版本
 String channel; 渠道号
 String packageName; 包名
 String openInstall; openInstall
 String versionName; 应用版本名称
 String versionCode; 应用版本号
 String logo; 图标
 String remark; 备注
 String oss; OSS链接
 String cdn; CDN链接
 String file; apk
 Long status 已处理 1、是 2、否
 */
api.editApkMain = function (data) {
  return util.post('editApkMain', data);
};

/*
 删除主包
 removeApkMain
 Long id
 */
api.removeApkMain = function (data) {
  return util.post('removeApkMain', data);
};

/*
 添加、编辑渠道包
 pageApkMain
 Long mainId 主包id
 String name; 应用名
 String channel; 渠道号
 String packageName; 包名
 String logoId 关于我们图标序号
 String openInstall; openInstall
 String appId; AppID
 String versionName; 应用版本名称
 String versionCode; 应用版本号
 String logo; 图标
 String remark; 备注
 String oss; OSS链接
 String cdn; CDN链接
 Long status 已处理 1、是 2、否
 */
api.editApkChannel = function (data) {
  return util.post('editApkChannel', data);
};

/*
 删除渠道包
 removeApkChannel
 Long id
 */
api.removeApkChannel = function (data) {
  return util.post('removeApkChannel', data);
};

/*
 渠道号管理列表
 listChannel
 */
api.listChannel = function (data) {
  return util.post('listChannel', data);
};

/*
 编辑渠道号
 editChannel
  String domain; 分享地址
  String channel; 渠道号
  Long id; id
  String logo; 图标
  String name; 应用名
  Long platform; 展示终端
  String shareUrl; 下载页背景底图
  Long status; 状态 1、启用 2、禁用
  String remark; 备注
 */
api.editChannel = function (data) {
  return util.post('editChannel', data);
};

/*
 删除渠道号
 removeChannel
 Long id
 */
api.removeChannel = function (data) {
  return util.post('removeChannel', data);
};

/*
 查询外链点击日志
 pageClickLog
 Date beginTime 点击时间
 Date endTime 结束时间
 String ci 渠道号
 String uid 用户id
 String ip ip
 Long device 终端

 */
api.pageClickLog = function (data) {
  return util.post('pageClickLog', data);
};

/*
 查询外链点击日志
 listClickLog
 Date beginTime 点击时间
 Date endTime 结束时间
 String ci 渠道号
 String uid 用户id
 String ip ip
 Long device 终端

 */
api.listClickLog = function (data) {
  return util.post('listClickLog', data);
};

/*
 查询渠道日志
 pageChannelLog
 Date beginTime 注册开始时间
 Date endTime 注册结束时间
 Date mgBeginTime MG注册开始时间
 Date mgEndTime MG注册结束时间
 Date addBeginTime 记录开始时间
 Date addEndTime 记录结束时间
 String ci 渠道号
 Long device 终端
 Long mgId mgId

 */
api.pageChannelLog = function (data) {
  return util.post('pageChannelLog', data);
};

/*
 查询渠道日志
 listChannelLog
 Date beginTime 注册开始时间
 Date endTime 注册结束时间
 Date mgBeginTime MG注册开始时间
 Date mgEndTime MG注册结束时间
 Date addBeginTime 记录开始时间
 Date addEndTime 记录结束时间
 String ci 渠道号
 Long device 终端
 Long mgId mgId

 */
api.listChannelLog = function (data) {
  return util.post('listChannelLog', data);
};

/*
 查询渠道日志
 pageLiveList
 Long liveId 直播间ID
 String userName 主播
 Long status 直播状态
 Long scheduleId 比赛ID
 Date beginTime 比赛时间
 Date endTime 比赛结束时间
 String matchName 联赛
 String teamNameA 主队
 String teamNameB 客队
 */
api.pageLiveList = function (data) {
  return util.post('pageLiveList', data);
};

/*
 查询渠道日志
 pageLiveListDay
 Date beginTime 日期
 */
api.pageLiveListDay = function (data) {
  return util.post('pageLiveListDay', data);
};

/*
 查询每日直播数据在线人数趋势图
 listLiveAmountDay
 Date updateTime 时间
 */
api.listLiveAmountDay = function (data) {
  return util.post('listLiveAmountDay', data);
};

/*
 查询每日直播数据用户在线时长
 listLiveDurationDay
 Date updateTime 时间
 */
api.listLiveDurationDay = function (data) {
  return util.post('listLiveDurationDay', data);
};

/*
 查询每日直播数据用户来源
 listLiveSourceDay
 Date updateTime 时间
 */
api.listLiveSourceDay = function (data) {
  return util.post('listLiveSourceDay', data);
};

/*
 查询直播间数据在线人数趋势图
 listLiveAmount
 Long liveId 直播间id
 */
api.listLiveAmount = function (data) {
  return util.post('listLiveAmount', data);
};

/*
 查询直播间数据用户在线时长
 listLiveDuration
  Long liveId 直播间id
 */
api.listLiveDuration = function (data) {
  return util.post('listLiveDuration', data);
};

/*
 查询直播间数据用户来源
 listLiveSource
 Long liveId 直播间id
 */
api.listLiveSource = function (data) {
  return util.post('listLiveSource', data);
};

/*
 查询模板列表
 pageUpAd
 String name 模板名字
 Long status 1、已启用 2、已停用
 */
api.pageUpAd = function (data) {
  return util.post('pageUpAd', data);
};

/*
 添加、编辑模板
 editUpAd
 */
api.editUpAd = function (data) {
  return util.post('editUpAd', data);
};

/*
 删除模板
 removeUpAd
 Long id
 */
api.removeUpAd = function (data) {
  return util.post('removeUpAd', data);
};

/*
查询app logo：
接口：pageAppLogo
参数：
	public Long id;
	public String name;
	public String logo;
	public String remark;
	public Long status;
 */

api.pageAppLogo = function (data) {
  return util.post('pageAppLogo', data);
};

/*
增改app logo：editAppLogo
参数：
	public Long id;
	public String name;
	public String logo;
	public String remark;
	public Long status;
5:57
status 0:无效 1:有效
 */
api.editAppLogo = function (data) {
  return util.post('editAppLogo', data);
};

/*
删除 app logo：
removeAppLogo
参数 id
 */
api.removeAppLogo = function (data) {
  return util.post('removeAppLogo', data);
};

/*
获取google信息
infoCodeGoogle
 */
api.infoCodeGoogle = function (data) {
  return util.post('infoCodeGoogle', data);
};

/*
绑定google信息
editCodeGoogle
String key
String code
 */
api.editCodeGoogle = function (data) {
  return util.post('editCodeGoogle', data);
};

/*
解绑google账号
editCodeGoogleCloseA
Long id
 */
api.editCodeGoogleCloseA = function (data) {
  return util.post('editCodeGoogleCloseA', data);
};

/**
 * 接口：
 * 参数列表:
Long id
 */
api.removeToutiao = function (data) {
  return util.post('removeToutiao', data);
};
/**
 * 接口：添加/修改头条
 * 参数列表:
Long id
String content
Long groupId
String title = "无标题"
Long type = 1l
Date endTime
String keywords
Date startTime
String remark
Long status
String thumb
Long classId
Long matchId
Long scheduleId
Long teamId
String season
Long hot
 */
api.editToutiao = function (data) {
  return util.post('editToutiao', data);
};
/**
 * 接口：头条列表
 * 参数列表:
Long matchId
Long teamId
String season
Long status
Long scheduleId
Long hot
Long id
Long classId
String title
 */
api.pageToutiao = function (data) {
  return util.post('pageToutiaoA', data);
};
/**
 * 接口：头条列表
 * 参数列表:
Long id
Long status 说明：0下线 1发布
 */
api.editToutiaoStatus = function (data) {
  return util.post('editToutiaoStatus', data);
};

/**
 * 接口：每日签到
 *
 */
api.editActivityLog = function (data) {
  return util.post('editActivityLog', data);
};

/**
 * 接口：新增/修改奖品
 *
 */
// Long id
// Long status = 1l
// String name
// Float rate 中奖比例
// Long drawAmount 抽奖数量
// Long amount 释放数量
// Date beginTime
// Date endTime
// Long stock 总库存
// Long freeAmount 当前剩余数量
// Long currentStock 当前库存
// Long usedAmount 已中奖数量(无法修改)
// String logo 图标
api.editAward = function (data) {
  return util.post('editAward', data);
};

/**
 * 接口：完成一次性任务
 *
 */
// Long taskId
api.editUserOnceTask = function (data) {
  return util.post('editUserOnceTask', data);
};

/**
 * 接口：查询获奖情况（管理员）
 *
 */
// Long uid
// Long awardId
// Date beginTime
// Date endTime
// Long status
api.pageAwardResultA = function (data) {
  return util.post('pageAwardResultA', data);
};

// Long priority
// Long requestId
// Long id
// Long status
// Date beginTime
// Date endTime
api.editHotLive = function (data) {
  return util.post('editHotLive', data);
};

/**
 * 接口：查询奖品
 *
 */
// Long id
// String name
api.pageAward = function (data) {
  return util.post('pageAward', data);
};

/**
 * 接口：查询最近获奖
 *
 */
api.pageAwardResultList = function (data) {
  return util.post('pageAwardResultList', data);
};

/**
 * 接口：领取抽奖次数
 *
 */
// Long id
api.editUserActivity = function (data) {
  return util.post('editUserActivity', data);
};
/**
 * 接口：查询用户完成任务情况
 **	public static long TYPE_SIGN = 1; 签到
 *	public static long TYPE_SHARE = 2; 分享APP
 *	public static long TYPE_WATCH = 3; 看比赛
 *	public static long TYPE_ROOM_MSG = 4; 广场发言
 *	public static long TYPE_USER_MATCH = 5; 订阅比赛
 *	public static long TYPE_REGIST = 6; 注册
 *	public static long TYPE_FRIEND = 7; 加助理
 *	public static long TYPE_FOLLOW = 8; 关注主播
 *	public static long TYPE_MSG = 9; 助理私信
 */
api.pageUserActivity = function (data) {
  return util.post('pageUserActivity', data);
};
/**
 * 接口：查询获奖情况
 *
 */
// Long status
api.pageAwardResult = function (data) {
  return util.post('pageAwardResult', data);
};

/**
 * 接口：查询奖品（管理员）
 *
 */
// String name
// Long status
api.pageAwardA = function (data) {
  return util.post('pageAwardA', data);
};

/**
 * 接口：完成每日任务
 *
 */
// Long taskId
api.editUserDailyTask = function (data) {
  return util.post('editUserDailyTask', data);
};

/**
 * 接口：删除奖品
 *
 */
// Long id
api.removeAward = function (data) {
  return util.post('removeAward', data);
};

/**
 * 接口：抽奖
 *
 */
api.editUserAward = function (data) {
  return util.post('editUserAward', data);
};

/**
 * 接口：获取抽奖次数
 *
 */
api.infoUserStat = function (data) {
  return util.post('infoUserStat', data);
};

/**
 * 完成发放奖品和备注
参数：id 必填
status 0:未中奖 1:未发货 2:已发货
remark 备注
*/
api.editAwardResult = function (data) {
  return util.post('editAwardResult', data);
};

export default api;
