// 这里保持true
let prod = false;
let map = {
  'hh.798zb.com': {
    title: '五楼直播',
    api: 'mixiangchina.com',
    prod: true,
    id: 1,
    brand: '5'
  },
  'admin.jsxdjy.com': {
    title: '黑白直播',
    api: 'jsmmr.com',
    prod: true,
    id: 2,
    brand: 'h'
  },
  'ml.jsxdjy.com': {
    title: '黑白直播',
    api: 'jsmmr.com',
    prod: true,
    id: 2,
    brand: 'h'
  },
  'pp.7982zb.com': {
    title: '五楼直播',
    api: 'rhjqwzb5l21.com',
    prod: true,
    id: 3,
    brand: '5'
  },
  'admin.landmark7890.com': {
    title: '五楼直播',
    api: 'landmark7890.com',
    prod: false,
    id: 1,
    brand: '5'
  },
  'admin.landmarkhb.com': {
    title: '黑白直播',
    api: 'landmarkhb.com',
    prod: false,
    id: 2,
    brand: 'h'
  },
  // UAT
  'admin.hb.landingmakljdh.xyz': {
    title: '黑白直播 UAT',
    api: 'hb.landingmakljdh.xyz',
    prod: false,
    id: 10,
    brand: 'h',
    down: 'hb.landingmakljdh.xyz'
  },
  'admin.landmark7892.com': {
    title: '五楼直播',
    api: 'landmark7892.com',
    prod: false,
    id: 3,
    brand: '5'
  },
  'admin.mayazb8.com': {
    title: '玛雅直播',
    api: 'mayazb8.com',
    prod: false,
    id: 4,
    brand: 'my'
  },
  'admin.bsvwkxjt5l28.cc': {
    title: '提审',
    api: 'bsvwkxjt5l28.cc',
    prod: false,
    id: 5,
    brand: 'ts'
  },
  'admin131.z88.tv': {
    title: 'z88',
    api: 'z88.tv',
    prod: false,
    id: 6,
    brand: 'z88'
  },
  'osengnls.24kzb.com': {
    title: '提审',
    api: '24kzb.com',
    prod: false,
    id: 7,
    brand: 'ts',
    down: 'ryqclmzb31.cc'
  },
  'nb.56721yx.xyz': {
    title: '天天直播',
    api: 'bujzeyzb32.cc',
    prod: false,
    id: 8,
    brand: 'tt',
    down: 'bujzeyzb32.cc'
  },
  // UAT
  'admin.tt.landingmakljdh.xyz': {
    title: '天天直播 UAT',
    api: 'tt.landingmakljdh.xyz',
    prod: false,
    id: 10,
    brand: 'tt',
    down: 'tt.landingmakljdh.xyz'
  },
  '99gv.9zhibo2.live': {
    title: 'xiao9直播',
    api: 'apg2o570ez44.xyz',
    prod: false,
    id: 9,
    brand: 'tt',
    down: 'apg2o570ez44.xyz'
  },
  // UAT
  'admin.haliccesshb.com': {
    title: '小9直播 UAT',
    api: 'haliccesshb.com',
    prod: false,
    id: 10,
    brand: 'x9',
    down: 'haliccesshb.com'
  },
  'hh.apzy24ii0457.xyz': {
    title: '蜜瓜看球',
    api: 'apzy24ii0457.xyz',
    prod: true,
    id: 10,
    brand: '5',
    down: 'mixiangchina.com'
  },
  'admin.bbg.landingmakljdh.xyz': {
    title: 'A3直播',
    api: 'bbg.landingmakljdh.xyz',
    prod: false,
    id: 10,
    brand: 'bbg',
    down: 'bbg.landingmakljdh.xyz'
  },
  //BBG prod, 海外轉化
  'ecc.673667aaa.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod, 中國主播
  'hub.apskjzoypw82.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.apt94xr20o100.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.apwyd1eu6b99.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.apy9s18zmu98.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.ap3cgrlldy97.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.apc0z9pegu96.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },
  //BBG prod MAL-868
  'hub.apvr1wjiwi95.xyz': {
    title: 'A3直播',
    api: 'apggddbntr81.xyz',
    prod: true,
    id: 10,
    brand: 'bbg',
    down: 'apggddbntr81.xyz'
  },  
  // UAT 站
  'admin.bl.landingmakljdh.xyz': {
    title: '播龙体育',
    api: 'bl.landingmakljdh.xyz',
    prod: false,
    id: 10,
    brand: 'bl',
    down: 'bl.landingmakljdh.xyz'
  },
  // 正式站
  'deq.apjtdxaohi55.xyz': {
    title: '播龙体育',
    api: 'mixiangchina.com',
    prod: true,
    id: 1,
    brand: 'bl',
    down: 'fantasy-town.xyz'
  },
  // 正式站
  'ax.55223zb.xyz': {
    title: '播龙体育',
    api: 'mixiangchina.com',
    prod: true,
    id: 1,
    brand: 'bl',
    down: 'fantasy-town.xyz'
  },
  // 黑白直播 UAT
  'admin.hbuat.jsxdjy.com': {
    title: '黑白直播 UAT',
    api: 'hbuat.jsmmr.com',
    prod: true,
    id: 2,
    brand: 'h',
    down: 'hbuat.heibaitiyu.cc'
  },
  // 黑白直播 UAT
  'm.hbuat.heibaitiyu.cc': {
    title: '黑白直播 UAT',
    api: 'hbuat.jsmmr.com',
    prod: true,
    id: 2,
    brand: 'h',
    down: 'hbuat.heibaitiyu.cc'
  },
  // 黑白直播 UAT
  'api.hbuat.heibaitiyu.cc': {
    title: '黑白直播 UAT',
    api: 'hbuat.jsmmr.com',
    prod: true,
    id: 2,
    brand: 'h',
    down: 'hbuat.heibaitiyu.cc'
  },
  // localhost
  localhost: {
    title: '西瓜看球',
    api: 'bl.landingmakljdh.xyz',
    prod: false,
    id: 10,
    brand: 'bl',
    down: 'bl.landingmakljdh.xyz'
  }
};
let routerMode = 'hash';

const config = {};

let version = '2.9.2';
let verIndex = '2090020';

let channel = 'manage';
let timeout = 30000;

let h = window.location.host;
if (h == 'ddd.jsxdjy.com') {
  h = 'admin.jsxdjy.com';
}
if (h.startsWith('localhost')) {
  h = 'localhost';
}

if (process.env.NODE_ENV == 'development') {
  // h='admin.landmarkhb.com'

  //播龙 UAT
  h = 'admin.bl.landingmakljdh.xyz';

  //播龙 Production
  //h='deq.apjtdxaohi55.xyz'

  //播龙 Production
  //h='ax.55223zb.xyz'

  //黑白 Production
  //h='admin.jsxdjy.com'

  //黑白 UAT
  //h='admin.hb.landingmakljdh.xyz'

  //天天 UAT
  //h='admin.tt.landingmakljdh.xyz'

  //小九 UAT
  //h='admin.haliccesshb.com'
}

console.log('location.host', h);

let m = map[h];

if (!m) {
  m = map['hh.798zb.com'];
}

config.id = m.id;
const host = m.api;
let brand = m.brand;

let prefix = m.prod ? 'x7eidtry' : 'api';
if (m.id == 2 && m.prod) {
  prefix = 'apiv2';
}
if (m.id == 6) {
  prefix = 'api131';
}
if (m.id == 7) {
  prefix = 'osengnlsapi';
}
if (m.id == 9) {
  prefix = 'x7x7eidtry';
}
if (m.id == 10) {
  prefix = 'api';
}
let baseUrl = `https://${prefix}.${host}/api`;
let websocketUrl = `wss://im.${host}/ws/`;
let uploadUrl = `https://api.${host}/api?method=upload`;
let downloadUrl = `https://file.${m.down || host}/`;
if (m.id == 7) {
  uploadUrl = `https://${prefix}.${host}/api?method=upload`;
}
console.log(`baseUrl: ${baseUrl}`);

// 黑白图床 正式站 MAL-175
if (!m.down && brand == 'h') {
  downloadUrl = `https://hbfile.fantasy-town.xyz/`;
}

//  会员添加好友 主播私聊列表是否显示添加好友信息
let iszbFriend = brand == 'h' ? false : true;
if (process.env.NODE_ENV == 'development') {
  timeout = 120000;
  // baseUrl = `http://192.168.31.253:60400/api`
  // baseUrl = `http://192.168.31.172:60400/api`
  // baseUrl = `http://192.168.31.150:60400/api`
  // baseUrl = `http://192.168.31.151:60400/api`
  // baseUrl = `http://192.168.232.75:60400/api`
  // baseUrl = 'http://192.168.31.215:60400/api'
  // uploadUrl = `http://api.192.168.31.151:60400/api?method=upload`
}

export {
  baseUrl,
  routerMode,
  websocketUrl,
  uploadUrl,
  downloadUrl,
  version,
  brand,
  verIndex,
  channel,
  timeout,
  iszbFriend
};
