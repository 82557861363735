import api from '@/api/api';
import util from '@/config/util';
import Vue from 'vue';
import vars from '@/config/vars';
import { iszbFriend } from '@/config/env';
const state = {};
const actions = {};
const mutations = {};

const map = {
  room: {},
  group: {},
  friend: {},
  assist: {}
};
const room = [];
const friend = [];
const group = [];
const assist = [];
const allAssist = [];
const current = {};
const anchorForm = {
  size: 10,
  page: 1
};
const friendForm = {
  size: 10,
  page: 1
};
const data = { room, friend, group, assist, current, map };

state.message = data;
state.anchorForm = anchorForm;
state.friendForm = friendForm;
state.anchorTotal = 0;
state.friendTotal = 0;
state.allFriendUnread = 0;
state.allAssistUnread = 0;
state.finishData = false;
state.talking = {};

mutations.bangUserfriend = function (state, data) {
  let r = state.message.map.friend[data.senderId];
  r.name = data.senderName;
  r.joinMap = {
    u: {
      avatar: data.avatar,
      userNicename: data.senderName
    }
  };
  r.refId = data.refId;
  r.uid = data.refId;
  r.senderId = data.refId;
  r.friendId = data.refId;
  r.lastMsg = data.content;
  r.userType = 2;
  Vue.set(state.message.map.friend, r.senderId, r);
  Vue.delete(state.message.map.friend, data.senderId);
  Vue.delete(state.userMap, data.senderId);
};

mutations.bangUserAssist = function (state, data) {
  for (let i in state.message.assist) {
    let r = state.message.assist[i];
    if (r.senderId == data.senderId) {
      let key = r.receiverId + '-' + r.senderId;
      // r.friendId = data.refId
      // r.senderId = data.refId
      // r.name = data.senderName
      let ma = state.message.map.assist[key];
      if (ma && ma.id) {
        ma.friendId = data.refId;
        ma.senderId = data.refId;
        ma.name = data.senderName + '#已绑定';
        ma.joinMap.u.userNicename = data.refContent;
        let akey = r.receiverId + '-' + data.refId;
        Vue.set(state.message.map.assist, akey, ma);
        Vue.delete(state.message.map.assist, key);
      }
      // state.message.assist.splice(i,1)
      // Vue.delete(state.message.map.assist,key)
    }
  }

  // let r = state.message.map.assist[data.senderId]
  //    r.name = data.senderName
  //    r.joinMap = {
  //        u:{
  //            avatar:data.avatar,
  //            userNicename:data.senderName,
  //        }
  //    }
  //    r.refId = data.refId
  //    r.uid = data.refId
  //    r.senderId = data.refId
  //    r.friendId = data.refId
  //    r.lastMsg = data.content
  // r.userType = 2
  //    Vue.set(state.message.map.friend,r.senderId,r)
  //    Vue.delete(state.message.map.friend,data.senderId)
  //    Vue.delete(state.userMap,data.senderId)
};
mutations.messageNewTarget = function (state, data) {
  let r = data;
  r.updateTime =
    r.createTime || util.dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
  r.lastMsg = r.content;
  r.unread = 1;
  r.messageList = [];
  let userNicename = r.senderName;
  if (!userNicename && r.messageType == 3) {
    userNicename = '游客#' + r.senderId;
    r.userType = 3;
  }
  r.joinMap = {
    u: {
      avatar: r.senderAvator,
      userNicename: userNicename,
      cancel: 0
    },
    o: {
      avatar: r.receiverAvator,
      userNicename: r.receiverName
    },
    g: {
      name: r.joinMap && r.joinMap.g && r.joinMap.g.name,
      amount: r.joinMap && r.joinMap.g && r.joinMap.g.amount
    }
  };
  if (data.messageType == 2) {
    r.unread = 0;
    r.list = [];
    let map = state.message.map.group[r.groupId];
    state.message.map.group[r.groupId] = r;
    Vue.set(state.message.map.group, r.groupId, r);
    if (!map) {
      state.message.group.unshift(r);
      state.groupTotal++;
    }
    return;
  }

  if (data.messageType == 3 && !data.assistId) {
    r.list = [];
    if (r.refContent) {
      r.remark = r.refContent;
    }
    r.id = r.refId;
    r.friendId = r.senderId;
    r.name =
      (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) ||
      (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename);

    let map = state.message.map.friend[r.senderId];
    // Vue.set(state.message.map.friend,r.senderId,r)
    state.message.map.friend[r.senderId] = r;
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename) {
      r.joinMap.u.userNicename = '游客#' + r.senderId;
      r.name = '游客#' + r.senderId;
      r.userType = 3;
    }
    if (!map) {
      state.message.friend.unshift(r);
      state.friendTotal++;
    }
    return;
  }

  if (data.messageType == 3 && data.assistId) {
    if (!iszbFriend && r.type == vars.TYPE_ENTER) {
      return false;
    }
    // if(data.type == 1){
    //     return
    // }
    r.list = [];
    r.id = r.refId;
    r.friendId = r.senderId;
    r.name =
      (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) ||
      (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename);
    let key = `${r.receiverId}-${r.senderId}`;
    if (!r.receiverName) {
      r.joinMap.o.userNicename = r.refContent;
    }
    r.remark = '小助手';
    // let key = `${r.senderId}-${r.receiverId}`
    let map = state.message.map.assist[key];
    state.message.map.assist[key] = r;
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename) {
      r.joinMap.u.userNicename = '游客#' + r.senderId;
      r.name = '游客#' + r.senderId;
    }
    r.joinMap.u.cancel = 0;
    if (!map) {
      state.message.assist.unshift(r);
      state.anchorTotal++;
    }
    return;
  }
};

mutations.messageDelGroup = function (state, data) {
  let r = data;
  if (r.type == 2) {
    state.message.group.splice(r.index, 1);
    state.groupTotal--;
  }
};

mutations.messageUpdateGroup = function (state, data) {
  let r = data;
  let map = state.message.map.group;
  let m = map[r.groupId || r.id];
  m.joinMap.g.name = r.name;
  m.mute = r.mute;
  m.joinMap.g.mute = r.mute;
  Vue.set(state.message.map, 'group', map);
};

mutations.updateGroupAmount = function (state, data) {
  let r = data;
  let map = state.message.map.group;
  let m = map[r.groupId];
  m.joinMap.g.amount--;
  if (m.joinMap.g.amount < 0) {
    m.joinMap.g.amount = 0;
  }
  Vue.set(state.message.map, 'group', map);
};

mutations.messageListRoom = function (state, data) {
  state.message.map.room = {};
  data.forEach((r) => {
    r.list = [];
    r.messageList = [];
    r.unread = 0;
    state.message.map.room[r.id] = r;
  });
  Vue.set(state.message, 'room', Object.values(state.message.map.room));
};

mutations.messageListGroup = function (state, data) {
  let map = {};
  data.forEach((r) => {
    r.list = [];
    r.messageList = [];
    let g = r.joinMap.g;
    if (!g.name) {
      r.name = r.groupName;
      g.name = r.groupName;
    }
    r.messageType = 2;
    map[r.groupId] = r;
  });
  Vue.set(state.message, 'group', data);
  Vue.set(state.message.map, 'group', map);
  state.groupTotal = data.total;
};

mutations.messageListFriend = function (state, data) {
  let list = data.list ? data.list : [];
  let map = {};
  list.forEach((r) => {
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.cancel) {
      r.joinMap.u.cancel = 0;
    }
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename) {
      r.joinMap.u.userNicename = '游客#' + r.friendId;
    }
    r.name =
      (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) ||
      (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename);
    r.list = [];
    r.messageList = [];
    r.messageType = 3;
    let m = map[r.friendId];
    if (m && m.id) {
      return;
    }
    map[r.friendId] = r;
  });

  // Vue.set(state.message,'friend',list)
  state.message.friend = Object.values(map);
  Vue.set(state.message.map, 'friend', map);
  state.allFriendUnread = data.unread;
  state.friendTotal = data.total;
};

mutations.messageListAssist = function (state, data) {
  if (!data) {
    return;
  }
  let list = data.list ? data.list : [];
  let map = {};
  let unread = 0;
  list.forEach((r) => {
    r.list = [];
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.cancel) {
      r.joinMap.u.cancel = 0;
    }
    if (r.joinMap && r.joinMap.u && !r.joinMap.u.userNicename) {
      r.joinMap.u.userNicename = '游客#' + r.friendId;
    }
    let key = `${r.uid}-${r.friendId}`;
    r.name =
      (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) ||
      (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename);
    r.list = [];
    r.messageList = [];
    r.messageType = 4;
    map[key] = r;
    if (r.userHide == 0 && r.unread > 0) {
      unread += r.unread;
    }
  });
  Vue.set(state.message.map, 'assist', map);
  state.message.assist = Object.values(map);
  // Vue.set(state.message,'assist',)
  state.allAssistUnread = unread;
  state.anchorTotal = data.total;
};

mutations.messageUpdateFriend = function (state, data) {
  let r = data;
  let map = state.message.map.friend;
  let m = map[r.friendId];
  m.name = r.name;
  m.joinMap.ur.remark = r.joinMap.ur.remark;
  Vue.set(state.message.map, 'friend', map);
};

mutations.messageDelFriend = function (state, data) {
  let r = data;
  let map = state.message.map.friend[r.friendId];
  let l = state.message.friend;

  if (map) {
    for (let i in l) {
      let f = l[i];
      if (f.friendId == r.friendId) {
        l.splice(i, 1);
        state.friendTotal--;
        return;
      }
    }
  }
};

mutations.messageCurrent = function (state, data) {
  state.message.current = data;
  if (data && data.id) {
    if (data.messageType == 3) {
      state.allFriendUnread =
        state.allFriendUnread - data.unread > 0
          ? state.allFriendUnread - data.unread
          : 0;
    }
    if (data.messageType == 4) {
      state.allAssistUnread =
        state.allAssistUnread - data.unread > 0
          ? state.allAssistUnread - data.unread
          : 0;
    }
  }
  data.unread = 0;
};

mutations.messageNew = function (state, data) {
  data.target.lastMsg = data.r.content;
  data.target.lastTime = data.r.createTime;
  data.target.updateTime = data.r.createTime;
  let tmap = data.t;

  if (tmap && tmap.id) {
    let t = tmap.talking;
    if (!t || t.showscrollinfo) {
      data.target.unread++;
    }
  } else {
    if (data.r && !data.r.refContent) {
      data.target.unread++;
    }
  }
};

mutations.updateGroupMessage = function (state, data) {
  let r = data;
  let map = state.message.map.group;
  let m = map[r.groupId];
  if (m) {
    m.updateTime = r.addTime;
    m.lastMsg = r.content;
    Vue.set(state.message.map, 'group', map);
  }
};

mutations.updateFriendMessage = function (state, data) {
  let r = data;
  let map = state.message.map.friend;
  let m = map[r.receiverId || r.tid];
  if (m) {
    m.updateTime = r.addTime;
    m.lastMsg = r.content;
    Vue.set(state.message.map, 'friend', map);
  }
};

mutations.messageNewUnread = function (state, data) {
  if (data.assistId && data.messageType == 3) {
    if (!iszbFriend && data.type == vars.TYPE_ENTER) {
      return false;
    }
    state.allAssistUnread++;
  }
  if (!data.assistId && data.messageType == 3) {
    state.allFriendUnread++;
  }
};

actions.messageInit = function (context) {
  context.dispatch('messageListRoom');
  context.dispatch('messageListGroup');
  context.dispatch('messageListFriend', friendForm);
  context.dispatch('messageListAssist', anchorForm);
};

actions.messageListRoom = function (context) {
  api
    .listRoom()
    .then((resp) => {
      context.state.finishData = false;
      resp.data.map((r) => {
        let msg = util.json(r.lastMsg);
        if (msg && msg.title) {
          r.lastMsg = msg.title;
        }
        if (msg && msg.message) {
          r.lastMsg = msg.message;
        }
      });
      context.commit('messageListRoom', resp.data);
    })
    .catch((err) => {
      context.state.finishData = false;
    });
};

actions.messageListGroup = function (context) {
  api
    .listGroup()
    .then((resp) => {
      context.state.finishData = false;
      resp.data.map((r) => {
        let g = r.joinMap && r.joinMap.g;
        r.name = g.name || r.groupName;
        let msg = util.json(r.lastMsg);
        if (msg && msg.title) {
          r.lastMsg = '' + msg.title;
        }
        if (msg && msg.message) {
          r.lastMsg = msg.message;
        }
      });
      context.commit('messageListGroup', resp.data);
    })
    .catch((err) => {
      context.state.finishData = false;
    });
};

actions.messageListFriend = function (context, data) {
  api
    .pageFriend(data)
    .then((resp) => {
      context.state.finishData = false;
      context.commit('messageListFriend', resp.data);
    })
    .catch((err) => {
      context.state.finishData = false;
    });
};

actions.messageListAssist = function (context, data) {
  api
    .pageAssistFriend(data)
    .then((resp) => {
      context.state.finishData = false;
      context.commit('messageListAssist', resp.data);
    })
    .catch((err) => {
      context.state.finishData = false;
    });
};
actions.bangUser = function (context, data) {
  let r = state.message.map.friend[data.senderId];
  if (!r || !r.id) {
    // context.dispatch('messageNew',data)
    return;
  }
  context.commit('bangUserfriend', data);
};
actions.messageNew = function (context, r) {
  if (r.index == 1) {
    return;
  }
  let message = context.state.message;

  let map = undefined;
  let target = undefined;
  let t = undefined;
  if (r.messageType == 1) {
    map = message.map.room;
    target = map[r.roomId || r.senderId];
    t = context.state.drawMap[r.roomId || r.senderId];
  }
  if (r.messageType == 2) {
    map = message.map.group;
    target = map[r.roomId || r.senderId];
    t = context.state.drawMap[r.roomId || r.senderId];
  }
  if (r.messageType == 3 && !r.assistId) {
    map = message.map.friend;
    target = map[r.roomId || r.senderId];
    t = context.state.drawMap[r.roomId || r.senderId];
  }

  if (r.messageType == 3 && r.assistId) {
    map = message.map.assist;
    let key = `${r.receiverId}-${r.senderId}`;
    let k = `4-${r.senderId}`;
    t = context.state.drawMap[k];
    target = map[key];
  }
  if (t && t.id) {
    let tal = t.talking;
    if (!tal || tal.showscrollinfo) {
      context.commit('messageNewUnread', r);
    }
  } else {
    context.commit('messageNewUnread', r);
  }
  if (!target) {
    context.commit('messageNewTarget', r);
    return;
  }
  context.commit('messageNew', { r, target, t });
};

function message(store) {
  Object.assign(store.state, state);
  Object.assign(store.actions, actions);
  Object.assign(store.mutations, mutations);
}

export default message;
