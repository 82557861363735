import util from './util.js';
import api from './api.js';
import vars from './vars.js';

const sport = {};

sport.subFriendUnread = function (list, friendId) {
  // let key = `3-${this.userId}-${friendId}`
  // let id = []
  // list.forEach(r => {
  // 	if (r.status == 0) {
  // 		id.push(r.id)
  // 	}
  // })
  // if (id.length > 0) {
  // 	sport.editMsgRead(key, id, friendId)
  // }
};
sport.editMsgRead = function (key, id, friendId) {
  api
    .editMsgRead({
      id,
      friendId
    })
    .then((resp) => {
      let unread = resp.data * 1;
      util.emit('unread', key, unread);
    })
    .catch((res) => {
      util.message(res.message);
    });
};

sport.subGroupUnread = function (list, groupId) {
  // let key = `2-${this.userId}-${groupId}`
  // let id = []
  // list.forEach(r => {
  // 	if (r.joinMap && r.joinMap.unread && r.joinMap.unread.id) {
  // 		id.push(r.id)
  // 	}
  // })
  // if (id.length > 0) {
  // 	sport.editGroupMsgRead(key, id, groupId)
  // }
};

sport.editGroupMsgRead = function (key, id, groupId) {
  api
    .editGroupMsgRead({
      id,
      groupId
    })
    .then((resp) => {
      let unread = resp.data * 1;
      util.emit('unread', key, unread);
    })
    .catch((res) => {
      util.message(res.message);
    });
};
sport.getMsgList = function () {
  let msgMap = this.msgMap;

  for (let i in msgMap) {
    delete msgMap[i];
  }

  if (!this.user || this.user.userType == 3) {
    return;
  }

  api.listGroup().then((resp) => {
    resp.data.forEach((r) => {
      let key = `2-${r.uid}-${r.groupId}`;

      r.list = [];
      r.targetId = r.groupId;
      r.type = 2;
      r.name = r.joinMap.g.name || 'ID' + r.id;

      r.lastTime = r.updateTime;

      msgMap[key] = r;
    });

    api.listFriend().then((resp) => {
      let talking = undefined;
      resp.data.forEach((r) => {
        let key = `3-${r.uid}-${r.friendId}`;

        r.list = [];
        r.targetId = r.friendId;
        r.type = 3;
        r.name = r.joinMap.u.userNicename || 'ID' + r.id;
        r.avatar = r.joinMap.u.avatar;
        r.lastTime = r.updateTime;

        msgMap[key] = r;

        if (this.option.id == r.id && this.option.type == 3) {
          talking = r;
        }
      });

      if (talking) {
        this.talkTo(talking);
      }

      this.listAll = Object.values(msgMap);

      this.checkUnread();
    });
  });
};
sport.delMsg = function (messageList, r) {
  for (let k in messageList) {
    if (messageList[k].id == r.id) {
      messageList.splice(k, 1);
      break;
    }
  }
};
sport.getName = function (r) {
  let name = '游客#' + r.senderId;
  if (!r.senderId) {
    name = '游客#' + r.id;
  }

  return (
    (r.joinMap && r.joinMap.ur && r.joinMap.ur.remark) ||
    (r.joinMap && r.joinMap.u && r.joinMap.u.userNicename) ||
    name
  );
};

sport.emotionlist2 = vars.emoji;

sport.jsonContent = (content, n) => {
  let msg = [];
  if (/^\[.+\]$/.test(content)) {
    // console.log("bbb",content)
    let emo = content.substr(1, content.length - 2);
    // console.log("aaa",emo)
    let hi = sport.emotionlist2.indexOf(emo);
    // console.log("ccc",hi)
    if (hi >= 0) {
      if (n) {
        n.hi = true;
      }
      let imageUrl = `emoji/${hi + 1}.jpg`;
      // console.log("dddd",imageUrl)
      msg.push({ content: imageUrl, type: 'image' });
    }
    return msg;
  }

  let reg =
    /(http)s?:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/gim;
  let start = 0;
  let end = content.length;
  for (;;) {
    let s = reg.exec(content);
    end = s && s.length ? s.index : content.length;

    let t = content.substring(start, end);
    if (t) {
      msg.push({ content: t, type: 'text' });
    }
    start = s && s.length ? end + s[0].length : content.length;
    if (s && s.length) {
      msg.push({ content: s[0], type: 'http' });
    } else {
      return msg;
    }
  }
};

export default sport;
