const vars = {};
vars.award = [
  { id: 0, name: '未中奖' },
  { id: 1, name: '未发放' },
  { id: 2, name: '已发放' }
];
vars.hot = [
  { id: 1, name: '是' },
  { id: 0, name: '否' }
];
vars.class = [
  { id: '4', name: '篮球' },
  { id: '5', name: '足球' },
  { id: '6', name: '电竞' }
];
vars.class = [
  { id: '4', name: '篮球' },
  { id: '5', name: '足球' },
  { id: '6', name: '电竞' }
];
vars.swiperType = [
  { id: '1', name: '外链' },
  { id: '2', name: '新闻' },
  { id: '3', name: '直播间' }
];
vars.device = [
  { id: '1', name: 'PC' },
  { id: '2', name: 'M' },
  { id: '3', name: 'APP' }
];
vars.equipment = [
  { id: '1', name: 'iOS' },
  { id: '2', name: 'Android' },
  { id: '3', name: 'PC' },
  { id: '4', name: 'M' }
];
vars.ua = [
  { id: 'app', name: 'APP' },
  { id: 'h5', name: 'H5' },
  { id: 'pc', name: 'WEB' }
];
vars.scheduleStatus = [
  { id: 0, name: '未开始' },
  { id: 1, name: '进行中' },
  { id: 2, name: '已结束' },
  { id: 3, name: '已取消' },
  { id: 4, name: '待定' },
  { id: 5, name: '中断' },
  { id: 6, name: '已推迟' },
  { id: 7, name: '腰斩' }
];
vars.result = [
  { id: 3, name: '走水' },
  { id: 2, name: '黑单' },
  { id: 1, name: '红单' },
  { id: 0, name: '未判定' }
];
vars.status = [
  { id: 1, name: '是' },
  { id: 0, name: '否' }
];
vars.articleStatus = [
  { id: 1, name: '已发布' },
  { id: 0, name: '未发布' }
];
vars.enableStatus = [
  { id: 1, name: '已启用' },
  { id: 2, name: '已停用' }
];
vars.onlineStatus = [
  { id: 0, name: '待上线' },
  { id: 1, name: '已上线' },
  { id: 2, name: '已下线' }
];
vars.bannerType = [
  { id: 1, name: '图片链接' },
  // {id:3, name:'直播'},
  { id: 4, name: '比赛' },
  { id: 5, name: '纯图片' },
  { id: 6, name: '专题' }
];
vars.articleType = [
  { id: 1, name: '文本' },
  { id: 2, name: '视频' },
  { id: 3, name: 'gif' }
];

vars.subType = [
  { id: 0, name: '普通头条' },
  { id: 1, name: '直播' },
  { id: 2, name: '动态' },
  { id: 102, name: '隐藏的动态' }
];
vars.ignore = [
  { id: 1, name: '忽略更新' },
  { id: 0, name: '更新' }
];
vars.enable = [
  { id: 1, name: '启用' },
  { id: 2, name: '停用' }
];
vars.brandProviderEnable = [
  { id: 1, name: '启用' },
  { id: 0, name: '停用' }
];
vars.liveStatus = [
  { id: 1, name: '是' },
  { id: 2, name: '否' }
];
vars.reviewStatus = [
  { id: 0, name: '待审核' },
  { id: 1, name: '已通过' },
  { id: 2, name: '已拒绝' },
  { id: 3, name: '已开播' }
];
vars.setupTypes = [
  { id: 1, name: '安装' },
  { id: 2, name: '卸载' },
  { id: 3, name: '升级' }
];
vars.work = [
  { id: '组长', name: '组长' },
  { id: '副组长', name: '副组长' },
  { id: '成员', name: '成员' }
];

vars.isDiscount = [
  { id: 0, name: '普通' },
  { id: 1, name: '优惠' },
  { id: 2, name: '免费' }
];

vars.weeks = [
  { id: 1, name: '周一' },
  { id: 2, name: '周二' },
  { id: 3, name: '周三' },
  { id: 4, name: '周四' },
  { id: 5, name: '周五' },
  { id: 6, name: '周六' },
  { id: 7, name: '周日' }
];
vars.contentType = [
  { id: 1, name: '普通文本消息' },
  { id: 2, name: '带链接图片的广告' }
];
vars.plugFlow = [
  { id: 0, name: '全部' },
  { id: 1, name: '清流' }
];
vars.answerTypes = [
  { id: 1, name: '纯文字' },
  { id: 2, name: '图片' },
  { id: 3, name: '消息链接' }
];
vars.answermap = ['纯文字', '图片', '消息链接'];

vars.fapstatus = [
  { id: 0, name: '已停用' },
  { id: 1, name: '已启用' }
];
vars.fapstatusmap = ['已停用', '已启用'];

vars.autoType = [
  { id: 0, name: '不自动回复' },
  { id: 1, name: '自动回复' }
];
vars.automap = ['不自动回复', '自动回复'];

vars.covertype = [
  { id: 1, name: '自定义封面' },
  { id: 2, name: '主播个人封面' },
  { id: 3, name: '比赛截图封面' },
  { id: 4, name: '自动运动封面' }
];

vars.operator = [
  { id: 1, name: '查询' },
  { id: 2, name: '编辑' },
  { id: 3, name: '冻结' },
  { id: 4, name: '解冻' }
];
vars.covertypemap = [
  '自定义封面',
  '主播个人封面',
  '比赛截图封面',
  '自动运动封面'
];
vars.configtype = [
  { id: 0, name: '未配置' },
  { id: 1, name: '已配置' }
];

vars.reacstatus = [
  { id: 0, name: '待生效' },
  { id: 1, name: '已生效' }
];
(vars.livestatus = [
  { id: 2, name: '未直播' },
  { id: 1, name: '直播中' }
]),
  (vars.domainStatus = [
    { id: 0, name: '无效' },
    { id: 1, name: '有效' }
  ]);

vars.broadcastStatus = [
  { id: 0, name: '待审核' },
  { id: 1, name: '未直播' },
  { id: 2, name: '审核不通过' },
  { id: 3, name: '直播中' },
  { id: 4, name: '已下播' }
];

vars.terminalTyep = [
  { id: 0, name: '全部' },
  { id: 1, name: 'PC端' },
  { id: 2, name: 'M端' },
  { id: 3, name: 'APP' }
];

vars.liveServerType = [
  { id: 'ali', name: '阿里云' },
  { id: 'tx', name: '腾讯' }
];

vars.batchMsgTypeList = [
  {
    id: 1,
    name: '普通消息',
    contentType: 1
  },
  {
    id: 2,
    name: '广告',
    contentType: 2
  },
  {
    id: 3,
    name: '图片',
    contentType: 3
  }
];

vars.sendStatus = [
  { id: 0, name: '全部' },
  { id: 1, name: '审核中', className: 'verifying' },
  { id: 2, name: '审核不通过', className: 'deny' },
  { id: 3, name: '等待发送中', className: 'waiting' },
  { id: 4, name: '发送中', className: 'sending' },
  { id: 5, name: '发送完成', className: 'done' }
];

vars.matchStatus = [
  '未开始',
  '已开始',
  '已结束',
  '已取消',
  '待定',
  '中断',
  '已推迟',
  '腰斩'
];
//进入直播间/群组
vars.TYPE_ENTER = 1;
//离开直播间/群组
vars.TYPE_LEAVE = -1;
//游客进入直播间/群组
vars.TYPE_ENTER_GUEST = 2;
//游客离开直播间/群组
vars.TYPE_LEAVE_GUEST = -2;
//订阅
vars.TYPE_FOLLOW = 3;
//取消订阅
vars.TYPE_FOLLOW_CANCEL = -3;
//文本消息
vars.TYPE_NORMAL = 4;
//公告
vars.TYPE_NOTICE = 5;
//系统公告
vars.TYPE_SYS_NOTICE = 6;
//弹幕
vars.TYPE_DANMU = 7;
//直播/直播间配置更新
vars.TYPE_LIVE_UPDATE = 8;
//投票
vars.TYPE_VOTE = 9;
//投票结果
vars.TYPE_VOTE_RESULT = -9;
//用户投票
vars.TYPE_VOTE_USER = 10;
vars.TYPE_REMOVE_MESSAGE = 11;

// 添加好友
vars.TYPE_ADD_FRIEND = 12;
//领取礼物
vars.TYPE_GIFT = 13;
//绑定账号
vars.TYPE_BANG = 14;
//已读信息
vars.TYPE_READ = 15;

vars.messageType = [
  { id: 1, name: '直播房间' },
  { id: 2, name: '群组消息' },
  { id: 3, name: '私信' }
];
vars.customerTypesText = (type) => {
  for (let i in vars.customerTypes) {
    let r = vars.customerTypes[i];
    if (r && r.id == type) {
      return r.name;
    }
  }
};

vars.softwareTypesText = (type) => {
  for (let i in vars.softwareTypes) {
    let r = vars.softwareTypes[i];
    if (r && r.id == type) {
      return r.name;
    }
  }
};

vars.setupTypesText = (type) => {
  for (let i in vars.setupTypes) {
    let r = vars.setupTypes[i];
    if (r && r.id == type) {
      return r.name;
    }
  }
};
vars.emojis = [
  {
    emoj: '😃',
    name: '哈哈'
  },
  {
    emoj: '😄',
    name: '大笑'
  },
  {
    emoj: '😁',
    name: '嘻嘻'
  },
  {
    emoj: '😆',
    name: '斜眼笑'
  },
  {
    emoj: '😅',
    name: '苦笑'
  },
  {
    emoj: '🤣',
    name: '笑得满地打滚'
  },
  {
    emoj: '😂',
    name: '笑哭了'
  },
  {
    emoj: '🙃',
    name: '倒脸'
  },
  {
    emoj: '😉',
    name: '眨眼'
  },
  {
    emoj: '😊',
    name: '羞涩微笑'
  },
  {
    emoj: '😇',
    name: '微笑天使'
  },
  {
    emoj: '🥰',
    name: '喜笑颜开'
  },
  {
    emoj: '😍',
    name: '花痴'
  },
  {
    emoj: '🤩',
    name: '好崇拜哦'
  },
  {
    emoj: '😘',
    name: '飞吻'
  },
  {
    emoj: '😗',
    name: '亲亲'
  },
  {
    emoj: '😚',
    name: '羞涩亲亲'
  },
  {
    emoj: '😙',
    name: '微笑亲亲'
  },
  {
    emoj: '😋',
    name: '好吃'
  },
  {
    emoj: '😛',
    name: '吐舌'
  },
  {
    emoj: '😜',
    name: '单眼吐舌'
  },
  {
    emoj: '🤪',
    name: '滑稽'
  },
  {
    emoj: '😝',
    name: '眯眼吐舌'
  },
  {
    emoj: '🤑',
    name: '发财'
  },
  {
    emoj: '🤗',
    name: '抱抱'
  },
  {
    emoj: '🤭',
    name: '不说'
  },
  {
    emoj: '🤫',
    name: '安静的脸'
  },
  {
    emoj: '🤔',
    name: '想一想'
  },
  {
    emoj: '🤐',
    name: '闭嘴'
  },
  {
    emoj: '🤨',
    name: '挑眉'
  },
  {
    emoj: '😐',
    name: '冷漠'
  },
  {
    emoj: '😑',
    name: '无语'
  },
  {
    emoj: '😶',
    name: '沉默'
  },
  {
    emoj: '😏',
    name: '得意'
  },
  {
    emoj: '😒',
    name: '不高兴'
  },
  {
    emoj: '🙄',
    name: '翻白眼'
  },
  {
    emoj: '😬',
    name: '龇牙咧嘴'
  },
  {
    emoj: '🤥',
    name: '沉默'
  },
  {
    emoj: '😌',
    name: '松了口气'
  },
  {
    emoj: '😔',
    name: '沉思'
  },
  {
    emoj: '😪',
    name: '困'
  },
  {
    emoj: '🤤',
    name: '流口水'
  },
  {
    emoj: '😴',
    name: '睡着了'
  },
  {
    emoj: '😷',
    name: '感冒'
  },
  {
    emoj: '🤒',
    name: '发烧'
  },
  {
    emoj: '🤕',
    name: '受伤'
  },
  {
    emoj: '🤢',
    name: '恶心'
  },
  {
    emoj: '🤮',
    name: '呕吐'
  },
  {
    emoj: '🤧',
    name: '打喷嚏'
  },
  {
    emoj: '🥵',
    name: '脸发烧'
  },
  {
    emoj: '🥶',
    name: '冷脸'
  },
  {
    emoj: '🥴',
    name: '头昏眼花'
  },
  {
    emoj: '😵',
    name: '晕头转向'
  },
  {
    emoj: '🤯',
    name: '爆炸头'
  },
  {
    emoj: '🤠',
    name: '牛仔帽脸'
  },
  {
    emoj: '🥳',
    name: '聚会笑脸'
  },
  {
    emoj: '😎',
    name: '墨镜笑脸'
  },
  {
    emoj: '🤓',
    name: '书呆子脸'
  },
  {
    emoj: '🧐',
    name: '带单片眼镜的脸'
  },
  {
    emoj: '😕',
    name: '困扰'
  },
  {
    emoj: '😟',
    name: '担心'
  },
  {
    emoj: '🙁',
    name: '微微不满'
  },
  {
    emoj: '☹️',
    name: '不满'
  },
  {
    emoj: '😮',
    name: '吃惊'
  },
  {
    emoj: '😯',
    name: '缄默'
  },
  {
    emoj: '😲',
    name: '震惊'
  },
  {
    emoj: '😳',
    name: '脸红'
  },
  {
    emoj: '🥺',
    name: '恳求的脸'
  },
  {
    emoj: '😦',
    name: '啊'
  },
  {
    emoj: '😧',
    name: '极度痛苦'
  },
  {
    emoj: '😨',
    name: '害怕'
  },
  {
    emoj: '😰',
    name: '冷汗'
  },
  {
    emoj: '😥',
    name: '失望但如释重负'
  },
  {
    emoj: '😢',
    name: '哭'
  },
  {
    emoj: '😭',
    name: '放声大哭'
  },
  {
    emoj: '😱',
    name: '吓死了'
  },
  {
    emoj: '😣',
    name: '痛苦'
  },
  {
    emoj: '😞',
    name: '失望'
  },
  {
    emoj: '😓',
    name: '汗'
  },
  {
    emoj: '😩',
    name: '累死了'
  },
  {
    emoj: '😫',
    name: '累'
  },
  {
    emoj: '🥱',
    name: '打呵欠'
  },
  {
    emoj: '😤',
    name: '傲慢'
  },
  {
    emoj: '😡',
    name: '怒火中烧'
  },
  {
    emoj: '😠',
    name: '生气'
  },
  {
    emoj: '🤬',
    name: '嘴上有符号的脸'
  },
  {
    emoj: '😈',
    name: '恶魔微笑'
  },
  {
    emoj: '💀',
    name: '头骨'
  },
  {
    emoj: '☠️',
    name: '骷髅'
  },
  {
    emoj: '💩',
    name: '大便'
  },
  {
    emoj: '🤡',
    name: '小丑脸'
  },
  {
    emoj: '👹',
    name: '食人魔'
  },
  {
    emoj: '👺',
    name: '天狗'
  },
  {
    emoj: '👻',
    name: '鬼'
  },
  {
    emoj: '👽',
    name: '外星人'
  },
  {
    emoj: '👾',
    name: '怪物'
  },
  {
    emoj: '🤖',
    name: '机器人'
  },
  {
    emoj: '😺',
    name: '大笑的猫脸'
  },
  {
    emoj: '😸',
    name: '微笑的猫脸'
  },
  {
    emoj: '😹',
    name: '笑出眼泪的猫脸'
  },
  {
    emoj: '😻',
    name: '花痴的猫脸'
  },
  {
    emoj: '😼',
    name: '奸笑的猫脸'
  },
  {
    emoj: '😽',
    name: '猫脸亲亲'
  },
  {
    emoj: '🙀',
    name: '疲倦的猫脸'
  },
  {
    emoj: '😿',
    name: '哭泣的猫脸'
  },
  {
    emoj: '😾',
    name: '生气的猫脸'
  }
];
vars.emotionlist = [
  '微笑',
  '撇嘴',
  '色',
  '发呆',
  '得意',
  '流泪',
  '害羞',
  '闭嘴',
  '睡',
  '大哭',
  '尴尬',
  '发怒',
  '调皮',
  '呲牙',
  '惊讶',
  '难过',
  '酷',
  '冷汗',
  '抓狂',
  '吐',
  '偷笑',
  '可爱',
  '白眼',
  '傲慢',
  '饥饿',
  '困',
  '惊恐',
  '流汗',
  '憨笑',
  '大兵',
  '奋斗',
  '咒骂',
  '疑问',
  '嘘',
  '晕',
  '折磨',
  '衰',
  '骷髅',
  '敲打',
  '再见',
  '擦汗',
  '抠鼻',
  '鼓掌',
  '糗大了',
  '坏笑',
  '左哼哼',
  '右哼哼',
  '哈欠',
  '鄙视',
  '委屈',
  '快哭了',
  '阴险',
  '亲亲',
  '吓',
  '可怜',
  '菜刀',
  '西瓜',
  '啤酒',
  '篮球',
  '乒乓',
  '咖啡',
  '饭',
  '猪头',
  '玫瑰',
  '凋谢',
  '示爱',
  '爱心',
  '心碎',
  '蛋糕',
  '闪电',
  '炸弹',
  '刀',
  '足球',
  '瓢虫',
  '便便',
  '月亮',
  '太阳',
  '礼物',
  '拥抱',
  '强',
  '弱',
  '握手',
  '胜利',
  '抱拳',
  '勾引',
  '拳头',
  '差劲',
  '爱你',
  'NO',
  'OK',
  '爱情',
  '飞吻',
  '跳跳',
  '发抖',
  '怄火',
  '转圈',
  '磕头',
  '回头',
  '跳绳',
  '挥手',
  '激动',
  '街舞',
  '献吻',
  '左太极',
  '右太极'
];
vars.emoji = [
  '啊',
  '啊啊啊',
  '啊哈',
  '盯',
  '额',
  '烦',
  '烦烦',
  '服不服',
  '哈',
  '哈哈',
  '哈哈哈',
  '哈哈哈哈',
  '哈哈哈哈哈哈哈',
  '哈哈哈哈哈哈哈哈',
  '嗨起来',
  '好烦啊',
  '好慌啊',
  '好球',
  '嘿嘿',
  '嘿嘿嘿',
  '嘿嘿嘿嘿',
  '嘿嘿嘿嘿嘿嘿',
  '嘿嘿嘿嘿嘿嘿嘿',
  '吼吼',
  '慌',
  '慌慌',
  '火大',
  '火大火',
  '哭了',
  '哭了哭了',
  '溜了',
  '嗯',
  '嗯嗯',
  '你愁啥',
  '拍手',
  '噗',
  '天呐',
  '问号',
  '问号问号',
  '问号问号问号',
  '问号问号问号问号',
  '天呐天呐',
  '我的天呐',
  '我哭了',
  '嘻嘻',
  '嘻嘻嘻',
  '嘻嘻嘻嘻',
  '嘻嘻嘻嘻嘻嘻',
  '耶',
  '真的烦'
];
export default vars;
